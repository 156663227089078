import React from 'react';
import Link from '../../../utils/Link';

import styles from '../styles/button.module.scss';
import { HrefOriginLogIn } from '../../../constants/Copies';

interface Props {
  text: string;
  path?: string;
  onClick?: () => void;
  roundCorners?: boolean;
  id?: string;
  buttonClassName?: string;
  buttonStyles?: Object;
  textClassName?: string;
  textStyles?: Object;
  innerRef?: React.RefObject<HTMLInputElement>;
}

const Button = (props: Props) => {
  const {
    path,
    text,
    onClick,
    roundCorners,
    buttonClassName,
    buttonStyles,
    textClassName,
    textStyles,
    innerRef,
    id,
  } = props;
  const renderInnerButton = () => {
    return (
      <span
        id={id}
        className={`${styles.button}${
          roundCorners ? ` ${styles.round_corners}` : ''
        }${buttonClassName ? ` ${buttonClassName}` : ''}`}
        style={buttonStyles}
        onClick={onClick}
        role={path ? undefined : 'button'}
        aria-label={path ? undefined : text}
        tabIndex={path ? undefined : 0}
        ref={innerRef}
      >
        <p className={textClassName} style={textStyles}>
          {text}
        </p>
      </span>
    );
  };

  if (path) {
    if (
      path.includes('http://') ||
      path.includes('https://') ||
      path.includes('www://')
    ) {
      // external link
      return (
        <a
          title={text}
          href={path}
          target={path.includes(HrefOriginLogIn) ? '_self' : '_blank'}
          rel={path.includes(HrefOriginLogIn) ? '' : 'noopener noreferrer'}
        >
          {renderInnerButton()}
        </a>
      );
    } else {
      return (
        <Link to={path} title={text}>
          {renderInnerButton()}
        </Link>
      );
    }
  } else {
    return renderInnerButton();
  }
};

export default Button;
