import React from 'react'
import moment from 'moment-timezone'

import Link from '../../../utils/Link'
import Button from '../../button/components/Button'
// import Image from '../../image/components/Image';
import { FooterCopies } from '../../../constants/Copies'
import styles from '../styles/footer.module.scss'

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.footer__bottom}>
      <div className={styles.footer__bottom_left}>
        {/* <div className={styles.footer__bottom_left__stores}>
          <a
            href={FooterCopies.stores.appStore.link}
            target={'_blank'}
            rel='noopener noreferrer'
            title={FooterCopies.stores.appStore.text}
            className={styles.footer__bottom_left__stores__link}
          >
            <Image
              src={FooterCopies.stores.appStore.img}
              alt={FooterCopies.stores.appStore.text}
              width={'120px'}
              height={'40px'}
              className={styles.footer__bottom_left__stores__icon}
            />
          </a>
          <a
            href={FooterCopies.stores.googlePlayStore.link}
            target={'_blank'}
            rel='noopener noreferrer'
            title={FooterCopies.stores.googlePlayStore.text}
            className={styles.footer__bottom_left__stores__link}
          >
            <Image
              src={FooterCopies.stores.googlePlayStore.img}
              alt={FooterCopies.stores.googlePlayStore.text}
              width={'136px'} // original 512px
              height={'40px'} // original 151px
              className={styles.footer__bottom_left__stores__icon}
            />
          </a>
        </div> */}
        <p className={`${styles.footer__bottom_left__text} ${styles.footer__bottom_left__text__address}`}>
          {FooterCopies.address.address1}
          <br />
          {FooterCopies.address.address2}
          <br />
          {FooterCopies.address.address3}
        </p>

        <p className={styles.footer__bottom_left__text}>{FooterCopies.phone}</p>

        <p className={styles.footer__bottom_left__text}>{`${FooterCopies.copyright1}${moment().format('YYYY')}${
          FooterCopies.copyright2
        }`}</p>
      </div>

      {/* nav */}
      {/* left */}
      <div className={styles.footer__bottom_center}>
        <ul>
          {/* company */}
          <li className={`${styles.footer__bottom__link}`}>
            <Link to={FooterCopies.nav.companyButtonLink.link}>{FooterCopies.nav.companyButtonLink.text}</Link>
          </li>

          {/* partners */}
          <li className={`${styles.footer__bottom__link}`}>
            <Link to={FooterCopies.nav.partnersButtonLink.link}>{FooterCopies.nav.partnersButtonLink.text}</Link>
          </li>

          {/* support */}
          <li className={`${styles.footer__bottom__link}`}>
            <Link to={FooterCopies.nav.supportButtonLink.link}>{FooterCopies.nav.supportButtonLink.text}</Link>
          </li>

          {/* careers */}
          <li className={`${styles.footer__bottom__link}`}>
            <Button text={FooterCopies.nav.careersButtonLink.text} path={'https://apply.workable.com/docmatter-inc-2/'} />
          </li>
        </ul>
      </div>

      {/* right */}
      <div className={styles.footer__bottom_right}>
        <ul>
          {/* home */}
          <li className={`${styles.footer__bottom__link} ${styles.home}`}>
            <Link to={FooterCopies.nav.homeButtonLink.link}>{FooterCopies.nav.homeButtonLink.text}</Link>
          </li>

          {/* terms of service */}
          <li className={`${styles.footer__bottom__link}`}>
            <Link to={FooterCopies.nav.termsButtonLink.link}>{FooterCopies.nav.termsButtonLink.text}</Link>
          </li>

          {/* privacy policy */}
          <li className={`${styles.footer__bottom__link}`}>
            <Link to={FooterCopies.nav.privacyButtonLink.link}>{FooterCopies.nav.privacyButtonLink.text}</Link>
          </li>
        </ul>
      </div>
    </div>
  </footer>
)

export default Footer
