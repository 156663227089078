import React from 'react';

import styles from '../styles/hamburger.module.scss';

interface Props {
  isNavOpen: boolean;
  handleToggleNav: () => void;
}

const Hamburger = (props: Props) => {
  const { isNavOpen, handleToggleNav } = props;
  return (
    <div className={styles.hamburger} onClick={handleToggleNav}>
      <div
        className={`${styles.hamburger__bar}${
          isNavOpen ? ` ${styles.open}` : ''
        }`}
      />
    </div>
  );
};

export default Hamburger;
