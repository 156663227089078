import React from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

import EntityAvatarSection from './EntityAvatarSection';
import EntityLoader from './EntityLoader';
import EntityDetails from './EntityDetails';
import EntityWelcomeText from './EntityWelcomeText';
import EntityButtonsRow from './EntityButtonsRow';
import {
  HrefOrigin,
  Pathname,
  handleFindPath,
} from '../../../constants/Copies';
import styles from '../styles/entity.module.scss';

interface Props {
  routerProps: any;
  windowDimensions: { width: number; height: number };
  isTop: boolean;
  isMobileTablet: boolean;
  handleUpdateEntityData: (entityData: any) => void;
  handleResetEntityData: () => void;
  entityData: any;
}

interface State {
  isLoading: boolean;
}

export default class Entity extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  private entity = React.createRef<HTMLDivElement>();

  componentDidMount() {
    this.entity.current && disableBodyScroll(this.entity.current);
    this.props.handleResetEntityData();
    this.handleSlugApiCall();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isLoading && !this.state.isLoading) {
      this.entity.current && enableBodyScroll(this.entity.current);
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  handleSlugApiCall = () =>
    // axios({
    //   url: 'https://web.testmatter.info/dm/rest/public/corp/abbott',
    //   method: 'GET',
    //   headers: {
    //     'Access-Control-Allow-Origin': '*',
    //     'Access-Control-Allow-Headers': 'X-Requested-With',
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //     'Content-Type': 'application/json',
    //     Accept: 'application/json',
    //   },
    // })
    axios
      .get(
        // `${Pathname}${GetAlias}/${this.props.routerProps.match.params.slug}`
        // for react local environment, use the following:
        `${HrefOrigin}${Pathname}/${this.props.routerProps.match.params.slug}`
      )
      .then((res) => {
        console.log(res);

        this.setState({ isLoading: false }, () => {
          // if alias/slug is valid, save data to App's entityData state,
          if (res.data?.rc === 1) {
            this.props.handleUpdateEntityData(res.data.data);
          }

          // if alias/slug is invalid, redirect to home
          else {
            this.props.routerProps.history.push(handleFindPath('HOME'));
          }
        });
      })

      // if alias/slug is invalid, redirect to home
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false }, () =>
          this.props.routerProps.history.push(handleFindPath('HOME'))
        );
      });

  renderIsLoading = () => <EntityLoader isLoading={this.state.isLoading} />;

  renderIsLoaded = () => {
    const { entityData } = this.props;
    return (
      <div className={styles.entity__loaded}>
        {/* content */}
        <div className={styles.entity__inner}>
          {/* header - if webinar render 'Webinar', otherwise render name */}
          {entityData?.ctxtype === 700 && (
            <h1 className={styles.entity__title}>{'Webinar'}</h1>
          )}
          {entityData?.ctxtype !== 700 && entityData?.name && (
            // only for users has pnl attribute in entityData - if pnl is not "", attach to displayed name
            <div
              className={styles.entity__title}
              dangerouslySetInnerHTML={{
                __html: `<h1>${entityData.name}${
                  entityData.pnl ? `, ${entityData.pnl}` : ''
                }</h1>`,
              }}
            />
          )}

          {/* avatar section */}
          <EntityAvatarSection entityData={entityData} />
        </div>

        {/* info */}
        <div className={styles.entity__info}>
          {/* left */}
          <div className={styles.entity__info__left}>
            <div className={styles.entity__info__left__inner}>
              {entityData?.name && (
                // only for users has pnl attribute in entityData - if pnl is not "", attach to displayed name
                <div
                  className={styles.entity__info__left__title}
                  dangerouslySetInnerHTML={{
                    __html: `<h2>${entityData.name}${
                      entityData.pnl ? `, ${entityData.pnl}` : ''
                    }</h2>`,
                  }}
                />
              )}

              <EntityDetails entityData={entityData} />
            </div>
          </div>

          {/* right */}
          <div className={styles.entity__info__right}>
            <div className={styles.entity__info__right__inner}>
              {entityData?.welcomeText && (
                <p className={styles.entity__info__right__text}>
                  {EntityWelcomeText(entityData)}
                </p>
              )}

              <EntityButtonsRow entityData={entityData} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={styles.entity} ref={this.entity}>
        {this.props.entityData?.name && (
          <Helmet>
            <title>{`${this.props.entityData.name} | Docmatter`}</title>
          </Helmet>
        )}
        {this.renderIsLoading()}
        {this.renderIsLoaded()}
      </div>
    );
  }
}
