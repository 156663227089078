import React from 'react';
import axios from 'axios';

import Field from '../../../partials/form/components/Field';
import FormValidations from '../../../utils/FormValidations';
import Button from '../../../partials/button/components/Button';
import ErrorMessage from '../../signup/components/ErrorMessage';
import {
  HrefOrigin,
  Pathname,
  PostSupportForm,
} from '../../../constants/Copies';
import { SupportCopies } from '../../../constants/Copies';
import styles from '../styles/support-form.module.scss';

interface Props {
  windowDimensions: { width: number; height: number };
  handleSubmitSuccessful: () => void; // to pass style up to parent
}

interface State {
  fields: {
    firstName: FieldState;
    lastName: FieldState;
    email: FieldState;
    phone: FieldState;
    //topic: FieldState;
    message: FieldState;
  };
  isSubmitAttempted: boolean;
  isSubmitSuccessful: boolean;
}

interface FieldState {
  value: string;
  validations: Validation['validation'][];
  error: boolean;
  errorMessage: {
    message1: string;
    errorLink: {
      link: string;
      text: string;
    };
    message2: string;
  };
  isFreshPage: true;
}

interface Validation {
  validation: 'required' | 'phone' | 'email';
}

export default class SupportForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fields: {
        firstName: {
          value: '',
          validations: ['required'],
          error: false,
          errorMessage: SupportCopies.firstName.error,
          isFreshPage: true,
        },
        lastName: {
          value: '',
          validations: ['required'],
          error: false,
          errorMessage: SupportCopies.lastName.error,
          isFreshPage: true,
        },
        email: {
          value: '',
          validations: ['required', 'email'],
          error: false,
          errorMessage: SupportCopies.email.error,
          isFreshPage: true,
        },
        phone: {
          value: '',
          validations: [],
          error: false,
          errorMessage: SupportCopies.phone.error,
          isFreshPage: true,
        },
        /*
        topic: {
          value: '',
          validations: ['required'],
          error: false,
          errorMessage: SupportCopies.topic.error,
          isFreshPage: true,
        },
        */
        message: {
          value: '',
          validations: ['required'],
          error: false,
          errorMessage: SupportCopies.message.error,
          isFreshPage: true,
        },
      },
      isSubmitAttempted: false,
      isSubmitSuccessful: false,
    };
  }

  componentDidMount() {
    this.handleValidateAllFields();
    this.handleLinkParam();

    // accessibility - enter on submit button
    document
      .getElementById('support_submit')
      ?.addEventListener('keyup', this.handleEnter);
  }

  // accessibility - enter on submit button
  handleEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.key === 'Enter' && !this.hasError() && this.handleSubmit();
  };

  handleLinkParam = () => {
    // if (
    //   window.location.search &&
    //   window.location.search.includes('?topic=inquiry')
    // ) {
    //   this.onDropdownSelect({ value: 'Inquiry', label: 'Inquiry' }, 'topic');
    // }
  };

  handleValidate = (
    field: FieldState,
    handleSetErrorState: () => void,
    handleSetNoErrorState: () => void
  ) => {
    // validate not blank
    if (field.validations.find((validation) => validation === 'required')) {
      FormValidations.isEmpty(field.value)
        ? handleSetErrorState()
        : handleSetNoErrorState();
    }

    // validate email format
    if (field.validations.find((validation) => validation === 'email')) {
      FormValidations.isEmpty(field.value) ||
      !FormValidations.isEmailFormat(field.value)
        ? handleSetErrorState()
        : handleSetNoErrorState();
    }
  };

  handleValidateIndField = (field: string) => {
    const stateField = this.state.fields[field];
    const handleSetErrorState = () =>
      this.setState({
        fields: {
          ...this.state.fields,
          [field]: {
            ...this.state.fields[field],
            error: true,
            isFreshPage: false,
          },
        },
      });
    const handleSetNoErrorState = () =>
      this.setState({
        fields: {
          ...this.state.fields,
          [field]: {
            ...this.state.fields[field],
            error: false,
            isFreshPage: false,
          },
        },
      });

    this.handleValidate(stateField, handleSetErrorState, handleSetNoErrorState);
  };

  handleValidateAllFields = () => {
    // grab all key names in this.state.fields
    const fieldsArray = Object.keys(this.state.fields);

    // make a copy of their states for js iteration
    let copy = this.state.fields;

    // in each iteration, set error state if any (according to the array of validations)
    fieldsArray.forEach((field) => {
      const stateField = this.state.fields[field];
      const handleSetErrorState = () =>
        (copy[field] = { ...copy[field], error: true });
      const handleSetNoErrorState = () =>
        (copy[field] = { ...copy[field], error: false });

      this.handleValidate(
        stateField,
        handleSetErrorState,
        handleSetNoErrorState
      );
    });

    // set state with the mutated copy
    this.setState({
      fields: {
        firstName: {
          ...this.state.fields.firstName,
          error: copy.firstName.error,
        },
        lastName: {
          ...this.state.fields.lastName,
          error: copy.lastName.error,
        },
        email: { ...this.state.fields.email, error: copy.email.error },
        phone: { ...this.state.fields.phone, error: copy.phone.error },
        // topic: { ...this.state.fields.topic, error: copy.topic.error },
        message: { ...this.state.fields.message, error: copy.message.error },
      },
    });
  };

  handleChange = (e, field: string) => {
    this.setState(
      {
        fields: {
          ...this.state.fields,
          [field]: {
            ...this.state.fields[field],
            value: e.target.value,
            isFreshPage: false,
          },
        },
      },
      () => this.handleValidateIndField(field)
    );
  };

  hasError = () =>
    Object.values(this.state.fields)
      .map((field: any) => field.error)
      .find((error) => error);

  onDropdownSelect = (
    option: { value: string; label: string },
    field: string
  ) => {
    this.setState(
      {
        fields: {
          ...this.state.fields,
          [field]: {
            ...this.state.fields[field],
            value: option.value,
            isFreshPage: false,
          },
        },
      },
      () => this.handleValidateIndField(field)
    );
  };

  handleSubmit = () => {
    // validate
    this.handleValidateAllFields();

    // if no errors, POST
    if (!this.hasError()) {
      let formData = new FormData();
      formData.append('fn', this.state.fields.firstName.value);
      formData.append('ln', this.state.fields.lastName.value);
      formData.append('em', this.state.fields.email.value); // has to be email format ${...}@${...}.${...}
      formData.append('ph', this.state.fields.phone.value);
      // formData.append('tp', this.state.fields.topic.value.toLowerCase());
      formData.append('tp', 'inquiry');
      formData.append('msg', this.state.fields.message.value);
      // formData.append('src', ...) --> don't pass

      axios({
        method: 'post',
        url: `${HrefOrigin}${Pathname}${PostSupportForm}`,
        data: formData,
      }).then((res) => {
        console.log(res);

        if (res.data?.rc === 1) {
          // if successful
          this.setState(
            {
              fields: {
                firstName: {
                  ...this.state.fields.firstName,
                  value: '',
                  isFreshPage: true,
                },
                lastName: {
                  ...this.state.fields.lastName,
                  value: '',
                  isFreshPage: true,
                },
                email: {
                  ...this.state.fields.email,
                  value: '',
                  isFreshPage: true,
                },
                phone: {
                  ...this.state.fields.phone,
                  value: '',
                  isFreshPage: true,
                },
                // topic: {
                //   ...this.state.fields.topic,
                //   value: '',
                //   isFreshPage: true,
                // },
                message: {
                  ...this.state.fields.message,
                  value: '',
                  isFreshPage: true,
                },
              },
              isSubmitAttempted: false,
              isSubmitSuccessful: true,
            },
            () => {
              this.props.handleSubmitSuccessful(); // to pass style up to parent
            }
          );
        } else {
          // if not successful
          this.setState({
            isSubmitAttempted: true,
            isSubmitSuccessful: false,
          });
        }
      }).catch(err => 
      // if not successful
      this.setState({
        isSubmitAttempted: true,
        isSubmitSuccessful: false,
      }));
    }
  };

  handleErrorMessage = () => {
    const { isSubmitAttempted, isSubmitSuccessful } = this.state;
    const showError = Object.values(this.state.fields)
      .map((field: any) => field.error && !field.isFreshPage)
      .find((error) => error);
    let messagesArray = [] as FieldState['errorMessage'][];

    if (isSubmitAttempted && !isSubmitSuccessful) {
      messagesArray.push(SupportCopies.submitError);
      return {
        isShow: true,
        messagesArray: messagesArray,
      };
    }
    if (showError) {
      Object.keys(this.state.fields).map((field: any) => {
        const stateField = this.state.fields[field];
        return (
          stateField.error &&
          !stateField.isFreshPage &&
          messagesArray.push(stateField.errorMessage)
        );
      });
      return {
        isShow: true,
        messagesArray: messagesArray,
      };
    }
    return { isShow: false, messagesArray: [] };
  };

  render() {
    const { windowDimensions } = this.props;
    const { fields, isSubmitSuccessful } = this.state;
    const { firstName, lastName, email, phone, message } = fields;

    return (
      <div className={styles.support_form}>
        <form
          className={`${styles.support_form__form}${
            isSubmitSuccessful ? ` ${styles.submit_success}` : ''
          }`}
        >
          <div className={styles.signup_form__form__top}>
            <div className={styles.support_form__row}>
              <Field
                id={SupportCopies.firstName.id}
                label={SupportCopies.firstName.label}
                value={firstName.value}
                required
                onChange={(e) => this.handleChange(e, 'firstName')}
                onBlur={() => this.handleValidateIndField('firstName')}
                error={firstName.error && !firstName.isFreshPage}
                fieldClassName={styles.support_form__field}
              />
              <Field
                id={SupportCopies.lastName.id}
                label={SupportCopies.lastName.label}
                value={lastName.value}
                required
                onChange={(e) => this.handleChange(e, 'lastName')}
                onBlur={() => this.handleValidateIndField('lastName')}
                error={lastName.error && !lastName.isFreshPage}
                fieldClassName={styles.support_form__field}
              />
            </div>

            <div className={styles.support_form__row}>
              <Field
                id={SupportCopies.email.id}
                label={SupportCopies.email.label}
                value={email.value}
                required
                onChange={(e) => this.handleChange(e, 'email')}
                onBlur={() => this.handleValidateIndField('email')}
                error={email.error && !email.isFreshPage}
                fieldClassName={styles.support_form__field}
              />
              <Field
                id={SupportCopies.phone.id}
                label={SupportCopies.phone.label}
                value={phone.value}
                onChange={(e) => this.handleChange(e, 'phone')}
                onBlur={() => this.handleValidateIndField('phone')}
                error={phone.error && !phone.isFreshPage}
                fieldClassName={styles.support_form__field}
              />
            </div>
 
            {/* //<div className={styles.support_form__row}>
            //  <Field
            //    id={SupportCopies.topic.id}
            //    label={SupportCopies.topic.label}
            //     value={topic.value}
            //     required
            //     dropdown
            //     options={SupportCopies.topic.options}
            //     onDropdownSelect={(option) =>
            //       this.onDropdownSelect(option, 'topic')
            //     }
            //     onBlur={() => this.handleValidateIndField('topic')}
            //     error={topic.error && !topic.isFreshPage}
            //     fieldClassName={styles.support_form__field}
            //     windowDimensions={windowDimensions}
            //   />
            // </div> */}
            <Field
              id={SupportCopies.message.id}
              label={SupportCopies.message.label}
              value={message.value}
              required
              textarea
              onChange={(e) => this.handleChange(e, 'message')}
              onBlur={() => this.handleValidateIndField('message')}
              error={message.error && !message.isFreshPage}
            />

            {/* error message - shows when this.state.isSubmitAttempted === true && this.state.isSubmitSuccessful === false || when there is an error in ojne of the fields onBlur from the field */}
            <ErrorMessage data={this.handleErrorMessage()} />
          </div>

          <div>
            <Button
              id={'support_submit'}
              text={'Submit'}
              onClick={this.hasError() ? undefined : this.handleSubmit}
              buttonClassName={`${styles.support_form__button}${
                this.hasError() ? ` ${styles.disabled}` : ''
              }`}
              textClassName={styles.support_form__button_text}
            />
          </div>
        </form>
      </div>
    );
  }
}
