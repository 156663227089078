import React from "react";
import axios from "axios";

import Field from "../../../partials/form/components/Field";
import FormValidations from "../../../utils/FormValidations";
import Button from "../../../partials/button/components/Button";
import ErrorMessage from "./ErrorMessage";
import {
  HrefOrigin,
  Pathname,
  PostSignUpForm,
} from "../../../constants/Copies";
import { SignupCopies } from "../../../constants/Copies";
import styles from "../styles/signup-form.module.scss";

interface Props {
  handleSubmitSuccessful: () => void; // to pass style up to parent
  entityData: any;
}

interface State {
  fields: {
    firstName: FieldState;
    lastName: FieldState;
    email: FieldState;
    phone: FieldState;
    organization: FieldState;
    message: FieldState;
  };
  isSubmitAttempted: boolean;
  isSubmitSuccessful: boolean;
}

interface FieldState {
  value: string;
  validations: Validation["validation"][];
  error: boolean;
  errorMessage: {
    message1: string;
    errorLink: {
      link: string;
      text: string;
    };
    message2: string;
  };

  isFreshPage: true;
}

interface Validation {
  validation: "required" | "phone" | "email";
}

export default class SignupForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fields: {
        firstName: {
          value: "",
          validations: ["required"],
          error: false,
          errorMessage: SignupCopies.firstName.error,
          isFreshPage: true,
        },
        lastName: {
          value: "",
          validations: ["required"],
          error: false,
          errorMessage: SignupCopies.lastName.error,
          isFreshPage: true,
        },
        email: {
          value: "",
          validations: ["required", "email"],
          error: false,
          errorMessage: SignupCopies.email.error,
          isFreshPage: true,
        },
        phone: {
          value: "",
          validations: ["required"],
          error: false,
          errorMessage: SignupCopies.phone.error,
          isFreshPage: true,
        },
        organization: {
          value: "",
          validations: ["required"],
          error: false,
          errorMessage: SignupCopies.organization.error,
          isFreshPage: true,
        },
        message: {
          value:
            this.props.entityData &&
            this.props.entityData.ctxtype === 700 &&
            this.props.entityData.name
              ? this.props.entityData.name
              : "",
          validations: ["required"],
          error: false,
          errorMessage: SignupCopies.message.error,
          isFreshPage: true,
        },
      },
      isSubmitAttempted: false,
      isSubmitSuccessful: false,
    };
  }

  componentDidMount() {
    console.log("signup form entityData", this.props.entityData);
    console.log("signup form message", this.state.fields.message);
    this.handleValidateAllFields();

    // accessibility - enter on submit button
    document
      .getElementById("signup_submit")
      ?.addEventListener("keyup", this.handleEnter);
  }

  // accessibility - enter on submit button
  handleEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.key === "Enter" && !this.hasError() && this.handleSubmit();
  };

  handleValidate = (field, handleSetErrorState, handleSetNoErrorState) => {
    // validate not blank (for required)
    if (field.validations.find((validation) => validation === "required")) {
      FormValidations.isEmpty(field.value)
        ? handleSetErrorState()
        : handleSetNoErrorState();
    }

    // validate institutional email format
    if (field.validations.find((validation) => validation === "email")) {
      FormValidations.isEmpty(field.value) ||
      !FormValidations.isInstitutionalEmailFormat(field.value)
        ? handleSetErrorState()
        : handleSetNoErrorState();
    }
  };

  handleValidateIndField = (field) => {
    const stateField = this.state.fields[field];
    const handleSetErrorState = () =>
      this.setState({
        fields: {
          ...this.state.fields,
          [field]: {
            ...this.state.fields[field],
            error: true,
            isFreshPage: false,
          },
        },
      });
    const handleSetNoErrorState = () =>
      this.setState({
        fields: {
          ...this.state.fields,
          [field]: {
            ...this.state.fields[field],
            error: false,
            isFreshPage: false,
          },
        },
      });

    this.handleValidate(stateField, handleSetErrorState, handleSetNoErrorState);
  };

  handleValidateAllFields = () => {
    // grab all key names in this.state.fields
    const fieldsArray = Object.keys(this.state.fields);

    // make a copy of their states for js iteration
    let copy = this.state.fields;

    // in each iteration, set error state if any (according to the array of validations)
    fieldsArray.forEach((field) => {
      const stateField = this.state.fields[field];
      const handleSetErrorState = () =>
        (copy[field] = { ...copy[field], error: true });
      const handleSetNoErrorState = () =>
        (copy[field] = { ...copy[field], error: false });

      this.handleValidate(
        stateField,
        handleSetErrorState,
        handleSetNoErrorState
      );
    });

    // set state with the mutated copy
    this.setState({
      fields: {
        firstName: {
          ...this.state.fields.firstName,
          error: copy.firstName.error,
        },
        lastName: {
          ...this.state.fields.lastName,
          error: copy.lastName.error,
        },
        email: { ...this.state.fields.email, error: copy.email.error },
        phone: { ...this.state.fields.phone, error: copy.phone.error },
        organization: {
          ...this.state.fields.organization,
          error: copy.organization.error,
        },
        message: { ...this.state.fields.message, error: copy.message.error },
      },
    });
  };

  handleChange = (e, field) => {
    this.setState(
      {
        fields: {
          ...this.state.fields,
          [field]: {
            ...this.state.fields[field],
            value: e.target.value,
            isFreshPage: false,
          },
        },
      },
      () => this.handleValidateIndField(field)
    );
  };

  hasError = () =>
    Object.values(this.state.fields)
      .map((field: any) => field.error)
      .find((error) => error);

  handleSubmit = () => {
    // validate
    this.handleValidateAllFields();

    // if no errors, POST
    if (!this.hasError()) {
      const { entityData } = this.props;
      const { fields } = this.state;
      const { firstName, lastName, email, phone, message, organization } =
        fields;
      let event =
        entityData?.ctxid && entityData?.ctxtype && entityData?.ctxtype === 700
          ? entityData.ctxid
          : 0; // webinar
      let ctxid =
        entityData?.ctxid && entityData?.ctxtype && entityData?.ctxtype !== 700
          ? entityData.ctxid
          : 0; // other than webinar
      let ctxt =
        entityData?.ctxid && entityData?.ctxtype && entityData?.ctxtype !== 700
          ? entityData.ctxtype
          : 0; // other than webinar

      let formData = `fn=${firstName.value}&ln=${lastName.value}&em=${email.value}&ph=${phone.value}&org=${organization.value}&m=${message.value}&event=${event}&ctxid=${ctxid}&ctxt=${ctxt}`;

      axios({
        method: "post",
        url: `${HrefOrigin}${Pathname}${PostSignUpForm}`,
        data: formData,
      })
        .then((res) => {
          console.log(res);

          if (res.data?.rc === 1) {
            // if successful
            this.setState(
              {
                fields: {
                  firstName: {
                    ...this.state.fields.firstName,
                    value: "",
                    isFreshPage: true,
                  },
                  lastName: {
                    ...this.state.fields.lastName,
                    value: "",
                    isFreshPage: true,
                  },
                  email: {
                    ...this.state.fields.email,
                    value: "",
                    isFreshPage: true,
                  },
                  phone: {
                    ...this.state.fields.phone,
                    value: "",
                    isFreshPage: true,
                  },
                  organization: {
                    ...this.state.fields.organization,
                    value: "",
                    isFreshPage: true,
                  },
                  message: {
                    ...this.state.fields.message,
                    value: "",
                    isFreshPage: true,
                  },
                },
                isSubmitAttempted: false,
                isSubmitSuccessful: true,
              },
              () => {
                this.props.handleSubmitSuccessful(); // to pass style up to parent
              }
            );
          } else {
            // if not successful
            this.setState({
              isSubmitAttempted: true,
              isSubmitSuccessful: false,
            });
          }
        })
        .catch((err) =>
          // if not successful
          this.setState({
            isSubmitAttempted: true,
            isSubmitSuccessful: false,
          })
        );
    }
  };

  handleErrorMessage = () => {
    const { isSubmitAttempted, isSubmitSuccessful } = this.state;
    const showError = Object.values(this.state.fields)
      .map((field: any) => field.error && !field.isFreshPage)
      .find((error) => error);
    let messagesArray = [] as FieldState["errorMessage"][];

    if (isSubmitAttempted && !isSubmitSuccessful) {
      messagesArray.push(SignupCopies.submitError);
      return {
        isShow: true,
        messagesArray: messagesArray,
      };
    }
    if (showError) {
      Object.keys(this.state.fields).map((field: any) => {
        const stateField = this.state.fields[field];
        return (
          stateField.error &&
          !stateField.isFreshPage &&
          messagesArray.push(stateField.errorMessage)
        );
      });
      return {
        isShow: true,
        messagesArray: messagesArray,
      };
    }
    return { isShow: false, messagesArray: [] };
  };

  render() {
    const { fields, isSubmitSuccessful } = this.state;
    const { firstName, lastName, email, phone, organization, message } = fields;

    return (
      <div className={styles.signup_form}>
        <form
          className={`${styles.signup_form__form}${
            isSubmitSuccessful ? ` ${styles.submit_success}` : ""
          }`}
        >
          <div className={styles.signup_form__form__top}>
            <div className={styles.signup_form__row}>
              <Field
                id={SignupCopies.firstName.id}
                label={SignupCopies.firstName.label}
                value={firstName.value}
                required
                onChange={(e) => this.handleChange(e, "firstName")}
                onBlur={() => this.handleValidateIndField("firstName")}
                error={firstName.error && !firstName.isFreshPage}
                fieldClassName={styles.signup_form__field}
              />
              <Field
                id={SignupCopies.lastName.id}
                label={SignupCopies.lastName.label}
                value={lastName.value}
                required
                onChange={(e) => this.handleChange(e, "lastName")}
                onBlur={() => this.handleValidateIndField("lastName")}
                error={lastName.error && !lastName.isFreshPage}
                fieldClassName={styles.signup_form__field}
              />
            </div>

            <div className={styles.signup_form__row}>
              <Field
                id={SignupCopies.email.id}
                label={SignupCopies.email.label}
                value={email.value}
                required
                onChange={(e) => this.handleChange(e, "email")}
                onBlur={() => this.handleValidateIndField("email")}
                error={email.error && !email.isFreshPage}
                fieldClassName={styles.signup_form__field}
              />
              <Field
                id={SignupCopies.phone.id}
                label={SignupCopies.phone.label}
                value={phone.value}
                required
                onChange={(e) => this.handleChange(e, "phone")}
                onBlur={() => this.handleValidateIndField("phone")}
                error={phone.error && !phone.isFreshPage}
                fieldClassName={styles.signup_form__field}
              />
            </div>

            <div className={styles.signup_form__row}>
              <Field
                id={SignupCopies.organization.id}
                label={SignupCopies.organization.label}
                value={organization.value}
                required
                onChange={(e) => this.handleChange(e, "organization")}
                onBlur={() => this.handleValidateIndField("organization")}
                error={organization.error && !organization.isFreshPage}
                fieldClassName={`${styles.signup_form__field} ${styles.signup_form__field__full}`}
              />
            </div>

            <Field
              id={SignupCopies.message.id}
              label={SignupCopies.message.label}
              value={message.value}
              required
              textarea
              onChange={(e) => this.handleChange(e, "message")}
              onBlur={() => this.handleValidateIndField("message")}
              error={message.error && !message.isFreshPage}
            />

            {/* error message - shows when this.state.isSubmitAttempted === true && this.state.isSubmitSuccessful === false || when there is an error in ojne of the fields onBlur from the field */}
            <ErrorMessage data={this.handleErrorMessage()} />
          </div>

          {/* submit button */}
          <div>
            <Button
              id={"signup_submit"}
              text={"Submit"}
              onClick={this.hasError() ? undefined : this.handleSubmit}
              buttonClassName={`${styles.signup_form__button}${
                this.hasError() ? ` ${styles.disabled}` : ""
              }`}
              textClassName={styles.signup_form__button_text}
            />
          </div>
        </form>
      </div>
    );
  }
}
