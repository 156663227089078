import React from "react";
import moment from "moment-timezone";

import Button from "../../../partials/button/components/Button";
import { handleFindPath, HrefOriginLogIn } from "../../../constants/Copies";
import styles from "../styles/entity-buttons-row.module.scss";

const EntityButtonsRow = ({ entityData }: any) => {
  if (entityData?.ctxtype === 700) {
    // webinar
    const today = moment().tz("America/Los_Angeles"); // today (now)'s Date object
    const startTime = moment(new Date(entityData?.time), "America/Los_Angeles"); // webinar's start time Date object
    return (
      <div className={`${styles.entity_buttons_row} ${styles.single}`}>
        <Button
          text={
            startTime > today
              ? "Register" // upcoming
              : "View Webinar Recording" // past webinar
          }
          path={handleFindPath("SIGNUP")}
          buttonClassName={`${styles.entity__button} ${styles.signup}${
            startTime > today ? "" : ` ${styles.view_webinar}`
          }`}
          textClassName={`${styles.entity__button__text} ${styles.view_webinar}`}
        />
      </div>
    );
  } else {
    return (
      <div className={styles.entity_buttons_row}>
        <Button
          text={"Sign Up"}
          path={handleFindPath("SIGNUP")}
          buttonClassName={`${styles.entity__button} ${styles.signup}`}
          textClassName={styles.entity__button__text}
        />
        <Button
          text={"Log In"}
          path={`${HrefOriginLogIn}/dm/app/login/?t=${
            entityData?.ctxtype ? entityData.ctxtype : 0
          }&id=${entityData?.ctxid ? entityData.ctxid : 0}${
            window.location.search ? `&${window.location.search.slice(1)}` : ""
          }`}
          buttonClassName={styles.entity__button}
          textClassName={styles.entity__button__text}
        />
      </div>
    );
  }
};

export default EntityButtonsRow;
