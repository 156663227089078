import React from "react";
import { Helmet } from "react-helmet";
import {
  handleFindPath,
  handleFindPathText,
  HrefOriginLogIn,
} from '../../../constants/Copies';
import { SupportCopies } from '../../../constants/Copies';
import SupportForgotPwCard from "../../support/components/SupportForgotPwCard";
import SignupForm from "./SignupForm";
import { SignupCopies } from "../../../constants/Copies";
import styles from "../styles/signup.module.scss"; 
import styles2 from '../styles/support-forgot-pw-card.module.scss';

interface Props {
  routerProps: any;
  windowDimensions: { width: number; height: number };
  isTop: boolean;
  isMobileTablet: boolean;
  handleUpdateEntityData: (entityData: any) => void;
  handleResetEntityData: () => void;
  entityData: any;
}

interface State {
  isSubmitSuccessful: boolean;
}

export default class Signup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSubmitSuccessful: false,
    };
  }

  handleSubmitSuccessful = () => this.setState({ isSubmitSuccessful: true });

  render() {
    const { entityData } = this.props;
    const { isSubmitSuccessful } = this.state;

    return (
      <div className={styles.signup}>
        <Helmet>
          <title>{SignupCopies.headTitle}</title>
        </Helmet>

        <div className={styles.signup__inner}>
          <div
            className={`${styles.signup__inner__left}${
              isSubmitSuccessful ? ` ${styles.submit_success}` : ""
            }`}
          >
            {/* if webinar display webinar title and time */}
            {entityData?.ctxtype === 700 &&
            entityData.name &&
            entityData.time ? (
              <>
                <h1 className={styles.webinar__signup__inner__left__title}>
                  {entityData.name}
                </h1>
                <p className={styles.webinar__signup__inner__left__date}>
                  {`${entityData.time} Pacific Time (US and Canada)`}
                </p>
              </>
            ) : (
              <h1 className={styles.signup__inner__left__title}>
                {SignupCopies.title1}
                <br />
                {SignupCopies.title2}
              </h1>
            )}

            {/* if webinar display webinar body */}
            <p className={styles.signup__inner__left__body}>
              {entityData?.ctxtype === 700
                ? SignupCopies.webinarBody
                : SignupCopies.body}
            </p>


             
             
            <div
              className={styles.signup__inner__left__forgot_pw_card_container}
            > 
              <div className={styles2.support_forgot_pw}>
                <p className={styles2.support_forgot_pw__title}>
                  Already have an account?
                </p>
                {/* 
                <p className={styles2.support_forgot_pw__body}>
                  Click below to login
                </p>
                */}
                <a 
                      href={`${HrefOriginLogIn}/dm/app/login/?t=${
                      this.props.entityData?.ctxtype ? entityData.ctxtype : 0
                    }&id=${entityData?.ctxid ? entityData.ctxid : 0}${
                      window.location.search ? `&${window.location.search.slice(1)}` : ''
                    }`}
                      target={'_blank'} 
                      className={styles2.support_forgot_pw__link}
                    >  
                  Log In
                </a>
              </div>
            </div>
           
          </div>

          <div
            className={`${styles.signup__inner__right}${
              entityData?.ctxtype && entityData?.ctxid
                ? ` ${styles.with_message}`
                : ""
            }${isSubmitSuccessful ? ` ${styles.submit_success}` : ""}`}
          >
            <SignupForm
              handleSubmitSuccessful={this.handleSubmitSuccessful}
              entityData={entityData}
            />

            {/* submit successful message - shows when form is successufully submitted */}
            <div
              className={`${styles.signup__submit_success__container}${
                isSubmitSuccessful ? ` ${styles.submit_success}` : ""
              }`}
            >
              <p className={styles.signup__submit_success__title}>
                {SignupCopies.submitSuccessTitle}
              </p>
              <p className={styles.signup__submit_success__body}>
                {SignupCopies.submitSuccessBody}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
