import React from 'react';
import { Helmet } from 'react-helmet';

import SupportForm from './SupportForm';
import SupportForgotPwCard from './SupportForgotPwCard';
import { SupportCopies } from '../../../constants/Copies';
import styles from '../styles/support.module.scss';

interface Props {
  routerProps: any;
  windowDimensions: { width: number; height: number };
  isTop: boolean;
  isMobileTablet: boolean;
  handleUpdateEntityData: (entityData: any) => void;
  handleResetEntityData: () => void;
  entityData: any;
}

interface State {
  isSubmitAttempted: boolean;
  isSubmitSuccessful: boolean;
}

export default class Support extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSubmitAttempted: false,
      isSubmitSuccessful: false,
    };
  }

  componentDidMount() {
    this.props.handleResetEntityData();
  }

  handleSubmitSuccessful = () => this.setState({ isSubmitSuccessful: true });

  render() {
    const { windowDimensions } = this.props;
    const { isSubmitSuccessful } = this.state;

    return (
      <div className={styles.support}>
        <Helmet>
          <title>{SupportCopies.headTitle}</title>
        </Helmet>

        <div className={styles.support__inner}>
          <div
            className={`${styles.support__inner__left}${
              isSubmitSuccessful ? ` ${styles.submit_success}` : ''
            }`}
          >
            <h1 className={styles.support__inner__left__title}>
              {SupportCopies.title1}
              <br />
              {SupportCopies.title2}
            </h1>
            <p className={styles.support__inner__left__body}>
              {SupportCopies.body}
            </p>
            <div
              className={styles.support__inner__left__forgot_pw_card_container}
            >
              <SupportForgotPwCard />
            </div>
          </div>

          <div
            className={`${styles.support__inner__right}${
              isSubmitSuccessful ? ` ${styles.submit_success}` : ''
            }`}
          >
            <SupportForm
              windowDimensions={windowDimensions}
              handleSubmitSuccessful={this.handleSubmitSuccessful}
            />

            {/* submit successful message - shows when form is successufully submitted */}
            <div
              className={`${styles.support__submit_success__container}${
                isSubmitSuccessful ? ` ${styles.submit_success}` : ''
              }`}
            >
              <p className={styles.support__submit_success__title}>
                {SupportCopies.submitSuccessTitle}
              </p>
              <p className={styles.support__submit_success__body}>
                {SupportCopies.submitSuccessBody}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
