import appStoreBadgeBlack from "../assets/icons/app-store-badge-black.svg";
// import appStoreBadgeWhite from '../assets/icons/app-store-badge-white.svg';
import googlePlayStoreBadgeBlack from "../assets/icons/google-play-badge.svg";
// import googlePlayStoreBadgeWhite from '../assets/icons/google-play-badge-white-unofficial.svg';

import armenia from "../assets/images/home-hero--bg/home-hero--bg-armenia.jpg";
import brazil from "../assets/images/home-hero--bg/home-hero--bg-brazil.jpg";
import canada from "../assets/images/home-hero--bg/home-hero--bg-canada.jpg";
import france from "../assets/images/home-hero--bg/home-hero--bg-france.jpg";
import newYork from "../assets/images/home-hero--bg/home-hero--bg-newyork.jpg";
import newZealand from "../assets/images/home-hero--bg/home-hero--bg-newzealand.jpg";
import sanFrancisco from "../assets/images/home-hero--bg/home-hero--bg-sf.jpg";
import scotland from "../assets/images/home-hero--bg/home-hero--bg-scotland.jpg";
import southAfrica from "../assets/images/home-hero--bg/home-hero--bg-southafrica.jpg";
import southKorea from "../assets/images/home-hero--bg/home-hero--bg-southkorea.jpg";
import switzerland from "../assets/images/home-hero--bg/home-hero--bg-switzerland.jpg";
import utah from "../assets/images/home-hero--bg/home-hero--bg-utah.jpg";

import HomeLogos from "../assets/icons/home-logos";
import HomeVideoPlaceHolderImg from "../assets/images/home-video-placeholder.jpg";
// import HomeVideoShortLoop from '../assets/videos/dm-loop.mp4';
// import HomeVideoFull from '../assets/videos/dm-full.mp4';

import CompanyBgImg1 from "../assets/images/company--bg1.jpg";
import CompanyBgImg2 from "../assets/images/company--bg2.jpg";
import CompanyBgImg3 from "../assets/images/company--bg3-new.jpg";

import PartnersBgImg from "../assets/images/partners--bg.jpg";
import PartnersQuotation from "../assets/images/partners--quotation.png";

import BrandLogo from "../assets/icons/brand-logo.svg";
import ChromeLogo from "../assets/icons/chrome-logo.svg";
import FirefoxLogo from "../assets/icons/firefox-logo.svg";
import SafariLogo from "../assets/icons/safari-logo.svg";
import EdgeLogo from "../assets/icons/edge-logo.svg";

/* Industry Images */
import ResultsImage from '../assets/images/KOLs/ProvenResults.png';
import KOLConnectionImage from '../assets/images/KOLs/KOLconnection.png';
import TurnUpVolume from '../assets/images/KOLs/TurnUpVolume.png';
import ScaleKOLs from '../assets/images/KOLs/ScaleKOLs.png';
import KOLsConversion from '../assets/images/KOLs/KOLsConversion.png';
import Collaboration from '../assets/images/KOLs/Collaboration.png';
import HeartOfMatter from '../assets/images/KOLs/HeartOfMatter.png';
import BioTissue from '../assets/images/KOLs/BioTissue.png';
import Haemonetics from '../assets/images/KOLs/Haemonetics.png';
import Hologic from '../assets/images/KOLs/Hologic.png';
import Axogen from '../assets/images/KOLs/Axogen.png';
import NeuroPace from '../assets/images/KOLs/NeuroPace.png';
import TearCare from '../assets/images/KOLs/TearCare.png';
import ChartLeft from '../assets/images/KOLs/ChartLeft.png';
import ChartMiddle from '../assets/images/KOLs/ChartMiddle.png';
import ChartRight from '../assets/images/KOLs/ChartRight.png';

export const prefix =
  `${process.env.REACT_APP_STAGE}` === "production" ? "" : "/dmcorp";

export const HrefOrigin =
  `${process.env.REACT_APP_STAGE}` === "production"
    ? "https://pub.docmatter.com:8443"
    : "http://10.2.2.17:8080";

// export const HrefOriginLogIn =
//   `${process.env.REACT_APP_STAGE}` === 'production'
//     ? 'https://www.docmatter.com'
//     : '192.168.0.111:9090';

export const HrefOriginLogIn = window.location.origin;

export const Pathname = "/pm/rest/public";
export const GetAlias = "/corp";
export const PostSupportForm = "/support";
export const PostSignUpForm = "/users";

export const RoutesConstants = [
  {
    constant: "COMPANY",
    path: `${prefix}/company`,
    text: "Company",
  },
  {
    constant: "PARTNERS",
    path: `${prefix}/partners`,
    text: "Partners",
  },
  {
    constant: "INDUSTRY",
    path: `${prefix}/industry`,
    text: "Industry",
  },
  {
    constant: "SUPPORT",
    path: `${prefix}/support`,
    text: "Support",
  },
  // careers page is not being used - now an external link to 'https://apply.workable.com/docmatter-inc-2/'
  {
    constant: "CAREERS",
    path: `${prefix}/careers`,
    text: "Careers",
  },
  {
    constant: "SIGNUP",
    path: `${prefix}/signup`,
    text: "Sign Up",
  },
  {
    constant: "TERMS",
    path: `${prefix}/terms`,
    text: "Terms of Service",
  },
  {
    constant: "PRIVACY",
    path: `${prefix}/privacy`,
    text: "Privacy",
  },
  {
    constant: "ENTITY",
    path: `${prefix}/:slug`,
    text: "Entity",
  },
  {
    constant: "HOME",
    path: `${prefix}/`,
    text: "Home",
  },
];

export const handleFindPath = (constant: string): string => {
  const route = RoutesConstants.find((route) => route.constant === constant);
  return route ? route.path : "";
};

export const handleFindPathText = (constant: string): string => {
  const route = RoutesConstants.find((route) => route.constant === constant);
  return route ? route.text : "";
};

export const FooterCopies = {
  // stores: {
  //   appStore: {
  //     text: 'DocMatter on the App Store',
  //     link: 'https://apps.apple.com/us/app/docmatter/id716069146',
  //     img: appStoreBadgeWhite,
  //   },
  //   googlePlayStore: {
  //     text: 'DocMatter - Apps on Google Play',
  //     link:
  //       'https://play.google.com/store/apps/details?id=com.docmatter.docmatter',
  //     img: googlePlayStoreBadgeWhite,
  //   },
  // },
  address: {
    address1: "DocMatter Headquarters",
    address2: "460 Brannan Street #77452",
    address3: "San Francisco, CA 94107",
  },
  phone: "855-DOC-MATTER",
  copyright1: "© ",
  copyright2: " by DocMatter Inc. All Rights Reserved.",
  nav: {
    homeButtonLink: {
      text: "DocMatter Inc.",
      link: handleFindPath("HOME"),
    },
    companyButtonLink: {
      text: handleFindPathText("COMPANY"),
      link: handleFindPath("COMPANY"),
    },
    partnersButtonLink: {
      text: handleFindPathText("PARTNERS"),
      link: handleFindPath("PARTNERS"),
    },
    industryButtonLink: {
      text: handleFindPathText("INDUSTRY"),
      link: handleFindPath("INDUSTRY"),
    },
    supportButtonLink: {
      text: handleFindPathText("SUPPORT"),
      link: handleFindPath("SUPPORT"),
    },
    careersButtonLink: {
      text: handleFindPathText("CAREERS"),
      link: handleFindPath("CAREERS"),
    },
    termsButtonLink: {
      text: handleFindPathText("TERMS"),
      link: handleFindPath("TERMS"),
    },
    privacyButtonLink: {
      text: handleFindPathText("PRIVACY"),
      link: handleFindPath("PRIVACY"),
    },
  },
};

export const HomeCopies = {
  headTitle: "What You Know Matters | DocMatter",
  topBannerText1:
    "To play our part in addressing the pandemic, DocMatter has committed our software and services to front-line provider societies, free of charge. The efficient sharing of relevant medical knowledge has never been more crucial. We currently moderate a multi-disciplinary COVID-19 DocMatter Community with collaborating providers from more than 100 countries and 15,000 institutions. Please join your colleagues if you have knowledge to contribute or would benefit from listening.",
  topBannerLink1: {
    text: "Click here",
    link: handleFindPath("SIGNUP"),
  },
  topBannerText2:
    " to request access as an individual provider. If you represent a society or institution, ",
  topBannerLink2: {
    text: "click here.",
    link: handleFindPath("SUPPORT"),
  },
  heroQuotes: [
    {
      quote: {
        white: "I am a ",
        gold: "radiologist",
      },
      location: "California, United States",
      bgImg: sanFrancisco,
    },
    {
      quote: {
        white: "I am an ",
        gold: "orthopedic surgeon",
      },
      location: "Normandy, France",
      bgImg: france,
    },
    {
      quote: {
        white: "I am a ",
        gold: "neurologist",
      },
      location: "New York, United States",
      bgImg: newYork,
    },
    {
      quote: {
        white: "I am an ",
        gold: "allergist",
      },
      location: "Western Cape, South Africa",
      bgImg: southAfrica,
    },
    {
      quote: {
        white: "I am a ",
        gold: "thoracic surgeon",
      },
      location: "Auckland, New Zealand",
      bgImg: newZealand,
    },
    {
      quote: {
        white: "I am an ",
        gold: "anesthesiologist",
      },
      location: "Bern, Switzerland",
      bgImg: switzerland,
    },
    {
      quote: {
        white: "I am a ",
        gold: "cardiologist",
      },
      location: "Yerevan, Armenia",
      bgImg: armenia,
    },
    {
      quote: {
        white: "I am an ",
        gold: "oncologist",
      },
      location: "Seoul, South Korea",
      bgImg: southKorea,
    },
    {
      quote: {
        white: "I am a ",
        gold: "dermatologist",
      },
      location: "Edinburgh, Scotland",
      bgImg: scotland,
    },
    {
      quote: {
        white: "I am an ",
        gold: "ophthalmologist",
      },
      location: "Rio De Janeiro, Brazil",
      bgImg: brazil,
    },
    {
      quote: {
        white: "I am a ",
        gold: "dentist",
      },
      location: "Ontario, Canada",
      bgImg: canada,
    },
    {
      quote: {
        white: "I am a ",
        gold: "urologist",
      },
      location: "Utah, United States",
      bgImg: utah,
    },
  ],
  heroStatic: {
    title: "The Medical Specialist Community",
    body: "DocMatter is where experts educate, to improve outcomes for all patients.",
    buttonLink: {
      text: "Request Access",
      link: handleFindPath("SIGNUP"),
    },
  },
  videoSection: {
    title: "The Internet, Built for You",
    body: "<p>From residency to retirement, physicians rely heavily on structured and ad hoc peer learning. This life-long education takes the form of national and regional society meetings, grand rounds, morbidity and mortality conferences, case conferences, journal clubs, tumor boards, industry-sponsored educational events, conversations with colleagues, and so on. These typically in-person learning opportunities are critical to the transfer of knowledge, which in turn ensures the best possible outcomes for patients.</p><br/><p>Today, we must ask the question: how can we use the global connectivity of the internet to remove geographic and point-in-time constraints to democratize and broaden this peer learning?</p><br/><p>Answer: DocMatter, the first of its kind, internet-enabled and human-supported collaboration platform, built by and for physicians to bring the structure of in-person learning online. Result: improving all patient outcomes, one physician at a time.</p>",
    shortVideoLoopUrl: "https://www.docmatter.com/learn/dm-loop.mp4",
    fullVideoUrl: "https://www.docmatter.com/learn/dm-full.mp4",
    // shortVideoLoopUrl: HomeVideoShortLoop,
    // fullVideoUrl: HomeVideoFull,
    placeholderImg: HomeVideoPlaceHolderImg,
    placeholderImgAlt: "DocMatter Video Placeholder",
  },
  logosSection: {
    title: "The DocMatter Community",
    logos: HomeLogos,
  },
  statsSection: {
    first: {
      number: "30K+",
      text: "INSTITUTIONS REPRESENTED",
    },
    second: {
      number: "140+",
      text: "COUNTRIES REPRESENTED",
    },
    third: {
      number: "100%",
      text: "MEDICAL SPECIALTIES REPRESENTED",
    },
  },
  stores: {
    appStore: {
      text: "DocMatter on the App Store",
      link: "https://apps.apple.com/us/app/docmatter/id716069146",
      img: appStoreBadgeBlack,
    },
    googlePlayStore: {
      text: "DocMatter - Apps on Google Play",
      link: "https://play.google.com/store/apps/details?id=com.docmatter.docmatter",
      img: googlePlayStoreBadgeBlack,
    },
  },
};

export const CompanyCopies = {
  headTitle: "The DocMatter Mission",
  sections: {
    section1: {
      title: "The DocMatter Mission",
      body: "<p>DocMatter was founded on the simple premise that individual physicians should be empowered with online, real time access to discover and then collaborate with experts on any medical topic. Today, we’re proud to support physicians representing 30,000+ institutions across 140+ countries. The broad awareness of best practices advocated by experts and shared across specialist networks on DocMatter translates every day to better patient outcomes.</p><br/><p>Every one of us, and those we love, is or will be a patient in a regional healthcare system. The care we receive is often limited by structural and circumstantial variables. Enabling immediate access to thousands of experts for online clinical collaboration significantly reduces variability of care.</p><br/><p>All stakeholders in medicine benefit from improved collaboration. DocMatter began by connecting expert physicians in the United States and subsequently expanded to include all health professionals, the institutions where they practice, the societies that support them, and the companies that make drugs, devices and other tools that play critical roles in affecting better outcomes.</p><br/><p>Everyone wins, when we work together.</p>",
      navigationTitle: "The DocMatter Mission",
      bgImg: CompanyBgImg1,
      bgGradients: {
        top: "#5199c7",
        bottom: "#4e80b0",
      },
    },
    section2: {
      title: "Our Core Value",
      body: "<p>Doing right by the medical community means empowering, and never marginalizing, the physician.</p><br/><p>At DocMatter, a physician always controls his or her profile and voice. A physician will not see advertising from DocMatter. A physician will always be able to find a network of medical experts on DocMatter, no matter the topic. A physician will always be supported by phone and email, as often as needed. A physician will always have full access to the DocMatter platform, at no cost, with no strings attached. Our goal is to enable direct, expert-led, continuous collaboration within the medical community, securely and quickly.</p><br/><p>We believe there is no more important purpose than building an online, global medical community to support collectively the health and well-being of every human being.</p>",
      navigationTitle: "Our Core Value",
      bgImg: CompanyBgImg2,
      bgGradients: {
        top: "#a03964",
        bottom: "#aa3c43",
      },
    },
    section3: {
      title: "Investors, Advisors and Founders",
      body: `<p>DocMatter was founded by brothers David and Eric Applegate. The origin of DocMatter, like many great companies, was personal. The brothers grew up in a family with strong personal ties to Lucile Packard Children’s Hospital, Stanford, and they spent countless hours in community engagement and, later, as teenage volunteers at the hospital, a global leader in pediatric medicine. Their experience left an indelible appreciation for health care providers. As young adults with successful careers in technology, they turned to philanthropy to support their ongoing commitment to medicine. A deep network in the ecosystem of Stanford Medicine, and a broad understanding of the need for advanced communication, led them to create a first of its kind software platform built to use the internet to evolve in-person medical collaboration to global scale.</p>
        <br/>
        <p>DocMatter is backed by leaders in technology and healthcare, with all playing an active role in our mission. Their expertise is broad and varied, and they include:</p>
        <br/>
        <ul>
          <li>6 MDs and 3 PhDs</li>
          <li>Trustees at multiple prominent academic medical centers</li>
          <li>6 that have taught at Stanford University or Stanford Medicine</li>
          <li>6 current and former healthcare and technology CEOs</li>
          <li>Former Chairman and CEO of a $12B+ pharmaceutical company</li>
          <li>9 Current and former healthcare and technology Venture Capitalists</li>
          <li>Early Google employees (One of the first 10 + co-creator of Gmail)</li>
          <li>Co-founders of 2 prominent companies among the first 10 acquired by Facebook</li>
          <li>The first institutional investor in Sequoia Capital</li>
        </ul>
        <br/>
        <p>This expertise is anchored by our legal team, led by the former Chairman of Fenwick & West who has acted as lead counsel on over 100 mergers and acquisitions valued at more than $100 billion in the aggregate, including the $19 billion acquisition of WhatsApp by Facebook. He has represented issuers and underwriters as lead counsel in the IPOs of many iconic companies, including Facebook, Intuit, Oracle, ServiceNow, Shutterfly, SuccessFactors, Symantec, Veritas and Workday.</p>`,
      navigationTitle: "Investors, Advisors and Founders",
      bgImg: CompanyBgImg3,
      bgGradients: {
        top: "#77cdc0",
        bottom: "#65aabf",
      },
    },
  },
};

export const PartnersCopies = {
  headTitle: "Partners",
  sections: {
    // img and text
    section1: {
      title: "Build Community",
      body: [
        {
          subtitle: "",
          text: "<p>All organizations supporting physicians benefit from better patient outcomes. DocMatter partners with medical societies, companies and institutions that align with our core value of always putting the physician first. This means an education-centric approach with the belief that enabling continuous peer-to-peer learning is not just a way—but the only way—to long-term success.</p>",
        },
        {
          subtitle: "One Statistic to Guide You",
          text: "<p>Even before COVID, 90+% of US-based physicians in 2020 were using their smart devices and the internet daily for professional knowledge seeking. Today, the future for societies, companies and institutions is clearly in focus: maximizing organizational growth will only be possible with a digital community engagement strategy. This means a web-enabled and human-supported community platform underpinning all of your current community-support activities. Digital point solutions managed in silos and consumer social media are no longer sufficient.<p/>",
        },
        {
          subtitle: "Data is Knowledge",
          text: "<p>Supporting the highest-quality digital engagement for your physicians means acknowledging that no two providers are exactly alike. Each physician has unique interests and experiences. Each physician has unique challenges represented by his or her individual patients. And most importantly, each physician is learning every day to prepare for the unknown of future patient presentations. With hundreds of millions of mapped areas of expertise and interest, DocMatter’s proprietary data map will underpin your Community to identify and prompt the right experts to lead every discussion, no matter the topic.</p>",
        },
        {
          subtitle: "Secure and Compliant",
          text: "<p>While consumer social media and industry-agnostic community engagement software work for many industries, they rarely work effectively, and never truly scale, for networks of medical professionals. Healthcare provider profiles are unique; look at a physician CV. Healthcare collaboration is unique; look at a medical conference. Healthcare laws are unique. DocMatter was designed by doctors, for doctors, then built by technologists who understand community dynamics and the importance of security and compliance.</p>",
        },
        {
          subtitle: "1+1: The DocMatter Solution",
          text: "<p>Every DocMatter partnership includes two elements: a world-class software platform built to fit seamlessly into today’s physician’s digital workflow and a San Francisco-based team of DocMatter consultants and support staff. Our team will guide you through our proven system of replicating the structure of peer-to-peer learning from conferences and in-person events into a digital environment.</p><br/><p>The DocMatter platform enables scale, reach and a continuum of education that, until now, has been impossible.</p>",
        },
      ],

      navigationTitle: "Build Community",
      bgImg: PartnersBgImg,
      // bgGradients: {
      //   top: '#5199c7',
      //   bottom: '#4e80b0',
      // },
    },
    // stats
    section2: {
      title: "The Results...",
      body: {
        first: {
          number: "65%",
          text: "AVERAGE COMMUNITY ENGAGEMENT",
        },
        second: {
          number: "13M+",
          text: "TOTAL PEER-TO-PEER DISCUSSIONS",
        },
        third: {
          number: "422M+",
          text: "EXPERTISE AND INTEREST RELATIONSHIPS MAPPED",
        },
      },
    },
    // quotes
    section3: {
      quotes: [
        "DocMatter is like being in a thousand-person practice where you can pitch a problem and depend on getting some help from your friends right away. It's a massive repository of experience I find essential.",
        "This is a much better way to learn…it is excellent…the app will sit next to Instagram now.",
        "I saw printed copies of the DocMatter conversation with highlights and handwritten notes on the desk in the OR.",
        "What I see in the value of DocMatter is that it recreates that most powerful part of our education, which was our residency, because we are focused on the same thing.",
        "I was bullish on DocMatter before, but I’m even more bullish now given the current events being canceled. The need for online education platforms like DocMatter are more important than ever now.",
      ],
      quotationBgImg: PartnersQuotation,
      buttonLink: {
        text: "Request Free Consultation",
        link: handleFindPath("SUPPORT"),
      },
    },
  },
};

export const SupportCopies = {
  headTitle: "DocMatter Support",
  title1: "DocMatter",
  title2: "Customer Support",
  body: "After submitting this form, a Support Manager will be in touch within one business day.",
  forgotPassword: {
    title: "Forgot your password?",
    body: "Click the link below to reset your DocMatter password.",
    buttonLink: {
      text: "Reset Password",
      link: "/dm/app/login/forgot.jsp",
    },
  },
  firstName: {
    id: "first_name",
    label: "First Name",
    error: {
      message1: "Please enter your first name",
      errorLink: {
        link: "",
        text: "",
      },
      message2: "",
    },
  },
  lastName: {
    id: "last_name",
    label: "Last Name",
    error: {
      message1: "Please enter your last name",
      errorLink: {
        link: "",
        text: "",
      },
      message2: "",
    },
  },
  email: {
    id: "email",
    label: "Email",
    error: {
      message1: "Please enter a valid email address",
      errorLink: {
        link: "",
        text: "",
      },
      message2: "",
    },
  },
  phone: {
    id: "phone",
    label: "Phone Number",
    error: {
      message1: "Please enter your phone number",
      errorLink: {
        link: "",
        text: "",
      },
      message2: "",
    },
  },
  topic: {
    id: "topic",
    label: "Topic",
    options: ["Support", "Feedback", "Inquiry"],
    error: {
      message1: "Please select a topic",
      errorLink: {
        link: "",
        text: "",
      },
      message2: "",
    },
  },
  message: {
    id: "message",
    label: "Message",
    error: {
      message1: "Please enter your message",
      errorLink: {
        link: "",
        text: "",
      },
      message2: "",
    },
  },
  submitSuccessTitle: "SUBMISSION SUCCESSFUL!",
  submitSuccessBody:
    "Thank you for your submission, a Support Manager will be in touch within one business day.",
  submitError: {
    message1:
      "Looks like something went wrong. Please resubmit your form or contact support.",
    errorLink: {
      link: "",
      text: "",
    },
    message2: "",
  },
};

export const CareersCopies = {
  headTitle: "Work at DocMatter",
  title: "Jobs at DocMatter",
};

export const SignupCopies = {
  headTitle: "DocMatter Sign Up",
  title1: "Sign up",
  title2: "for DocMatter",
  body: "Fill out this form to join DocMatter. A member of our Support Team will be in touch within one business day. Community Membership is free for verified health care providers and the scientific community.",
  webinarBody:
    "This webinar is hosted by DocMatter. Please fill out this form to join DocMatter. A member of our Support Team will be in touch within one buisness day. Community Membership is free for verified health care providers and the scientific community.",
  forgotPassword: {
    title: "Forgot your password?",
    body: "Click the link below to reset your DocMatter password.",
    buttonLink: {
      text: "Reset Password",
      link: "/dm/app/login/forgot.jsp",
    },
  },
  institutionalEmailAddresses: [
    "@gmail.com",
    "@me.com",
    "@hotmail.com",
    "@aol.com",
    "@msn.com",
    "@yahoo.com",
    "@bellsouth.net",
    "mail.net",
    "email.com",
    "mail.com",
    "att.net",
    "canada.com",
    "@email.",
    "freemail",
    "@go.com",
    "@go.",
    "@hotmail.",
    "@gmail.",
    "@yahoo.",
    "@live.",
    "@mail2",
    "@mail.",
    "@terr.",
    "@mindspring.com",
    "mail.mil",
  ],
  firstName: {
    id: "first_name",
    label: "First Name",
    error: {
      message1: "Please enter your first name",
      errorLink: {
        link: "",
        text: "",
      },
      message2: "",
    },
  },
  lastName: {
    id: "last_name",
    label: "Last Name",
    error: {
      message1: "Please enter your last name",
      errorLink: {
        link: "",
        text: "",
      },
      message2: "",
    },
  },
  email: {
    id: "email",
    label: "Email",
    error: {
      message1:
        "Please enter a valid email address",
      errorLink: {
        link: "", //handleFindPath("SUPPORT"),
        text: "", //"click here",
      },
      message2: "",
    },
  },
  phone: {
    id: "phone",
    label: "Phone Number",
    error: {
      message1: "Please enter your phone number",
      errorLink: {
        link: "",
        text: "",
      },
      message2: "",
    },
  },
  organization: {
    id: "organization",
    label: "Organization/Institution",
    error: {
      message1: "Please enter your organization/institution",
      errorLink: {
        link: "",
        text: "",
      },
      message2: "",
    },
  },
  message: {
    id: "message",
    label: "How did you hear about DocMatter?",
    error: {
      message1: "Please enter the required field",
      errorLink: {
        link: "",
        text: "",
      },
      message2: "",
    },
  },

  submitSuccessTitle: "SUBMISSION SUCCESSFUL!",
  submitSuccessBody: "A DocMatter representative will reach out to you.",
  submitError: {
    message1:
      "Looks like something went wrong. Please resubmit your form or contact support.",
    errorLink: {
      link: "",
      text: "",
    },
    message2: "",
  },
};

export const TermsCopies = {
  headTitle: "DocMatter Terms of Service",
  title1: "DocMatter",
  title2: "Terms of Service",
};

export const PrivacyCopies = {
  headTitle: "DocMatter Privacy Policy",
  title1: "DocMatter",
  title2: "Privacy Policy",
};

export const IECopies = {
  docmatterLogo: BrandLogo,
  text: "For a better browsing experience, we strongly suggest browsing this page on one of these supported browsers.",
  browsers: [
    {
      name: "Google Chrome",
      logo: ChromeLogo,
      imgAlt: "Google Chrome logo",
      text: "Download Google Chrome",
      link: "https://www.google.com/chrome/",
    },
    {
      name: "Mozilla Firefox",
      logo: FirefoxLogo,
      imgAlt: "Mozilla Firefox logo",
      text: "Download Mozilla Firefox",
      link: "https://www.mozilla.org/en-US/firefox/new/",
    },
    {
      name: "Safari",
      logo: SafariLogo,
      imgAlt: "Safari logo",
      text: "Download Safari",
      link: "https://support.apple.com/downloads/safari",
    },
    {
      name: "Microsoft Edge",
      logo: EdgeLogo,
      imgAlt: "Microsoft Edge logo",
      text: "Download Microsoft Edge",
      link: "https://www.microsoft.com/en-us/edge",
    },
  ],
};

export const IndustryCopies = {
  headTitle: "Industry Page",
  banner: {
    title: "Scaled KOL Connections",
    subTitle: "Without Scaled Coordination",
    copy: "Create a thriving community for your customers and invite KOLs to contribute. They can provide trusted insights, educating your prospects and customers about your product. This builds a powerful network of engaged healthcare professionals driving the future of medical education.",
  },
  results: {
    img: ResultsImage,
    title:"Proven Results",
    copy:"Using DocMatter increases your presence with healthcare providers and is proven to help grow communities and engagement.",
    title1: "30K",
    copy1: "Institutions Represented",
    title2: "140+",
    copy2: "Countries Represented",
    title3: "100%",
    copy3: "Specialties Represented",
    title4: "100,000+",
    copy4: "Doctors Worldwide",
  },
  outcomes: {
    title1: "100%",
    subtitle1: "Specialties Represented",
    copy1: "We healthcare professionals from Anesthesiology to Emegerncy Medical.",
    title2: "100%",
    subtitle2: "Specialties Represented",
    copy2: "We healthcare professionals from Anesthesiology to Emegerncy Medical.",
    title3: "100%",
    subtitle3: "Specialties Represented",
    copy3: "We healthcare professionals from Anesthesiology to Emegerncy Medical.",
  },
  weHelp: {
    title: "How we can help",
    copy: "Just some of the reasons why it makes sense to join.",
    img1: KOLConnectionImage,
    title1: "KOL connection, digital convenience",
    copy1: "KOL connections are still the most powerful method of education and influence for your prospects, but the legacy practice of scheduling phone calls is being disrupted. Why make your prospects wait? A partnership with DocMatter gives them 24/7 access to high quality medical collaboration, and lets them read KOL input in their time.",
    img2: TurnUpVolume,
    title2: "Turn up the volume, turn down the pressure",
    copy2: "Doctors are demanding more information than ever before, but the antiquated system of short 1:1 phone calls persists. Why hope that a doctor gets their questions answered in a short phone call, when the rest of the world is used to researching at their own speed? A partnership with DocMatter lets your prospects take their time to fully understand your proven technology so when your rep reaches out, they're ready to take the next step.",
    img3: ScaleKOLs,
    title3: "Scale up your KOLs",
    copy3: "Connecting the right KOLs to doctors at the right time is a proven winning strategy. But the old system of 1:1 phone calls is hard to schedule, hard to measure, and relies on the right voice at the right time. A partnership with DocMatter lets your prospects read discussions from the most suitable KOLs to get their questions answered.",
    img4: KOLsConversion,
    title4: "Get your KOLs in the conversation",
    copy4: "Your prospects are getting their information from someone. If your KOLs are not part of the discussion, how can you be sure that the information they're reading is correct? A partnership with DocMatter allows you to activate your KOLs to a whole new level.",
    img5: Collaboration,
    title5: "Authentic Medical Collaboration",
    copy5: "Doctors are trained to collaborate, and they trust and respect their peers. A partnership with DocMatter allows doctors to engage in authentic dialog to support each other to improve patient outcome, in a way that no rep will replicate.",
    img6: HeartOfMatter,
    title6: "The heart of the matter",
    copy6: "Your KOLs understand what barriers or hesitations exist in the minds of your prospects. A partnership with DocMatter empowers KOLs to start conversations that get to the heart of the matter, cut through the noise, and get the right information in the hands of your prospects.",
  }, 
  provenResults: {
    title: "Proven Results",
    copy: "Using DocMatter increases your presence with healthcare providers and is proven to help grow communities and engagement.",
    title1: "30% Less Overhead",
    img1: ChartLeft,
    title2: "20% More Doctors",
    img2: ChartMiddle,
    title3: "40% Growth", 
    img3: ChartRight, 
    link: "Signup for DocMatter",
  }, 

  improvements: {
    title: "Improvements You Can See ",
    copy: "DocMatter connects you with product companies ready to tell you more about the products.",
    img1: BioTissue, 
    img2: Haemonetics, 
    img3: Hologic, 
    img4: Axogen, 
    img5: NeuroPace, 
    img6: TearCare, 
  }, 
};
