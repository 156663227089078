import 'focus-visible';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { Routes } from './constants/Routes';

// import IE from './views/ie/components/IE';
import Header from './partials/header/components/Header';
import Footer from './partials/footer/components/Footer';
import WindowDismensions from './utils/WindowDimensions';
import RouteChange from './utils/RouteChange';

interface Props {}

interface State {
  // nav
  isNavOpen: boolean;

  // waypoint - for header styles
  isTop: boolean;

  // detect whether on IE
  // isIE: boolean;

  // user is on entity page - for injecting header's sign up and login
  entityData: any;
}

// Internet Explorer 6-11
// const isIE = /*@cc_on!@*/ false || !!document['documentMode'];

export default class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      // nav
      isNavOpen: false,

      // waypoint - for header styles
      isTop: true,

      // detect whether on IE
      // isIE: isIE,

      // user is on entity page - for injecting header's sign up and login
      entityData: null,
    };
  }

  // routes
  renderViews = (
    windowDimensions: {
      width: number;
      height: number;
      isMobile: boolean;
      isMobileTablet: boolean;
    },
    location: any
  ) => {
    const homeRoute = Routes.find((route) => route.constant === 'HOME');
    return (
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames='fade'
          timeout={1000}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <Switch location={location}>
            {Routes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                render={(props) =>
                  route.component && (
                    <route.component
                      routerProps={props}
                      windowDimensions={windowDimensions}
                      isTop={this.state.isTop}
                      isMobileTablet={windowDimensions.isMobileTablet}
                      handleUpdateEntityData={this.handleUpdateEntityData}
                      handleResetEntityData={this.handleResetEntityData} // call on every page's componentDidMount except signup
                      entityData={this.state.entityData}
                    />
                  )
                }
              />
            ))}
            {homeRoute && <Redirect to={homeRoute.path} />}
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    );
  };

  // nav
  handleToggleNav = () => this.setState({ isNavOpen: !this.state.isNavOpen });
  handleCloseNav = () =>
    this.state.isNavOpen ? this.setState({ isNavOpen: false }) : null;

  // waypoint
  handleWaypointEnter = () => this.setState({ isTop: true });
  handleWaypointLeave = () => this.setState({ isTop: false });
  renderWaypoint = () => (
    <Waypoint
      onEnter={this.handleWaypointEnter}
      onLeave={this.handleWaypointLeave}
    >
      <div
        style={{
          position: 'absolute',
          zIndex: -1,
          height: '50vh',
          top: '-75px',
          visibility: 'hidden',
        }}
      />
    </Waypoint>
  );

  // user is on entity page - for injecting header's sign up and login
  handleUpdateEntityData = (entityData) =>
    this.setState({ entityData: entityData });
  handleResetEntityData = () => this.setState({ entityData: null });

  // accessibility - shift focus back to document so that the first focusable element on page is docmatter logo in header
  handleNewPageFocus = () => {
    const classNameArray = document.getElementsByClassName(
      'app'
    ) as HTMLCollectionOf<HTMLElement>;
    classNameArray[0].focus();
  };

  render() {
    const { isNavOpen, isTop, entityData } = this.state;

    return (
      <WindowDismensions
        render={(windowDimensions) => (
          <Router>
            {/* scroll to top when change routes + passing location from router */}
            <RouteChange
              handleNewPageFocus={this.handleNewPageFocus}
              render={(props) => (
                <div className='app' tabIndex={-1}>
                  <Header
                    isNavOpen={isNavOpen}
                    handleToggleNav={this.handleToggleNav}
                    handleCloseNav={this.handleCloseNav}
                    windowDimensions={{
                      width: windowDimensions.width,
                      height: windowDimensions.height,
                    }}
                    isMobile={windowDimensions.isMobile}
                    isMobileTablet={windowDimensions.isMobileTablet}
                    isTop={isTop}
                    routerProps={props}
                    entityData={entityData}
                  />

                  {/* user scrolls down 1/2 of page, header turns opaque */}
                  {this.renderWaypoint()}

                  <div className='view'>
                    {this.renderViews(windowDimensions, props.location)}
                  </div>

                  <Footer />
                </div>
              )}
            />
          </Router>
        )}
      />
    );
  }
}
