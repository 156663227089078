import React from 'react';
import gsap from 'gsap';

import { HomeCopies } from '../../../constants/Copies';
import { MediaQueries } from '../../../constants/MediaQueries';
import MediaQueriesClassNames from '../../../utils/MediaQueriesClassNamesGenerator';
import styles from '../styles/home-logos.module.scss';

interface Props {
  windowDimensions: { width: number; height: number };
  isMobileTablet: boolean;
}

export default class HomeLogos extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};

    // 2-dimensional array
    this.logos = this.newArr(
      this.shuffleArray(HomeCopies.logosSection.logos),
      this.numOfDivs()
    ).slice(0, this.numOfDivs());

    this.divAnimations = [];
  }

  private logos: HTMLObjectElement[][];
  private divAnimations;

  componentDidMount() {
    this.handleSetLogos();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.windowDimensions.width !== this.props.windowDimensions.width ||
      prevProps.windowDimensions.height !== this.props.windowDimensions.height
    ) {
      this.divAnimations = [];
      this.logos = this.newArr(
        this.shuffleArray(HomeCopies.logosSection.logos),
        this.numOfDivs()
      ).slice(0, this.numOfDivs());

      this.handleSetLogos();
    }
  }

  handleSetLogos = () => {
    const opacityAnimation = {
      opacity: 1,
      yoyo: true,
      repeat: 1,
    };

    // const delayRange = () => {
    //   const { windowDimensions } = this.props;

    //   // random 0 - 45.99s for desktop && landscape tablet
    //   // random 0 - 35.99s for tablet
    //   // random 0 - 25.99s for phone (both prtrait && landscape)
    //   if (windowDimensions.width > MediaQueries.M_TABLET) {
    //     return (Math.random() * 45).toFixed(2);
    //   }
    //   if (
    //     windowDimensions.width > MediaQueries.M_MOBILE &&
    //     windowDimensions.width < windowDimensions.height
    //   ) {
    //     return (Math.random() * 35).toFixed(2);
    //   } else {
    //     return (Math.random() * 25).toFixed(2);
    //   }
    // };

    const numItemsInnerArr = this.numItemsInnerArr(
      HomeCopies.logosSection.logos.length,
      this.numOfDivs()
    );

    // make an array of animation of each divs
    [...Array(this.numOfDivs())].map(() =>
      this.divAnimations.push(gsap.timeline({ repeat: -1, repeatDelay: 0.5 }))
    );

    // for each div animation, the first span starts animation with random delay, and the next span animation starts after the first one ends
    this.divAnimations.map((divAnim, i) =>
      [...Array(numItemsInnerArr)].map((innerArr, j) =>
        j === 0
          ? divAnim.to(`#div${i}-span${j}`, {
              ...opacityAnimation,
              duration: (Math.random() + 1.5).toFixed(2),
              delay: (Math.random() + 1.5).toFixed(2),
            })
          : divAnim.to(`#div${i}-span${j}`, {
              ...opacityAnimation,
              duration: (Math.random() + 1.5).toFixed(2),
            })
      )
    );
  };

  shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  numItemsInnerArr = (arrLength, numSmallArr) =>
    Math.floor(arrLength / numSmallArr);

  // turn 1-dimensional array 2-dimensional array
  // divide logos into groups, where num of groups equals divs shown
  // each group has an equal number of logos
  // ex. 110 logos are divided into 27 groups + 1 (7x4 grid), 12 per group. The rest is put in last group with fewer items (7 items).
  // ex. 110 logos are divided into 11 groups + 1 (5x7 grid), 8 per group. The rest is put in last group with fewer items (5 items).
  newArr = (arr: HTMLObjectElement[], numSmallArr: number) => {
    let result: HTMLObjectElement[][] = [];
    const numItemsInnerArr = this.numItemsInnerArr(arr.length, numSmallArr);
    for (let i = 0; i < arr.length; i += numItemsInnerArr) {
      let innerArr: HTMLObjectElement[] = [];
      for (let j = 0; j < numItemsInnerArr * 2; j++) {
        arr[i + j] && innerArr.push(arr[i + j]);
      }
      !(innerArr.length < numItemsInnerArr) && result.push(innerArr);
    }
    return result;
  };

  numOfDivs = () => {
    const { windowDimensions } = this.props;

    // 7x4 for desktop && landscape tablet
    // 3x4 for tablet && landscape phone
    // 2x6 for portrait phone
    if (windowDimensions.width > MediaQueries.M_TABLET) {
      return 28;
    }
    if (windowDimensions.width > MediaQueries.M_MOBILE) {
      return 12;
    } else {
      return 12;
    }
  };

  render() {
    const { windowDimensions, isMobileTablet } = this.props;
    return (
      <div className={styles.home_logos}>
        <h3 className={styles.home_logos__title}>
          {HomeCopies.logosSection.title}
        </h3>

        <div
          className={styles.home_logos__body}
          key={`${windowDimensions.width}x${windowDimensions.height}`}
        >
          {this.logos.map((logoGroup, i) => (
            <div
              key={i}
              className={`${styles.container}${MediaQueriesClassNames(
                styles,
                windowDimensions,
                isMobileTablet
              )}`}
            >
              {logoGroup.map((logo, j) => (
                <span
                  key={j}
                  id={`div${i}-span${j}`}
                  className={styles.logo}
                  style={{
                    backgroundImage: `url(${logo})`,
                  }}
                  role={'img'}
                  aria-label={'logos'}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
