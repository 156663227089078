import React from 'react'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import Link from '../../../utils/Link'
import Button from '../../button/components/Button'
import { MediaQueries } from '../../../constants/MediaQueries'
import { handleFindPath, handleFindPathText, HrefOriginLogIn } from '../../../constants/Copies'
import styles from '../styles/nav.module.scss'

interface Props {
  isNavOpen: boolean
  handleToggleNav: () => void
  handleCloseNav: () => void
  entityData: any
  windowDimensions: { width: number; height: number }
  isMobile: boolean
  isMobileTablet: boolean
  routerProps: any
}

export default class Nav extends React.Component<Props> {
  componentDidUpdate(prevProps) {
    const view = document.getElementsByClassName('view')[0]
    if (this.props.windowDimensions.width < MediaQueries.M_TABLET) {
      !prevProps.isNavOpen && this.props.isNavOpen && disableBodyScroll(view)
      prevProps.isNavOpen && !this.props.isNavOpen && enableBodyScroll(view)
    } else {
      enableBodyScroll(view)
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  render() {
    const { isNavOpen, handleCloseNav, entityData, windowDimensions, isMobileTablet, routerProps } = this.props
    const activeRoute = (path) => routerProps.location.pathname === path

    return (
      <nav className={`${styles.nav}${isNavOpen ? ` ${styles.open}` : ''}`}>
        <ul className={`${styles.nav_ul}${isMobileTablet ? ` ${styles.mobile}` : ''}`}>
          <div className={`${styles.nav__top}${isMobileTablet ? ` ${styles.mobile}` : ''}`}>
            {/* home */}

            <li className={`${styles.nav_li} ${styles.home}${activeRoute(handleFindPath('HOME')) ? ` ${styles.active}` : ''}`}>
              <Link to={handleFindPath('HOME')} onClick={handleCloseNav}>
                {handleFindPathText('HOME')}
              </Link>
            </li>

            {/* company */}
            <li className={`${styles.nav_li}${activeRoute(handleFindPath('COMPANY')) ? ` ${styles.active}` : ''}`}>
              <Link to={handleFindPath('COMPANY')} onClick={handleCloseNav}>
                {handleFindPathText('COMPANY')}
              </Link>
            </li>

            {/* partners */}
            <li className={`${styles.nav_li}${activeRoute(handleFindPath('PARTNERS')) ? ` ${styles.active}` : ''}`}>
              <Link to={handleFindPath('PARTNERS')} onClick={handleCloseNav}>
                {handleFindPathText('PARTNERS')}
              </Link>
            </li>


            {/* industry 
            <li className={`${styles.nav_li}${activeRoute(handleFindPath('INDUSTRY')) ? ` ${styles.active}` : ''}`}>
              <Link to={handleFindPath('INDUSTRY')} onClick={handleCloseNav}>
                {handleFindPathText('INDUSTRY')}
              </Link>
            </li> */}

            {/* support */}
            <li className={`${styles.nav_li}${activeRoute(handleFindPath('SUPPORT')) ? ` ${styles.active}` : ''}`}>
              <Link to={handleFindPath('SUPPORT')} onClick={handleCloseNav}>
                {handleFindPathText('SUPPORT')}
              </Link>
            </li>

            {/* careers */}
            <li className={`${styles.nav_li}${activeRoute(handleFindPath('CAREERS')) ? ` ${styles.active}` : ''}`}>
              <Button text={handleFindPathText('CAREERS')} path={'https://apply.workable.com/docmatter-inc-2/'} onClick={handleCloseNav} />
            </li>
          </div>

          <div className={`${styles.nav__bottom}${isMobileTablet ? ` ${styles.mobile}` : ''}`} key={`${windowDimensions.width}`}>
            {/* sign up */}
            <li className={`${styles.nav_li} ${styles.small}${activeRoute(handleFindPath('SIGNUP')) ? ` ${styles.active}` : ''}`}>
              <Link to={handleFindPath('SIGNUP')} onClick={handleCloseNav}>
                {handleFindPathText('SIGNUP')}
              </Link>
            </li>

            {/* login */}
            <li className={`${styles.nav_li} ${styles.small}`}>
              <a
                href={`${HrefOriginLogIn}/dm/app/login/?t=${entityData?.ctxtype ? entityData.ctxtype : 0}&id=${
                  entityData?.ctxid ? entityData.ctxid : 0
                }${window.location.search ? `&${window.location.search.slice(1)}` : ''}`}
                className={styles.nav_li__login}
                onClick={handleCloseNav}
              >
                {'Log In'}
              </a>
            </li>
          </div>
        </ul>
      </nav>
    )
  }
}
