import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface Props extends RouteComponentProps<any> {
  location: any;
  handleNewPageFocus: () => void;
  render: (props: any) => React.ReactNode;
}

interface State {
  isRouteChanged: boolean;
}

class RouteChange extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isRouteChanged: false,
    };
  }

  private routeChangeListener: any;

  componentDidMount() {
    // triggers when route changes
    this.routeChangeListener = this.props.history.listen((location, action) => {
      // scroll to top when change routes
      setTimeout(() => window.scrollTo(0, 0), 900);

      // curtain animation
      this.handleRouteChanged();

      // accessibility - shift focus back to document so that the first focusable element on page is docmatter logo in header
      this.props.handleNewPageFocus();
    });
  }

  componentWillUnmount() {
    this.routeChangeListener();
  }

  handleRouteChanged = () =>
    this.setState({ isRouteChanged: true }, () =>
      setTimeout(() => this.setState({ isRouteChanged: false }), 2000)
    );

  render() {
    const { render } = this.props;
    const { isRouteChanged } = this.state;

    return (
      <React.Fragment>
        {render(this.props)}
        <div
          className={`page-transition--container${isRouteChanged ? ' in' : ''}`}
        >
          <div className={'page-transition'} />
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(RouteChange);
