import advocategoodsam from './advocategoodsam.png';
import altru from './altru.png';
import Amsterdam from './Amsterdam.png';
import Auckland from './Auckland.png';
import Bangkok from './Bangkok.png';
import Banner_Health from './Banner_Health.png';
import baptisthealth from './baptisthealth.png';
import Barcelona from './Barcelona.png';
import BarnesJewish from './BarnesJewish.png';
import Baylor from './Baylor.png';
import BeaumontHealth from './BeaumontHealth.png';
import BethIsreal from './BethIsreal.png';
import BJCHealthCare from './BJCHealthCare.png';
import Bogota from './Bogota.png';
import BonSecours from './BonSecours.png';
import BostonChildrens from './BostonChildrens.png';
import Brasil from './Brasil.png';
import Bridgeport from './Bridgeport.png';
import Brigham from './Brigham.png';
import Brown from './Brown.png';
import BryanHealth from './BryanHealth.png';
import Cairo from './Cairo.png';
import CedarsSinai from './CedarsSinai.png';
import ChariteBerlin from './ChariteBerlin.png';
import chesapeakeregional from './chesapeakeregional.png';
import Chicago from './Chicago.png';
import ChildrensATL from './ChildrensATL.png';
import ChildrensLA from './ChildrensLA.png';
import ChildrensNational from './ChildrensNational.png';
import ChildrensPA from './ChildrensPA.png';
import CincinnatiChildrens from './CincinnatiChildrens.png';
import ClevelandClinic from './ClevelandClinic.png';
import ColoradoChildrens from './ColoradoChildrens.png';
import ColumbiaNY from './ColumbiaNY.png';
import Columbus from './Columbus.png';
import cornell from './cornell.png';
import crozerkeystone from './crozerkeystone.png';
import DanaFarber from './DanaFarber.png';
import dartmouth from './dartmouth.png';
import DaytonChildrens from './DaytonChildrens.png';
import DenverHealth from './DenverHealth.png';
import DignityHealth from './DignityHealth.png';
import Duke from './Duke.png';
import Egypt from './Egypt.png';
import Einstein from './Einstein.png';
import Emory from './Emory.png';
import Feinberg from './Feinberg.png';
import Flinders from './Flinders.png';
import FloridaHospital from './FloridaHospital.png';
import Ford from './Ford.png';
import Germany from './Germany.png';
import HackensackNJ from './HackensackNJ.png';
import hadassah from './hadassah.png';
import Harvard from './Harvard.png';
import india from './india.png';
import ItalyFerra from './ItalyFerra.png';
import JeffersonHealth from './JeffersonHealth.png';
import JohnHopkins from './JohnHopkins.png';
import JohnMuir from './JohnMuir.png';
import JUST from './JUST.png';
import Kaiser from './Kaiser.png';
import Keck from './Keck.png';
import KingFahadSaudiaArabia from './KingFahadSaudiaArabia.png';
import Korea from './Korea.png';
import Korea2 from './Korea2.png';
import kualalumpur from './kualalumpur.png';
import KUmedicalcenter from './KUmedicalcenter.png';
import Kwazulu from './Kwazulu.png';
import LakelandRegional from './LakelandRegional.png';
import Lausanne from './Lausanne.png';
import Lebanon from './Lebanon.png';
import maimonides from './maimonides.png';
import MarinHealth from './MarinHealth.png';
import MassGen from './MassGen.png';
import Mayo from './Mayo.png';
import Medellin from './Medellin.png';
import MedStarHealth from './MedStarHealth.png';
import Miami from './Miami.png';
import michigan from './michigan.png';
import MorristownMedical from './MorristownMedical.png';
import moscow from './moscow.png';
import mountsinai from './mountsinai.png';
import Nairobi from './Nairobi.png';
import NebraskaMedicine from './NebraskaMedicine.png';
import Nemours from './Nemours.png';
import NHSLondon from './NHSLondon.png';
import NYPresbyterian from './NYPresbyterian.png';
import NYULagone from './NYULagone.png';
import OhioState from './OhioState.png';
import OregonHealth from './OregonHealth.png';
import OrlandoHealth from './OrlandoHealth.png';
import OsloUniversityHospital from './OsloUniversityHospital.png';
import OSSpeising from './OSSpeising.png';
import Ottowa from './Ottowa.png';
import PeaceHealth from './PeaceHealth.png';
import PennPres from './PennPres.png';
import perelman from './perelman.png';
import PhoenixChildrens from './PhoenixChildrens.png';
import PitieSalpetriereParis from './PitieSalpetriereParis.png';
import portugal from './portugal.png';
import QueenslandAust from './QueenslandAust.png';
import Radys from './Radys.png';
import RainbowBabies from './RainbowBabies.png';
import Rochester from './Rochester.png';
import RoyalChildrensAUS from './RoyalChildrensAUS.png';
import RushMedical from './RushMedical.png';
import SanDiegoHealth from './SanDiegoHealth.png';
import SantaClara from './SantaClara.png';
import scripps from './scripps.png';
import SeattleChildrens from './SeattleChildrens.png';
import Sentara from './Sentara.png';
import sharp from './sharp.png';
import Sheba from './Sheba.png';
import sickkids from './sickkids.png';
import Singapore from './Singapore.png';
import sloankettering from './sloankettering.png';
import southwestern from './southwestern.png';
import SparrowHealth from './SparrowHealth.png';
import SpecialSurgery from './SpecialSurgery.png';
import Stanford from './Stanford.png';
import Summa from './Summa.png';
import Sweden from './Sweden.png';
import Swedish from './Swedish.png';
import Texas from './Texas.png';
import TorontoGeneral from './TorontoGeneral.png';
import UAB from './UAB.png';
import ucDavis from './ucDavis.png';
import UCLAhealthRonaldRCA from './UCLAhealthRonaldRCA.png';
import UCSD from './UCSD.png';
import UCSF from './UCSF.png';
import UF from './UF.png';
import ufrgs from './ufrgs.png';
import UMC from './UMC.png';
import UMCUtrecht from './UMCUtrecht.png';
import UMinn from './UMinn.png';
import UNCHealth from './UNCHealth.png';
import UnityPoint from './UnityPoint.png';
import universitats from './universitats.png';
import UniversityofAberdeen from './UniversityofAberdeen.png';
import UniversityofArizona from './UniversityofArizona.png';
import UniversityofChicago from './UniversityofChicago.png';
import UniversityofColorado from './UniversityofColorado.png';
import UniversityofMaryland from './UniversityofMaryland.png';
import UniversityofNewCastle from './UniversityofNewCastle.png';
import UniversityofSheffield from './UniversityofSheffield.png';
import UniversityofTokyo from './UniversityofTokyo.png';
import UniversityofUtah from './UniversityofUtah.png';
import UniversityofVermont from './UniversityofVermont.png';
import UNM from './UNM.png';
import unmhospitals from './unmhospitals.png';
import upmc from './upmc.png';
import UTMcGovernTexas from './UTMcGovernTexas.png';
import UTSWMedical from './UTSWMedical.png';
import uwmedicine from './uwmedicine.png';
import vanderbilt from './vanderbilt.png';
import vcu from './vcu.png';
import veteranaffairs from './veteranaffairs.png';
import VirginiaMason from './VirginiaMason.png';
import wakeforest from './wakeforest.png';
import wellhealth from './wellhealth.png';
import wesley from './wesley.png';
import westchester from './westchester.png';
import westernplains from './westernplains.png';
import wurzburgGermany from './wurzburgGermany.png';
import yale from './yale.png';
import zurich from './zurich.png';

// 169 logos

const HomeLogos = [
  advocategoodsam,
  altru,
  Amsterdam,
  Auckland,
  Bangkok,
  Banner_Health,
  baptisthealth,
  Barcelona,
  BarnesJewish,
  Baylor,
  BeaumontHealth,
  BethIsreal,
  BJCHealthCare,
  Bogota,
  BonSecours,
  BostonChildrens,
  Brasil,
  Bridgeport,
  Brigham,
  Brown,
  BryanHealth,
  Cairo,
  CedarsSinai,
  ChariteBerlin,
  chesapeakeregional,
  Chicago,
  ChildrensATL,
  ChildrensLA,
  ChildrensNational,
  ChildrensPA,
  CincinnatiChildrens,
  ClevelandClinic,
  ColoradoChildrens,
  ColumbiaNY,
  Columbus,
  cornell,
  crozerkeystone,
  DanaFarber,
  dartmouth,
  DaytonChildrens,
  DenverHealth,
  DignityHealth,
  Duke,
  Egypt,
  Einstein,
  Emory,
  Feinberg,
  Flinders,
  FloridaHospital,
  Ford,
  Germany,
  HackensackNJ,
  hadassah,
  Harvard,
  india,
  ItalyFerra,
  JeffersonHealth,
  JohnHopkins,
  JohnMuir,
  JUST,
  Kaiser,
  Keck,
  KingFahadSaudiaArabia,
  Korea,
  Korea2,
  kualalumpur,
  KUmedicalcenter,
  Kwazulu,
  LakelandRegional,
  Lausanne,
  Lebanon,
  maimonides,
  MarinHealth,
  MassGen,
  Mayo,
  Medellin,
  MedStarHealth,
  Miami,
  michigan,
  MorristownMedical,
  moscow,
  mountsinai,
  Nairobi,
  NebraskaMedicine,
  Nemours,
  NHSLondon,
  NYPresbyterian,
  NYULagone,
  OhioState,
  OregonHealth,
  OrlandoHealth,
  OsloUniversityHospital,
  OSSpeising,
  Ottowa,
  PeaceHealth,
  PennPres,
  perelman,
  PhoenixChildrens,
  PitieSalpetriereParis,
  portugal,
  QueenslandAust,
  Radys,
  RainbowBabies,
  Rochester,
  RoyalChildrensAUS,
  RushMedical,
  SanDiegoHealth,
  SantaClara,
  scripps,
  SeattleChildrens,
  Sentara,
  sharp,
  Sheba,
  sickkids,
  Singapore,
  sloankettering,
  southwestern,
  SparrowHealth,
  SpecialSurgery,
  Stanford,
  Summa,
  Sweden,
  Swedish,
  Texas,
  TorontoGeneral,
  UAB,
  ucDavis,
  UCLAhealthRonaldRCA,
  UCSD,
  UCSF,
  UF,
  ufrgs,
  UMC,
  UMCUtrecht,
  UMinn,
  UNCHealth,
  UnityPoint,
  universitats,
  UniversityofAberdeen,
  UniversityofArizona,
  UniversityofChicago,
  UniversityofColorado,
  UniversityofMaryland,
  UniversityofNewCastle,
  UniversityofSheffield,
  UniversityofTokyo,
  UniversityofUtah,
  UniversityofVermont,
  UNM,
  unmhospitals,
  upmc,
  UTMcGovernTexas,
  UTSWMedical,
  uwmedicine,
  vanderbilt,
  vcu,
  veteranaffairs,
  VirginiaMason,
  wakeforest,
  wellhealth,
  wesley,
  westchester,
  westernplains,
  wurzburgGermany,
  yale,
  zurich,
];

export default HomeLogos;
