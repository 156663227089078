import { MediaQueries } from '../constants/MediaQueries';
const MediaQueriesClassNames = (styles, windowDimensions, isMobileTablet) => {
  if (windowDimensions.width > MediaQueries.S_DESKTOP) {
    return ` ${styles.m_desktop}${
      isMobileTablet && windowDimensions.width > windowDimensions.height
        ? ` ${styles.landscape_tablet}`
        : '' // ipad pro
    }`;
  }
  if (windowDimensions.width > MediaQueries.M_TABLET) {
    return ` ${styles.s_desktop}${
      isMobileTablet && windowDimensions.width > windowDimensions.height
        ? ` ${styles.landscape_tablet}`
        : '' // ipad
    }`;
  }
  if (windowDimensions.width > MediaQueries.S_TABLET) {
    return ` ${styles.m_tablet}`;
  }
  if (windowDimensions.width > MediaQueries.M_MOBILE) {
    return ` ${styles.s_tablet}`;
  }
  if (windowDimensions.width > MediaQueries.S_MOBILE) {
    return ` ${styles.m_mobile}`;
  }
  if (windowDimensions.width < MediaQueries.S_MOBILE + 1) {
    return ` ${styles.s_mobile}`;
  }
  return '';
};

export default MediaQueriesClassNames;
