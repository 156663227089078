import React from 'react';
import ReactPlayer from 'react-player';

import '../styles/video.scss';

interface Props {
  windowDimensions?: { width: number; height: number };
  url: string;
  previewImg?: string;
  playing?: boolean;
  muted?: boolean;
  loop?: boolean;
  style?: Object;
  hide?: boolean;
  playIcon?: React.ReactNode;
  onPlay?: () => void;
  onPause?: () => void;
}

const Video = (props: Props) => {
  const {
    url,
    previewImg,
    playing,
    style,
    hide,
    playIcon,
    onPlay,
    onPause,
  } = props;
  return (
    <ReactPlayer
      url={url}
      playing={playing ? true : false}
      loop
      controls
      light={previewImg ? previewImg : undefined}
      width={hide ? 0 : '100%'}
      height={hide ? 0 : '100%'}
      onContextMenu={e => e.preventDefault()} // Disable right click
      pip // Picture-in-Picture - watch videos in a floating window on top of other windows
      config={{ file: { attributes: { controlsList: 'nodownload' } } }} // Disable download button
      style={style ? style : undefined}
      playIcon={playIcon}
      onPlay={onPlay}
      onPause={onPause}
    />
  );
};

export default Video;
