import React from 'react';

import Link from '../../../utils/Link';
import Hamburger from './Hamburger';
import Nav from './Nav';
import Button from '../../button/components/Button';
import Image from '../../image/components/Image';
import BrandLogo from '../../../assets/icons/brand-logo.svg';
import {
  handleFindPath,
  handleFindPathText,
  HrefOriginLogIn,
} from '../../../constants/Copies';
import styles from '../styles/header.module.scss';

interface Props {
  isNavOpen: boolean;
  handleToggleNav: () => void;
  handleCloseNav: () => void;
  windowDimensions: { width: number; height: number };
  isMobile: boolean;
  isMobileTablet: boolean;
  isTop: boolean;
  routerProps: any;
  entityData: any;
}

const Header = (props: Props) => {
  const {
    isNavOpen,
    handleToggleNav,
    handleCloseNav,
    windowDimensions,
    isMobile,
    isMobileTablet,
    routerProps,
    entityData,
  } = props;

  return (
    <header className={styles.header}>
      <div className={styles.header__left}>
        <Hamburger isNavOpen={isNavOpen} handleToggleNav={handleToggleNav} />
        <Link to={handleFindPath('HOME')}>
          <Image
            className={styles.logo}
            src={BrandLogo}
            alt={'brand logo'}
            width={'91.3px'}
            height={'38.3px'}
          />
        </Link>
        <Nav
          isNavOpen={isNavOpen}
          handleToggleNav={handleToggleNav}
          handleCloseNav={handleCloseNav}
          entityData={entityData}
          windowDimensions={windowDimensions}
          isMobile={isMobile}
          isMobileTablet={isMobileTablet}
          routerProps={routerProps}
        />
      </div>
      <div className={styles.header__right}>
        <Link
          to={handleFindPath('SIGNUP')}
          onClick={handleCloseNav}
          className={`${styles.header__signup__link}${
            routerProps.location.pathname === handleFindPath('SIGNUP')
              ? ` ${styles.active}`
              : ''
          }`}
        >
          {handleFindPathText('SIGNUP')}
        </Link>
        <Button
          text={'Log In'}
          path={`${HrefOriginLogIn}/dm/app/login/?t=${
            entityData?.ctxtype ? entityData.ctxtype : 0
          }&id=${entityData?.ctxid ? entityData.ctxid : 0}${
            window.location.search ? `&${window.location.search.slice(1)}` : ''
          }`}
          roundCorners
          buttonClassName={styles.header__login__button}
          textClassName={styles.header__login__text}
        />
      </div>
    </header>
  );
};

export default Header;
