import React from 'react';

import Dropdown from './Dropdown';
import styles from '../styles/form.module.scss';

interface Props {
  windowDimensions?: { width: number; height: number };
  id: string;
  label?: string;
  value?: string;
  placeholder?: string;
  required?: boolean;
  textarea?: boolean;
  dropdown?: boolean;
  options?: string[];
  onChange?: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => void;
  onDropdownSelect?: (option: { value: string; label: string }) => void;
  onBlur?: () => void;
  error?: boolean;
  fieldClassName?: string;
}

const Field = (props: Props) => {
  const {
    id,
    label,
    value,
    placeholder,
    required,
    textarea,
    dropdown,
    options,
    onChange,
    onDropdownSelect,
    onBlur,
    error,
    fieldClassName,
    windowDimensions,
  } = props;

  return (
    <div
      className={`${styles.form__group}${
        fieldClassName ? ` ${fieldClassName}` : ''
      }`}
    >
      {label && (
        <label htmlFor={id} className={styles.form__label}>
          {label}
          {required && <small>{'(required)'}</small>}
        </label>
      )}

      {/* one line input */}
      {!textarea && !dropdown && (
        <input
          id={id}
          className={`${styles.form__input}${
            error ? ` ${styles.form__error}` : ''
          }`}
          type={'text'}
          value={value ? value : ''}
          placeholder={placeholder ? placeholder : ''}
          onChange={(e) => onChange && onChange(e)}
          onBlur={onBlur}
        />
      )}

      {/* textarea */}
      {textarea && (
        <textarea
          id={id}
          className={`${styles.form__textarea}${
            error ? ` ${styles.form__error}` : ''
          }`}
          value={value ? value : ''}
          placeholder={placeholder ? placeholder : ''}
          onChange={(e) => onChange && onChange(e)}
          onBlur={onBlur}
        />
      )}

      {/* dropdown */}
      {dropdown && (
        <Dropdown
          id={id}
          placeholder={placeholder}
          value={value ? value : ''}
          options={options ? options : []}
          onDropdownSelect={(option) =>
            onDropdownSelect && onDropdownSelect(option)
          }
          error={error}
          windowDimensions={windowDimensions}
          onBlur={onBlur}
        />
      )}
    </div>
  );
};

export default Field;
