import React from "react";

import Image from "../../../partials/image/components/Image";
import styles from "../styles/entity-details.module.scss";

const renderUser = (entityData: any) => {
  const renderUserLine2 = () => {
    if (entityData.sa1 && entityData.sa4) {
      // if has both sa1 and sa4
      return `${entityData.sa1}, ${entityData.sa4}`;
    } else if (entityData.sa1 && !entityData.sa4) {
      // if has sa1 and no sa4, display sa1
      return entityData.sa1;
    } else if (entityData.sa4 && !entityData.sa1) {
      // if has sa4 and no sa1, display sa4
      return entityData.sa4;
    }
  };

  return (
    <React.Fragment>
      {/* line 1 */}
      {entityData?.sa2 && (
        <div
          className={styles.entity_deails__text}
          dangerouslySetInnerHTML={{ __html: entityData.sa2 }}
        />
      )}

      {/* line 2 */}
      {(entityData?.sa1 || entityData?.sa4) && (
        <div
          className={styles.entity_deails__text}
          dangerouslySetInnerHTML={{ __html: renderUserLine2() }}
        />
      )}

      {/* line 3 */}
      {entityData?.sa3 && (
        <div
          className={styles.entity_deails__text}
          dangerouslySetInnerHTML={{ __html: entityData.sa3 }}
        />
      )}

      {/* description */}
      {entityData?.description && (
        <div
          className={styles.entity_deails__text}
          dangerouslySetInnerHTML={{ __html: entityData.description }}
        />
      )}
    </React.Fragment>
  );
};

const renderVendorProductSociety = (entityData: any) => {
  return (
    <React.Fragment>
      {/* line 1 */}
      {entityData?.pubInfo?.title && (
        <div
          className={styles.entity_deails__text}
          dangerouslySetInnerHTML={{ __html: entityData.pubInfo.title }}
        />
      )}

      {/* description */}
      {entityData?.pubInfo?.desc && (
        <div
          className={styles.entity_deails__text}
          dangerouslySetInnerHTML={{ __html: entityData.pubInfo.desc }}
        />
      )}
    </React.Fragment>
  );
};

const renderGroup = (
  entityData: any,
  specialtyCommunity?: "SPECIALTY_COMMUNITY"
) => {
  const renderGroupLine2 = () => {
    if (specialtyCommunity === "SPECIALTY_COMMUNITY" && entityData?.sa3) {
      // if special community, render sa3
      return (
        <div
          className={styles.entity_deails__text}
          dangerouslySetInnerHTML={{ __html: entityData.sa3 }}
        />
      );
    } else if (!specialtyCommunity && entityData?.description) {
      // if group, render description
      return (
        <div
          className={styles.entity_deails__text}
          dangerouslySetInnerHTML={{ __html: entityData.description }}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <React.Fragment>
      {/* line 1 */}
      {entityData?.sa2 && (
        <div
          className={styles.entity_deails__text}
          dangerouslySetInnerHTML={{ __html: entityData.sa2 }}
        />
      )}

      {/* line 2 */}
      {renderGroupLine2()}
    </React.Fragment>
  );
};

const renderWebinar = (entityData: any) => (
  <React.Fragment>
    {/* line 1 - webinar start date time + timezone (pacific time) */}
    {/* data from backend is already in pacific time */}
    {entityData?.time && (
      <div
        className={styles.entity_deails__text}
        dangerouslySetInnerHTML={{
          __html: `${entityData.time} Pacific Time (US and Canada)`,
        }}
      />
    )}

    {/* line 2 */}
    {entityData?.sa1 && (
      <div
        className={styles.entity_deails__text}
        dangerouslySetInnerHTML={{ __html: entityData.sa1 }}
      />
    )}

    {/* description */}
    {entityData?.description && (
      <div
        className={styles.entity_deails__text}
        dangerouslySetInnerHTML={{ __html: entityData.description }}
      />
    )}

    {/* list of speakers */}
    {entityData?.speakers?.length > 0 && (
      <ul className={styles.entity_deails__speakers__ul}>
        {entityData?.speakers.map((speaker, i) => (
          <li key={i} className={styles.entity_deails__speaker__li}>
            {/* speaker's photo */}
            {speaker.photoWebPath && (
              <div
                className={styles.entity_deails__speaker__li__photo}
                style={{ width: "100px", height: "100px" }}
              >
                <Image
                  // react automatically uses proxy (in package.json) as the hostname for returned speaker.photoWebPath (ex. '/dm/assets/images/fa-user_64_16_e2e2e4_none.png')
                  src={`https://docmatter.com${speaker.photoWebPath}`}
                  alt={
                    speaker.name
                      ? `${speaker.name}${
                          speaker.pnl ? `, ${speaker.pnl}` : ""
                        }`
                      : "Speaker's photo"
                  }
                  background
                  width={"100%"}
                  height={"100%"}
                />
              </div>
            )}

            <div className={styles.entity_deails__speaker__li__text}>
              {/* speaker's name */}
              {speaker.name && (
                <div
                  className={styles.entity_deails__speaker__li__text__name}
                  dangerouslySetInnerHTML={{
                    __html: `${speaker.name}${
                      speaker.pnl ? `, ${speaker.pnl}` : ""
                    }`,
                  }}
                />
              )}

              {/* sa2 */}
              {speaker.sa2 && (
                <div
                  className={styles.entity_deails__speaker__li__text__details}
                  dangerouslySetInnerHTML={{ __html: speaker.sa2 }}
                />
              )}

              {/* sa1 */}
              {speaker.sa1 && (
                <div
                  className={styles.entity_deails__speaker__li__text__details}
                  dangerouslySetInnerHTML={{ __html: speaker.sa1 }}
                />
              )}

              {/* sa4 */}
              {speaker.sa4 && (
                <div
                  className={styles.entity_deails__speaker__li__text__details}
                  dangerouslySetInnerHTML={{ __html: speaker.sa4 }}
                />
              )}
            </div>
          </li>
        ))}
      </ul>
    )}
  </React.Fragment>
);

const EntityDetails = ({ entityData }: any) => {
  if (entityData?.ctxtype === 100) {
    // user
    return renderUser(entityData);
  } else if (
    entityData?.ctxtype === 200 ||
    entityData?.ctxtype === 300 ||
    (entityData?.ctxtype === 400 && entityData?.soc === 1)
  ) {
    // 200 = vendor, 300 = product, 400&soc=1 = society
    return renderVendorProductSociety(entityData);
  } else if (entityData?.ctxtype === 400) {
    return renderGroup(entityData);
  } else if (entityData?.ctxtype === 600) {
    return renderGroup(entityData, "SPECIALTY_COMMUNITY");
  } else if (entityData?.ctxtype === 700) {
    return renderWebinar(entityData);
  } else {
    return null;
  }
};

export default EntityDetails;
