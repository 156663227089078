import React from 'react';

import { HomeCopies } from '../../../constants/Copies';
import styles from '../styles/home-stats-section.module.scss';

const HomeStatsSection = () => (
  <div className={styles.home_stats_section}>
    {/* bottom */}
    <div className={styles.home_stats_section__bottom}>
      <div className={styles.home_stats_section__flex}>
        {/* first */}
        <div className={styles.home_stats_section__bottom__flex__inner}>
          <p className={styles.home_stats_section__text}>
            <strong className={styles.home_stats_section__text__strong}>
              {HomeCopies.statsSection.first.number}
            </strong>
            {HomeCopies.statsSection.first.text}
          </p>
        </div>

        {/* second */}
        <div className={styles.home_stats_section__bottom__flex__inner}>
          <p className={styles.home_stats_section__text}>
            <strong className={styles.home_stats_section__text__strong}>
              {HomeCopies.statsSection.second.number}
            </strong>
            {HomeCopies.statsSection.second.text}
          </p>
        </div>

        {/* third */}
        <div className={styles.home_stats_section__bottom__flex__inner}>
          <p className={styles.home_stats_section__text}>
            <strong className={styles.home_stats_section__text__strong}>
              {HomeCopies.statsSection.third.number}
            </strong>
            {HomeCopies.statsSection.third.text}
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default HomeStatsSection;
