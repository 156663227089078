import React from 'react';
import { Helmet } from 'react-helmet';
import { Waypoint } from 'react-waypoint';
import gsap from 'gsap';

import Image from '../../../partials/image/components/Image';
import Button from '../../../partials/button/components/Button';
import { PartnersCopies } from '../../../constants/Copies';
import styles from '../styles/partners.module.scss';

interface Props {
  routerProps: any;
  windowDimensions: { width: number; height: number };
  isTop: boolean;
  isMobileTablet: boolean;
  handleUpdateEntityData: (entityData: any) => void;
  handleResetEntityData: () => void;
  entityData: any;
}

interface State {}

export default class Partners extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
    this.rightAnimation = gsap.timeline({});
    this.leftAnimation = gsap.timeline({});
  }

  private rightAnimation;
  private leftAnimation;

  handleEnter = (index: number) => {
    if ((index + 2) % 2) {
      this.rightAnimation.to(`.right-${index}`, {
        duration: 1.5,
        left: 0,
        ease: 'power1.inOut',
        delay: 0.5,
      });
    } else {
      this.leftAnimation.to(`.left-${index}`, {
        duration: 1.5,
        left: 0,
        ease: 'power1.inOut',
        delay: 0.5,
      });
    }
  };

  render() {
    return (
      <div className={styles.partners}>
        <Helmet>
          <title>{PartnersCopies.headTitle}</title>
        </Helmet>

        <h1 className={'hidden_page_h1'}>{PartnersCopies.headTitle}</h1>

        {/* img & text */}
        {PartnersCopies.sections.section1 && (
          <React.Fragment>
            <div className={styles.partners__bg_img__container}>
              {/* background image */}
              <Image
                src={PartnersCopies.sections.section1.bgImg}
                alt={'background image'}
                background
                width={'100%'}
                height={'100%'}
                className={styles.partners__bg_img}
              />

              {/* background color */}
              {/* <div
                className={`${styles.partners__bg_color} ${styles['section1']}`}
              /> */}
            </div>

            <div className={styles.partners__text}>
              {/* text title */}
              {!!PartnersCopies.sections.section1.title && (
                <h2 className={styles.partners__text__title}>
                  {PartnersCopies.sections.section1.title}
                </h2>
              )}

              {/* text body */}
              {!!PartnersCopies.sections.section1.body &&
                PartnersCopies.sections.section1.body.map((bodySection, i) => (
                  <div key={i} className={styles.partners__text__body_section}>
                    {!!bodySection.subtitle && (
                      <h3 className={styles.partners__text__subtitle}>
                        {bodySection.subtitle}
                      </h3>
                    )}
                    {!!bodySection.text && (
                      <div
                        className={styles.partners__text__body}
                        dangerouslySetInnerHTML={{
                          __html: bodySection.text,
                        }}
                      />
                    )}
                  </div>
                ))}
            </div>
          </React.Fragment>
        )}

        {/* stats */}
        {PartnersCopies.sections.section2 && (
          <div className={styles.partners_stats_section}>
            {/* title */}
            {!!PartnersCopies.sections.section2.title && (
              <h2 className={styles.partners__text__title}>
                {PartnersCopies.sections.section2.title}
              </h2>
            )}
            <div className={styles.partners_stats_section__bottom}>
              <div className={styles.partners_stats_section__flex}>
                {/* first */}
                <div
                  className={styles.partners_stats_section__bottom__flex__inner}
                >
                  <p className={styles.partners_stats_section__text}>
                    <strong
                      className={styles.partners_stats_section__text__strong}
                    >
                      {PartnersCopies.sections.section2.body.first.number}
                    </strong>
                    {PartnersCopies.sections.section2.body.first.text}
                  </p>
                </div>

                {/* second */}
                <div
                  className={styles.partners_stats_section__bottom__flex__inner}
                >
                  <p className={styles.partners_stats_section__text}>
                    <strong
                      className={styles.partners_stats_section__text__strong}
                    >
                      {PartnersCopies.sections.section2.body.second.number}
                    </strong>
                    {PartnersCopies.sections.section2.body.second.text}
                  </p>
                </div>

                {/* third */}
                <div
                  className={styles.partners_stats_section__bottom__flex__inner}
                >
                  <p className={styles.partners_stats_section__text}>
                    <strong
                      className={styles.partners_stats_section__text__strong}
                    >
                      {PartnersCopies.sections.section2.body.third.number}
                    </strong>
                    {PartnersCopies.sections.section2.body.third.text}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* quote */}
        {PartnersCopies.sections.section3.quotes &&
          PartnersCopies.sections.section3.quotes.map((quote, index) => {
            const positionStyleModule =
              (index + 2) % 2 ? styles.right : styles.left;
            const positionStyleAnim = (index + 2) % 2 ? 'right' : 'left';
            return (
              <Waypoint onEnter={() => this.handleEnter(index)} key={index}>
                <div
                  className={`${styles.partners_quotes_section__quote} ${positionStyleModule} ${positionStyleAnim}-${index}`}
                >
                  <Image
                    src={PartnersCopies.sections.section3.quotationBgImg}
                    alt={'quotation'}
                    background
                    width={'118.8px'}
                    height={'90.8px'}
                    className={`${styles.partners_quotes_section__bg} ${positionStyleModule}`}
                  />
                  <p
                    className={`${styles.partners_quotes_section__text__body} ${positionStyleModule}`}
                  >
                    {quote}
                  </p>
                </div>
              </Waypoint>
            );
          })}

        {/* buttonlink */}
        {PartnersCopies.sections.section3.buttonLink && (
          <div className={styles.partners_quotes_section__button_container}>
            <Button
              text={PartnersCopies.sections.section3.buttonLink.text}
              path={`${PartnersCopies.sections.section3.buttonLink.link}?topic=inquiry`}
              buttonClassName={styles.partners_quotes_section__button}
              textClassName={styles.partners_quotes_section__button_text}
            />
          </div>
        )}
      </div>
    );
  }
}
