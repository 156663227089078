import React from "react";
import Button from "../../../partials/button/components/Button";
import { handleFindPath } from "../../../constants/Copies";
import Image from "../../../partials/image/components/Image";
import DocIconMale from "../../../assets/icons/doctor-male.svg";
import DocIconFemale from "../../../assets/icons/doctor-female.svg";
import styles from "../styles/entity-avatar-section.module.scss";


const EntityAvatarSection = ({ entityData }: any) => {
  if (entityData) {
    if (
      entityData.ctxtype === 200 ||
      entityData.ctxtype === 300 ||
      (entityData.ctxtype === 400 && entityData.soc === 1)
    ) {
      // for vendor (200), product (300), and society (400, soc=1)
      // left - avatar; right - 5 icons, titles, specialties
      return (
        
        <div
          className={`${styles.entity_avatar__vendor}${
            !entityData.pubInfo ? ` ${styles.big}` : ""
          }`}
        >
          
          {entityData.photoUrl && (
            <div className={styles.entity_avatar__vendor__left}>
              <Image
                src={entityData.photoUrl}
                alt={`${entityData.name ? `${entityData.name}'s ` : ""}avatar`}
                background
                width={"100%"}
                height={"100%"}
                className={styles.entity_avatar__logo}
              />
            </div>
          )}
          {entityData.pubInfo && (entityData.ctxtype === 200 || entityData.ctxtype === 300)  && (
            <div>
              <br></br>
              <div className={styles.grid__container__element}>
                <div className={styles.grid__child__element}> 
                  <p
                          className={
                            styles.vendor__join__text
                          }
                        >
                          Want to view and participate in discussions with thought leaders?
                  </p>

                </div>
                <div className={styles.grid__child__element}>
                  <div className={styles.vendor__join__button__container}>
                  <Button
                          text={"Join DocMatter"}
                          path={handleFindPath("SIGNUP")}
                          buttonClassName={styles.vendor__join__button}
                          //textClassName={styles.entity__button__text}
                          //buttonClassName={styles.entity__signup__button}
                          //textClassName={styles.header__login__text}
                  />
                  </div>

                </div>
              </div>
 
              <div className={styles.entity_avatar__vendor__right}>
                
                <ul className={styles.entity_avatar__vendor__right__ul}>
                  {/* 1st */}
                  {(entityData.pubInfo.title1 || entityData.pubInfo.spec1) && (
                    <li className={styles.entity_avatar__vendor__right__li}>
                      {/* icon */}
                      <div
                        className={styles.entity_avatar__vendor__right__li__icon}
                      >
                        <Image
                          src={DocIconMale}
                          alt={"doctor icon male"}
                          background
                          width={"100%"}
                          height={"100%"}
                        />
                      </div>

                      <div
                        className={styles.entity_avatar__vendor__right__li__text}
                      >
                        {/* titles */}
                        {entityData.pubInfo.title1 && (
                          <p
                            className={
                              styles.entity_avatar__vendor__right__li__title
                            }
                          >
                            {entityData.pubInfo.title1}
                          </p>
                        )}

                        {/* specializations */}
                        {entityData.pubInfo.spec1 && (
                          <p
                            className={
                              styles.entity_avatar__vendor__right__li__spec
                            }
                          >
                            {entityData.pubInfo.spec1}
                          </p>
                        )}
                      </div>
                    </li>
                  )}

                  {/* 2nd */}
                  {(entityData.pubInfo.title2 || entityData.pubInfo.spec2) && (
                    <li className={styles.entity_avatar__vendor__right__li}>
                      {/* icon */}
                      <div
                        className={styles.entity_avatar__vendor__right__li__icon}
                      >
                        <Image
                          src={DocIconMale}
                          alt={"doctor icon male"}
                          background
                          width={"100%"}
                          height={"100%"}
                        />
                      </div>

                      <div
                        className={styles.entity_avatar__vendor__right__li__text}
                      >
                        {/* titles */}
                        {entityData.pubInfo.title2 && (
                          <p
                            className={
                              styles.entity_avatar__vendor__right__li__title
                            }
                          >
                            {entityData.pubInfo.title2}
                          </p>
                        )}

                        {/* specializations */}
                        {entityData.pubInfo.spec2 && (
                          <p
                            className={
                              styles.entity_avatar__vendor__right__li__spec
                            }
                          >
                            {entityData.pubInfo.spec2}
                          </p>
                        )}
                      </div>
                    </li>
                  )}

                  {/* 3rd */}
                  {(entityData.pubInfo.title3 || entityData.pubInfo.spec3) && (
                    <li className={styles.entity_avatar__vendor__right__li}>
                      {/* icon */}
                      <div
                        className={styles.entity_avatar__vendor__right__li__icon}
                      >
                        <Image
                          src={DocIconFemale}
                          alt={"doctor icon female"}
                          background
                          width={"100%"}
                          height={"100%"}
                        />
                      </div>

                      <div
                        className={styles.entity_avatar__vendor__right__li__text}
                      >
                        {/* titles */}
                        {entityData.pubInfo.title3 && (
                          <p
                            className={
                              styles.entity_avatar__vendor__right__li__title
                            }
                          >
                            {entityData.pubInfo.title3}
                          </p>
                        )}

                        {/* specializations */}
                        {entityData.pubInfo.spec3 && (
                          <p
                            className={
                              styles.entity_avatar__vendor__right__li__spec
                            }
                          >
                            {entityData.pubInfo.spec3}
                          </p>
                        )}
                      </div>
                    </li>
                  )}

                  {/* 4th */}
                  {(entityData.pubInfo.title4 || entityData.pubInfo.spec4) && (
                    <li className={styles.entity_avatar__vendor__right__li}>
                      {/* icon */}
                      <div
                        className={styles.entity_avatar__vendor__right__li__icon}
                      >
                        <Image
                          src={DocIconFemale}
                          alt={"doctor icon female"}
                          background
                          width={"100%"}
                          height={"100%"}
                        />
                      </div>

                      <div
                        className={styles.entity_avatar__vendor__right__li__text}
                      >
                        {/* titles */}
                        {entityData.pubInfo.title4 && (
                          <p
                            className={
                              styles.entity_avatar__vendor__right__li__title
                            }
                          >
                            {entityData.pubInfo.title4}
                          </p>
                        )}

                        {/* specializations */}
                        {entityData.pubInfo.spec4 && (
                          <p
                            className={
                              styles.entity_avatar__vendor__right__li__spec
                            }
                          >
                            {entityData.pubInfo.spec4}
                          </p>
                        )}
                      </div>
                    </li>
                  )}

                  {/* 5th */}
                  {(entityData.pubInfo.title5 || entityData.pubInfo.spec5) && (
                    <li className={styles.entity_avatar__vendor__right__li}>
                      {/* icon */}
                      <div
                        className={styles.entity_avatar__vendor__right__li__icon}
                      >
                        <Image
                          src={DocIconMale}
                          alt={"doctor icon male"}
                          background
                          width={"100%"}
                          height={"100%"}
                        />
                      </div>

                      <div
                        className={styles.entity_avatar__vendor__right__li__text}
                      >
                        {/* titles */}
                        {entityData.pubInfo.title5 && (
                          <p
                            className={
                              styles.entity_avatar__vendor__right__li__title
                            }
                          >
                            {entityData.pubInfo.title5}
                          </p>
                        )}

                        {/* specializations */}
                        {entityData.pubInfo.spec5 && (
                          <p
                            className={
                              styles.entity_avatar__vendor__right__li__spec
                            }
                          >
                            {entityData.pubInfo.spec5}
                          </p>
                        )}
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          )}
        </div>
      );
    } else if (entityData.ctxtype === 700) {
      // for webinar
      return (
        entityData.photoUrl && (
          <div className={styles.entity_avatar_webinar}>
            <Image
              src={entityData.photoUrl}
              alt={`${entityData.name ? `${entityData.name}'s ` : ""}avatar`}
              background
              width={"100%"}
              height={"100%"}
              className={styles.entity_avatar__logo}
            />
          </div>
        )
      );
    } else {
      // for user, group (400, soc=0), and specialty community
      return (
        entityData.photoUrl && (
          <div className={styles.entity_avatar}>
            <Image
              src={entityData.photoUrl}
              alt={`${entityData.name ? `${entityData.name}'s ` : ""}avatar`}
              background
              width={"100%"}
              height={"100%"}
              className={styles.entity_avatar__logo}
            />
          </div>
        )
      );
    }
  } else {
    return null;
  }
};

export default EntityAvatarSection;
