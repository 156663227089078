import React from 'react';
import Select from 'react-select';

import { MediaQueries } from '../../../constants/MediaQueries';
import styles from '../styles/form.module.scss';

interface Props {
  windowDimensions?: { width: number; height: number };
  id: string;
  value: string;
  placeholder?: string;
  options: string[];
  onDropdownSelect: (option: { value: string; label: string }) => void;
  error?: boolean;
  onBlur?: () => void;
}

interface State {
  selectedOption: { value: string; label: string } | null;
}

export default class Dropdown extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        selectedOption: { value: this.props.value, label: this.props.value },
      });
    }
    if (prevProps.value !== '' && this.props.value === '') {
      this.setState({ selectedOption: null });
    }
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    this.props.onDropdownSelect(selectedOption);
  };

  render() {
    const {
      id,
      placeholder,
      options,
      windowDimensions,
      error,
      onBlur,
    } = this.props;
    const { selectedOption } = this.state;
    const selectOptions = options.map((option) => {
      return { value: option, label: option };
    });

    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        height: '100%',
        border: 'none',
        backgroundColor: '#f5f5f6',
        boxShadow: state.isFocused ? '0 0 0 1px #56cbf9' : null,
        borderColor: state.isFocused ? '#56cbf9' : null,
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: 0,
        width: 0,
        height: 0,
        borderLeft: '8px solid transparent',
        borderRight: '8px solid transparent',
        borderTop: '6px solid #c4c4c4',
        marginRight: '18px',
      }),
      menu: (provided, state) => ({
        ...provided,
        marginTop: '4px',
        marginBottom: 0,
        backgroundColor: '#f5f5f6',
        boxShadow: '0 0 0 0 hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)',
      }),
      option: (provided, state) => ({
        ...provided,
        height: '30px',
        lineHeight: '30px',
        backgroundColor: state.isFocused ? '#e2e2e4' : '#f5f5f6',
        color: state.isSelected ? '#962d3e' : '#5d5e68',
        fontFamily: 'ProximaNova',
        fontSize: '16px',
        padding: 0,
        paddingLeft: '10px',
        paddingRight: '10px',
        transition: 'all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86)',
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        padding: 0,
        paddingLeft:
          windowDimensions && windowDimensions.width < MediaQueries.S_DESKTOP
            ? '10px'
            : '13px',
      }),
      singleValue: (provided, state) => ({
        ...provided,
        fontFamily: 'ProximaNova',
        fontSize: '16px',
        color: '#5d5e68',
        marginLeft: 0,
        marginRight: 0,
      }),
    };

    return (
      <Select
        name={id}
        placeholder={placeholder ? placeholder : ''}
        value={selectedOption}
        onChange={this.handleChange}
        onBlur={onBlur}
        options={selectOptions}
        isSearchable={false}
        isMulti={false}
        className={`${styles.form__dropdown}${
          error ? ` ${styles.form__error}` : ''
        }`} // container style
        styles={customStyles}
      />
    );
  }
}
