import React from 'react';
import { Helmet } from 'react-helmet';

import Link from '../../../utils/Link';
import { PrivacyCopies, handleFindPath } from '../../../constants/Copies';
import styles from '../styles/privacy.module.scss';

interface Props {
  routerProps: any;
  windowDimensions: { width: number; height: number };
  isTop: boolean;
  isMobileTablet: boolean;
  handleUpdateEntityData: (entityData: any) => void;
  handleResetEntityData: () => void;
  entityData: any;
}

export default class Privacy extends React.Component<Props> {
  componentDidMount() {
    this.props.handleResetEntityData();
  }

  render() {
    const { title1, title2 } = PrivacyCopies;

    return (
      <div className={styles.privacy}>
        <Helmet>
          <title>{PrivacyCopies.headTitle}</title>
        </Helmet>

        <div className={styles.privacy__inner}>
          <h1 className={styles.privacy__title}>
            {title1}
            <br />
            {title2}
          </h1>
          <div className={styles.privacy__body}>
            <h2>Welcome to DocMatter!</h2>
            <p>
              Our Mission is to support the clinicians who have dedicated their lives to providing care for others. We endeavor to make teaching, learning from, and collaborating with your peers as simple, efficient and impactful as possible. Central to this mission is our commitment to be transparent about the data we collect about you, how it is used, and with whom it is shared.
            </p>
            <h2>Privacy Policy</h2>
            <p>Last Revision: December 1, 2022</p>
            <p>
              This Privacy Policy applies to your use of DocMatter.com, DocMatter-branded apps, and other DocMatter-related sites, apps, communications, and other services that state that they are offered under this Privacy Policy ("<u>Services</u>").
            </p>
            <h3>
              <u>Changes to Our Privacy Policy</u>
            </h3>
            <p>
              We reserve the right, at our sole discretion, to change or modify portions of this Privacy Policy at any time. If the Privacy Policy is changed, we will post the changes on this page, and will indicate at the top of this page the date this Privacy Policy was last revised. Any changes will become effective upon the date indicated. If required by law, we will also notify you of any material changes to the Privacy Policy. Your continued use of the Services after the date any such changes become effective constitutes your unequivocal acceptance of, and agreement to, the new Privacy Policy (including any and all changes to the Privacy Policy).
            </p>
            <h2>1. Data We Collect</h2>
            <p>
              When you interact with us through the Services, we may collect personal data and other information from you, as further described below:
            </p>
            <h3>
              <u>Personal Data That You Provide Through the Services</u>
            </h3>
            <p>
              We collect personal data from you when you voluntarily provide such information, such as when you contact us with inquiries, register for access to the Services (thus becoming a "<u>Member</u>"), or use certain Services. For example, when you register and use the Services, you provide us with information such as your name, email address, physical address, and telephone number.
            </p>
            <p>
              You have control over the information you share in your Member profile, as well as content you share through the Services. If you share confidential information through the Services, there is no guarantee that information will stay confidential. Please think about this before you share.
            </p>
            <h3>
              <u>Data from Others</u>
            </h3>
            <p>
              In addition to information we collect from you, we may receive personal data about you through publicly available and other third-party sources, and through others' use of the Services, including other Members and DocMatter partner members. For example, we may acquire clinician or other lists from DocMatter partners.
            </p>
            <h3>
              <u>Data Collected through Your Use of the Services</u>
            </h3>
            <p>
              When you interact with DocMatter through the Services, we also passively receive and store certain information using various technologies, such as your Internet Protocol (IP) address, user settings, IMEI, MAC address, cookie identifiers, mobile carrier, mobile device type, details about your browser, operating system or device, and other unique identifiers. DocMatter may store such information itself or such information may be included in databases owned and maintained by DocMatter agents or service providers. The Services may use such information and pool it with other information to track use of the Services, including, for example, the total number of visitors, the number of visitors to each page, and the domain names of our visitors' internet service providers. Doing this allows us to continuously improve the Services for our Members.
            </p>
            <h3>
              <u>Cookies, Pixels, and Other Technologies</u>
            </h3>
            <p>
              In operating the Services, we may use technologies that are operationally necessary, performance-related, functionality-related, or Targeting-related to automatically collect information through the Services ("<u>Technology</u>" or "<u>Technologies</u>"). Technologies are essentially small data files placed on your computer, tablet, mobile phone, or other devices that allow us to record certain pieces of information whenever you visit or interact with our Services.
            </p>
            <p>
              One such Technology is called "cookies." A cookie is a piece of information that the computer that hosts our Services gives to your browser when you access the Services. Our cookies help provide additional functionality to the Services, help us analyze usage of the Services more accurately, and ensure you are able to stay logged in when you click "Remember Me" during the sign-in process.
            </p>
            <p>
              You may stop or restrict the placement of Technologies on your device or remove them by adjusting your preferences as your browser or device permits. On most web browsers, you will find a "help" section on the toolbar. Please refer to this section for information on how to receive notification when you are receiving a new cookie and how to turn cookies off. We recommend that you leave cookies turned on because they allow you to take advantage of some of the features of the Services, such as enabling you to not have to log in each time you want to access the Services from your personal computer's browser.
            </p>
            <p>
              We may also use a "pixel tag" (also known as a "web beacon"), which is a piece of code embedded within the Services that collects information about users' engagement on the Services.
            </p>
            <p>
            We may also use MixPanel to collect information regarding visitor behavior and visitor demographics on some of our Services, and to develop content. 
  For more information about MixPanel, please visit  <a href="https://mixpanel.com/legal/privacy-policy/">https://mixpanel.com/legal/privacy-policy/</a>. 
  You can opt out of MixPanel's collection and processing of data generated by your use of the Services 
  by following the procedure described at <a href="https://help.mixpanel.com/hc/en-us/articles/360001113426-Opt-Out-of-Tracking#do-not-track-settings">https://help.mixpanel.com/hc/en-us/articles/360001113426-Opt-Out-of-Tracking#do-not-track-settings</a>.
            </p>
            <h3>
              <u>Services Via Mobile Devices</u>
            </h3>
            <p>
              DocMatter provides Services that are specifically designed to be compatible and used on mobile devices. DocMatter will collect certain information that your mobile device sends when you use such Services, like a device identifier, user settings, location information, mobile carrier, and the operating system of your device. Mobile versions of DocMatter's Services may require that users log in with an account. In such cases, information about use of mobile versions of the Services may be associated with accounts. In addition, DocMatter may enable individuals to download an application, widget, or other tool that can be used on mobile or other computing devices. Some of these tools may store information on mobile or other devices, and may transmit personal data to DocMatter to enable individuals to access accounts and to enable DocMatter to track use of these tools.
            </p>
            <h3>
              <u>Protected Health Information and Other Patient Information</u>
            </h3>
            <p>
              DocMatter does not intentionally collect protected health information (as such term is defined in the Health Insurance Portability and Accountability Act of 1996 and the rules promulgated thereunder ("<u>HIPAA</u>")), personally identifiable information of patients, or other patient information. It is your responsibility to comply with HIPAA and any other confidentiality obligations that apply to you. If you have reason to believe that any such information is available through the Services, please contact us (contact information below).
            </p>
            <h2>2. Our Use of Personal Data and Other Information</h2>
            <p>
              DocMatter uses personal data for a variety of business purposes consistent with applicable law and this Privacy Policy, or as otherwise communicated to you at the point of collection, such as to: manage Members and accounts; respond to questions, comments, and requests, and otherwise communicate with you; provide access to certain areas, functionalities, and features of our Services; measure interest in and engagement with the Services; conduct research and development; prevent fraud or other potentially prohibited and/or illegal activities; comply with laws; improve on and develop new Services; detect and resolve bugs and other software issues; enforce our Terms and this Privacy Policy; and provide you with notifications and customized materials. For instance, if you contact us by email, we will use the personal data you provide to answer your question or resolve your problem. Also, if you provide personal data in order to obtain access to the Services, we will use your personal data to provide you with access to such Services, and to evaluate improvement opportunities for the Services. DocMatter may also use personal data and other information collected through the Services to help us improve the content and functionality of the Services, better understand our users, or otherwise improve your experience with the Services.
            </p>
            <p>
              Individuals who provide us with personal data, or whose personal data we obtain from third parties, may receive periodic emails or phone calls from us with information on the Services that we believe may be of interest. You may opt out of receiving communications regarding the Services at any time by contacting us at <a href='mailto:legal@docmatter.com'>legal@docmatter.com</a>.
            </p>
            <h3>
              <u>Aggregated Information</u>
            </h3>
            <p>
              DocMatter may use information about you to create aggregated information, such as demographic information, location information, or other analyses we create. Aggregated information is used for a variety of functions, including the measurement of visitors' interest in, and use of, various portions or features of the Services. Aggregated information is not personal data, and Members cannot be identified through such anonymized information. We may use such information in a number of ways, including research, internal analysis, analytics, and any other legally permissible purposes. We may share this information with third parties in a form that is designed to prevent anyone from identifying you.
            </p>
            <h3>
              <u>Member Referrals</u>
            </h3>
            <p>
              DocMatter allows you to provide information about your colleagues or business contacts through our referral Services, which may allow you to forward or share certain content with said colleagues or business contacts, such as an email "Invite" to your colleagues or business contacts to use our Services. Email addresses that you may provide for a colleague or business contact will be used to send your colleague or business contact information about the Services.
            </p>
            <h3>
              <u>Advertising</u>
            </h3>
            <p>
              DocMatter does not participate in behavioral or targeted advertising, either on the DocMatter Services or elsewhere. Additionally, DocMatter does not permit Members or others to advertise on the Services.
            </p>
            <h3>
              <u>Research and Development</u>
            </h3>
            <p>
              DocMatter may use personal data, alone or with information obtained from other sources, in order to help us to optimally deliver our existing Services or develop new Services. We may perform research (online and offline) via surveys or otherwise within specific, opt-in areas of the Services. All survey responses are voluntary, and the information collected will be used for research and reporting purposes to help us better serve individuals by learning more about their needs and the quality of the Services we provide. The survey responses may be utilized to determine the effectiveness of our Services. If an individual participates in a survey, the information given will be used along with that of other study participants.
            </p>
            <h3>
              <u>Cross-Device Tracking</u>
            </h3>
            <p>
              Your activity may be tracked across different websites and different devices or apps. For example, we may attempt to match your activity on your mobile device with your activity on your laptop to prevent unauthorized access to your account.
            </p>
            <h3>
              <u>Other Uses</u>
            </h3>
            <p>
              DocMatter may use personal data where you have consented, where using personal data is part of a contract of which DocMatter is a party, or for purposes for which we have a legitimate interest, such as individual or market research, anti-fraud protection, or any other purpose disclosed to you (including through this Privacy Policy) at the time you provide personal data or with your consent.
            </p>
            <h2>3. Our Disclosure of Personal Data and Other Information</h2>
            <p>
              DocMatter is not in the business of selling information. We consider information to be a vital part of our relationship with you. There are, however, certain circumstances in which we may share personal data with certain third parties without further notice to you, as set forth below:
            </p>
            <h3>
              <u>Your Member Profile</u>
            </h3>
            <p>
              Any personal data included in your profile will be available to other Members. Your contact information (email and phone number) will not be included in your profile, but we do include, for example, your institution, profile photo, hospital affiliations, publications, etc. You can edit the information available in your Member profile.
            </p>
            <h3>
              <u>Business Transfers</u>
            </h3>
            <p>
              As we develop our business, we may sell or buy businesses or assets. In the event of a corporate sale, merger, acquisition, reorganization, dissolution, or similar event, personal data (public and nonpublic) may be part of the transferred assets. In such event, DocMatter will endeavor to direct the transferee to use personal data in a manner that is consistent with the Privacy Policy in effect at the time such personal data was collected.
            </p>
            <h3>
              <u>Agents, Consultants, and Related Third Parties</u>
            </h3>
            <p>
              DocMatter, like many businesses, sometimes hires other companies to perform certain business-related functions and we may share any personal data we collect with our third-party service providers for the purpose of maintaining and/or improving the Services. Examples of such functions include mailing information and maintaining databases.
            </p>
            <h3>
              <u>Other Required Disclosures</u>
            </h3>
            <p>
              We may access, preserve, and disclose personal data, other account information, and content if we believe doing so is required or appropriate to: (i) comply with law enforcement or national security requests and/or legal process, such as a court order or subpoena; (ii) respond to your requests; (iii) protect the rights, property, or safety of you, DocMatter, or others; (iv) to enforce DocMatter policies or contracts; (v) to collect amounts owed to DocMatter; (vi) when we believe disclosure is necessary or appropriate to prevent physical harm or financial loss, or in connection with an investigation or prosecution of suspected or actual illegal activity; or (vii) if we, in good faith, believe that disclosure is otherwise necessary or advisable.
            </p>
            <h2>4. International Users and Data Transfers</h2>
            <p>
              By using the Services, DocMatter will transfer data to the United States. By choosing to utilize the Services or otherwise provide information to us, you agree that any dispute over privacy or the terms contained in this Privacy Policy will be governed by the laws of the United States, and the adjudication of any disputes arising in connection with DocMatter or the Site will be in accordance with the{' '}
              <Link to={handleFindPath('TERMS')}>Terms of Service</Link>.
            </p>
            <p>
              If you are visiting from the European Union or other regions with laws governing data collection and use, please note that you are agreeing to the transfer of your information to the United States and to processing of your data globally. By providing your personal data, you consent to any transfer and processing in accordance with this Privacy Policy.
            </p>
            <p>
              You agree that all personal data collected via or by DocMatter may be transferred, processed, and/or stored anywhere in the world, including but not limited to, the United States, the European Union, in the cloud, on our servers, or on the servers of our service providers. Your personal data may be accessible to law enforcement or other authorities pursuant to a lawful request. By providing information to DocMatter, you explicitly consent to the storage of your personal data in these locations.
            </p>
            <h2>5. Your Choices</h2>
            <p>
              If you choose not to provide certain elements of personal data, you may not be able to use certain Services.
            </p>
            <h3>
              <u>Your Privacy Rights</u>
            </h3>
            <p>Consistent with applicable laws, you may have the right to request:</p>
            <ul>
              <li>
                <b>Access to/Portability of</b> personal data about you consistent with legal requirements.  In addition, you may have the right in some cases to receive or have your electronic personal data transferred to another party.
              </li>
              <li>
                <b>Correction of</b> your personal data where it is inaccurate or incomplete.  In some cases, we may provide self-service tools that enable you to update your personal data.
              </li>
              <li>
                <b>Deletion of</b> your personal data, subject to certain exceptions prescribed by law.
              </li>
              <li>
                <b>Restriction of or object to processing of</b> your personal data, including the right to opt out of the sale of your personal data to third parties, if applicable, where such requests are permitted by law.
              </li>
            </ul>
            <p>
              If you would like to exercise any of these rights, please contact us at{' '}<a href='mailto:legal@docmatter.com'>legal@docmatter.com</a>. Please include your full name, email address associated with your account, and a detailed description of your data request. We will process such requests in accordance with applicable laws.  Although DocMatter makes good faith efforts to honor individual requests with respect to personal data, there may be circumstances in which DocMatter is unable to do so, including but not limited to: where the information contains legal privilege; would compromise others' privacy or other legitimate rights; where the burden or expense would be disproportionate to the risks to the individual's privacy in the case in question; or where it is commercially proprietary. If DocMatter determines that your rights should be restricted in any particular instance, we will provide you with an explanation of why that determination has been made and a contact point for any further inquiries. To protect your privacy, DocMatter will take commercially reasonable steps to verify your identity before fulfilling your request.
            </p>
            <h3>
              <u>Opting Out of Communications</u>
            </h3>
            <p>
              If you receive an unwanted email from us, you may reply to the email requesting to opt out of receiving future emails. We will process your request within a reasonable time after receipt. We may also send you certain non-promotional communications regarding DocMatter and our Services, and you will not be able to opt out of those communications (e.g., communications regarding updates to our Terms or this Privacy Policy).
            </p>
            <p>
              We may occasionally send you push notifications through our mobile application with notices that may be of interest to you. You may at any time opt out from receiving these types of communications by removing the mobile application from your mobile device or by adjusting the settings on your device to prevent the app from pushing notices. We may also collect location-based information if you use our mobile application. You may opt out of this collection by removing the mobile application from your mobile device or by adjusting the settings on your device to prevent the app from collecting your location.
            </p>
            <p>
              Do Not Track ("<u>DNT</u>") is a privacy preference that users can set in certain web browsers. DNT is a way for users to inform websites and services that they do not want certain information about their webpage visits collected over time and across websites or online services. Please note that we do not respond to or honor DNT signals or similar mechanisms transmitted by web browsers.
            </p>
            <p>
              As noted above, you may stop or restrict the placement of cookies on your computer or remove them from your browser by adjusting your web browser preferences. Please note that cookie-based opt-outs are not effective on mobile applications. However, on many mobile devices, application users may opt out of certain mobile ads via their device settings.
            </p>
            <h2>6. Children's Information</h2>
            <p>
              Our Services are not directed to children under 13 (or other age as required by local law), and we do not knowingly collect personal data from children. In addition, you are not permitted to use our Services if you do not meet the minimum age requirement applicable to our Services in your jurisdiction. If we learn that we have collected any personal data about a child in violation of applicable laws, we will promptly take steps to delete such information and terminate the child's account.
            </p>
            <h2>7. Data Retention</h2>
            <p>
              DocMatter retains the personal data we receive as described in this Privacy Policy for as long as you use our Services or as necessary to fulfill the purpose(s) for which it was collected, provide our Services, resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements, and/or comply with applicable laws. Unless DocMatter is otherwise obligated to retain data, upon a Member's request that their account be terminated and data deleted, DocMatter will endeavor to delete such Member's account and data.
            </p>
            <h2>8. Security</h2>
            <p>
              DocMatter takes reasonable steps to protect the data (personal, public, non-public, and otherwise) provided via the Services from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, the Internet cannot be guaranteed to be 100% secure, and we cannot ensure or warrant the security of any information you provide to us. We do not accept liability for any unintentional disclosures.
            </p>
            <p>
              By using the Services or by providing personal data to us, you agree that we may communicate with you electronically regarding security, privacy, and administrative issues relating to your use of the Services. If we learn of a security system's breach, we may attempt to notify you electronically by posting a notice on the Services or sending an email to you. You may have a legal right to receive this notice in writing.
            </p>
            <h2>9. Sites and Services of Others</h2>
            <p>
              This Privacy Policy applies only to the Services. The Services may contain links to other websites, not operated or controlled by DocMatter. The Privacy Policy does not apply to third-party websites. Any links from the Services to third party websites or content do not imply that DocMatter endorses or has reviewed the third-party websites or content. We recommend contacting those third-party websites directly for information on their privacy policies.
            </p>
            <h2>10. Supervisory Authority</h2>
            <p>
              If you are located in the European Economic Area, you have the right to lodge a complaint with a supervisory authority if you believe our processing of your personal data violates applicable law.
            </p>
            <h2>11. Supplemental California Privacy Notice</h2>
            <p>
              California law permits users who are California residents to request and obtain from us, once a year, free of charge, a list of the third parties to whom we have disclosed their personal data (if any), for their direct marketing purposes, in the prior calendar year, as well as the type of personal data disclosed to those parties. Note, however, that DocMatter does not share personal data with third parties for the third parties' own marketing purposes.
            </p>
            <p>
              Additionally, under the California Consumer Privacy Act (“CCPA”), California residents have certain rights around our collection, use, and sharing of personal data as explained in the “Your Privacy Rights” section above. If you wish to exercise any of your rights as a California resident, please contact us as set forth below. If you exercise any rights under the CCPA, we may ask certain questions in order to verify your identity and your status as a California resident. An agent may also submit a request on your behalf, but we may require that you verify the agent has been authorized by you. You have the right not to receive discriminatory treatment by DocMatter for the excise of your rights conferred by the CCPA.
            </p>
            <p>
              We collected the following categories of consumer personal information (as defined by the CCPA) in our role as a business within the last 12 months:
            </p>

            <div className={styles.table}>
              <div className={`${styles.tableRow} ${styles.header}`}>
                <div className={`${styles.tableColumn} ${styles.tableColumn1}`}>Categories of Information Collected</div>
                <div className={`${styles.tableColumn} ${styles.tableColumn2}`}>Information Examples</div>
              </div>

              <div className={styles.tableRow}>
                <div className={`${styles.tableColumn} ${styles.tableColumn1}`}>Identifiers</div>
                <div className={`${styles.tableColumn} ${styles.tableColumn2}`}>Name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, or other similar identifiers.</div>
              </div>

              <div className={styles.tableRow}>
                <div className={`${styles.tableColumn} ${styles.tableColumn1}`}>Professional or employment-related information</div>
                <div className={`${styles.tableColumn} ${styles.tableColumn2}`}>Current or past job history.</div>
              </div>
            </div>
            
            <p>
              The categories of sources from which we collect personal information and the purposes for which we collected the personal information are set forth in this Privacy Policy above.
            </p>
            <p>
              We disclosed for a business purpose the following categories of personal data to the following categories of third parties in the preceding 12 months:
            </p>

            <div className={styles.table}>
              <div className={`${styles.tableRow} ${styles.header}`}>
                <div className={`${styles.tableColumn} ${styles.tableColumn1}`}>Categories of Information Collected</div>
                <div className={`${styles.tableColumn} ${styles.tableColumn2}`}>Categories of Third Parties </div>
              </div>

              <div className={styles.tableRow}>
                <div className={`${styles.tableColumn} ${styles.tableColumn1}`}>Identifiers</div>
                <div className={`${styles.tableColumn} ${styles.tableColumn2}`}>Service providers, business partners, affiliates.</div>
              </div>

              <div className={styles.tableRow}>
                <div className={`${styles.tableColumn} ${styles.tableColumn1}`}>Internet or other electronic network activity</div>
                <div className={`${styles.tableColumn} ${styles.tableColumn2}`}>Service providers, business partners, affiliates.</div>
              </div>
            </div>

            <p>
              We do not “sell” personal information as such term is defined by the CCPA, nor do we have actual knowledge that we have “sold” any personal information of minors under 16 years of age.
            </p>
            <h2>12. Contact Information</h2>
            <p>
              Please feel free to contact us if you have any questions about DocMatter's Privacy Policy, or the information practices of the Services, or for any purpose described above or otherwise, at (855) DOC-MATTER,{' '}<a href='mailto:legal@docmatter.com'>legal@docmatter.com</a>, or
              via mail:
            </p>
            <div style={{ marginLeft: '20px' }}>
              DocMatter Privacy Policy Questions
              <br /> 460 Brannan Street #77452
              <br /> San Francisco, CA 94107
              <br /> USA <br />
            </div>
            <p>
              To reach our Data Protection Officer, please call (855) DOC-MATTER, email{' '}<a href='mailto:dataprotection@docmatter.com'>
                dataprotection@docmatter.com
              </a>
              , or send correspondence to:
            </p>
            <div style={{ marginLeft: '20px' }}>
              Yong Lee, Ph.D
              <br /> DocMatter Data Protection Officer
              <br /> 460 Brannan Street #77452
              <br /> San Francisco, CA 94107
              <br /> USA <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
