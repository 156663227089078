import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  to:
    | string
    | {
        pathname: string;
        search: string;
        hash: string;
        state: Object;
      };
  children?: React.ReactNode;
  onClick?: () => void;
  className?: string;
  style?: Object;
  title?: string;
}

interface State {}

export default class Link extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  renderLink = () => {
    const { to, children, onClick, className, style, title } = this.props;
    return (
      <RouterLink
        to={to}
        onClick={onClick}
        className={className}
        style={style}
        title={title}
      >
        {children}
      </RouterLink>
    );
  };

  renderSpan = () => {
    const { children, className, style } = this.props;
    return (
      <span className={className} style={style}>
        {children}
      </span>
    );
  };

  render() {
    // if not on that page already, render a normal link, otherwise render a span
    if (
      (typeof this.props.to === 'string' &&
        window.location.pathname !== this.props.to) ||
      (typeof this.props.to === 'object' &&
        window.location.pathname !== this.props.to.pathname)
    ) {
      return this.renderLink();
    } else {
      return this.renderSpan();
    }
  }
}
