// import moment from 'moment-timezone';

const EntityWelcomeText = (entityData: any) => {
  // user
  if (entityData?.ctxtype === 100) {
    return `Want to interact with ${entityData?.name}?`;
  }

  // vendor or product
  else if (entityData?.ctxtype === 200 || entityData?.ctxtype === 300) {
    return "Want to interact with experts?";
  }

  // group
  else if (entityData?.ctxtype === 400) {
    return `Want to join?`;
  }

  // specialties community
  else if (entityData?.ctxtype === 600) {
    return `Want to interact with professionals on ${entityData?.name}?`;
  }

  // // webinar
  // else if (entityData?.ctxtype === 700) {
  //   const today = moment().tz('America/Los_Angeles'); // today (now)'s Date object
  //   const startTime = moment(new Date(entityData?.time), 'America/Los_Angeles'); // webinar's start time Date object
  //   if (startTime > today) {
  //     // upcoming
  //     return 'Join the Webinar';
  //   } else {
  //     // past webinar
  //     return 'View Webinar Recording';
  //   }
  // }
};

export default EntityWelcomeText;
