import React from 'react';
import { Helmet } from 'react-helmet';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import { Waypoint } from 'react-waypoint';

import HomeHero from './HomeHero';
import HomeVideoSection from './HomeVideoSection';
import HomeLogos from './HomeLogos';
import HomeStatsSection from './HomeStatsSection';

import Modal from '../../../partials/modal/components/Modal';
import Video from '../../../partials/video/components/Video';
import { HomeCopies, HrefOriginLogIn } from '../../../constants/Copies';
import styles from '../styles/home.module.scss';
import Image from '../../../partials/image/components/Image';

interface Props {
  routerProps: any;
  windowDimensions: { width: number; height: number };
  isTop: boolean;
  isMobileTablet: boolean;
  handleUpdateEntityData: (entityData: any) => void;
  handleResetEntityData: () => void;
  entityData: any;
}

interface State {
  isTop: boolean;
  isModalOpen: boolean;
  isVideoPlaying: boolean;
}

export default class Home extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isTop: true,
      isModalOpen: false,
      isVideoPlaying: false,
    };
  }

  private home = React.createRef<HTMLDivElement>();

  componentDidMount() {
    this.props.handleResetEntityData();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // modal & body-scroll-lock
    if (!prevState.isModalOpen && this.state.isModalOpen) {
      this.home.current && disableBodyScroll(this.home.current);
    }
    if (prevState.isModalOpen && !this.state.isModalOpen) {
      this.home.current && enableBodyScroll(this.home.current);
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  handleOpenVideoModal = () =>
    this.setState({ isModalOpen: true }, this.handlePlayVideo);
  handleCloseVideoModal = () =>
    this.setState({ isModalOpen: false }, this.handlePauseVideo);

  handlePlayVideo = () => this.setState({ isVideoPlaying: true });
  handlePauseVideo = () => this.setState({ isVideoPlaying: false });

  render() {
    const {
      windowDimensions,
      isMobileTablet,
      handleUpdateEntityData,
    } = this.props;
    const { isTop, isModalOpen, isVideoPlaying } = this.state;

    return (
      <div className={styles.home} ref={this.home}>
        <Helmet>
          <title>{HomeCopies.headTitle}</title>
        </Helmet>

        <h1 className={'hidden_page_h1'}>{HomeCopies.headTitle}</h1>

        <HomeHero
          windowDimensions={windowDimensions}
          isMobileTablet={isMobileTablet}
          key={`${windowDimensions.width}x${windowDimensions.height}`}
        />

        <Waypoint onEnter={() => this.setState({ isTop: false })}>
          <div>
            <HomeVideoSection
              handleOpenVideoModal={this.handleOpenVideoModal}
              windowDimensions={windowDimensions}
              isMobileTablet={isMobileTablet}
              isTop={isTop}
              isVideoPlaying={isVideoPlaying}
              handlePlayVideo={this.handlePlayVideo}
            />

            <HomeLogos
              windowDimensions={windowDimensions}
              isMobileTablet={isMobileTablet}
            />

            <HomeStatsSection />
            <div className={styles.home__stores}>
              <a
                href={HomeCopies.stores.appStore.link}
                target={
                  HomeCopies.stores.appStore.link.includes(HrefOriginLogIn)
                    ? '_self'
                    : '_blank'
                }
                rel='noopener noreferrer'
                title={HomeCopies.stores.appStore.text}
                className={styles.home__stores__link}
              >
                <Image
                  src={HomeCopies.stores.appStore.img}
                  alt={HomeCopies.stores.appStore.text}
                  width={'120px'}
                  height={'40px'}
                  className={styles.home__stores__icon}
                />
              </a>
              <a
                href={HomeCopies.stores.googlePlayStore.link}
                target={
                  HomeCopies.stores.googlePlayStore.link.includes(
                    HrefOriginLogIn
                  )
                    ? '_self'
                    : '_blank'
                }
                rel='noopener noreferrer'
                title={HomeCopies.stores.googlePlayStore.text}
                className={styles.home__stores__link}
              >
                <Image
                  src={HomeCopies.stores.googlePlayStore.img}
                  alt={HomeCopies.stores.googlePlayStore.text}
                  width={'136px'} // original 512px
                  height={'40px'} // original 151px
                  className={styles.home__stores__icon}
                />
              </a>
            </div>
          </div>
        </Waypoint>

        {/* for mobile/tablet, do not render modal */}
        {isMobileTablet ? (
          <Video
            url={HomeCopies.videoSection.fullVideoUrl}
            playing={this.state.isVideoPlaying}
            windowDimensions={windowDimensions}
            hide
            onPause={this.handlePauseVideo}
            onPlay={this.handlePlayVideo}
          />
        ) : (
          <Modal
            isOpen={isModalOpen}
            handleClose={this.handleCloseVideoModal}
            video
          >
            <Video
              url={HomeCopies.videoSection.fullVideoUrl}
              playing={this.state.isVideoPlaying}
              windowDimensions={windowDimensions}
              onPlay={this.handlePlayVideo}
            />
          </Modal>
        )}
      </div>
    );
  }
}
