import Home from '../views/home/components/Home'
import Company from '../views/company/components/Company'
import Partners from '../views/partners/components/Partners'
import Support from '../views/support/components/Support'
import Careers from '../views/careers/components/Careers'
import Signup from '../views/signup/components/Signup'
import Terms from '../views/terms/components/Terms'
import Privacy from '../views/privacy/components/Privacy'
import Entity from '../views/entity/components/Entity'
import { prefix } from './Copies'
// import { RoutesConstants } from './Copies';

export const Routes = [
  {
    constant: 'COMPANY',
    path: `${prefix}/company`,
    component: Company,
    text: 'Company',
  },
  {
    constant: 'PARTNERS',
    path: `${prefix}/partners`,
    component: Partners,
    text: 'Partners',
  },

  {
    constant: 'INDUSTRY',
    path: `${prefix}/industry`,
    component: Partners,
    text: 'Industry',
  },
  {
    constant: 'SUPPORT',
    path: `${prefix}/support`,
    component: Support,
    text: 'Support',
  },
  // careers page is not being used - now an external link to 'https://apply.workable.com/docmatter-inc-2/'
  {
    constant: 'CAREERS',
    path: `${prefix}/careers`,
    component: Careers,
    text: 'Careers',
  },
  {
    constant: 'SIGNUP',
    path: `${prefix}/signup`,
    component: Signup,
    text: 'Sign Up',
  },
  {
    constant: 'TERMS',
    path: `${prefix}/terms`,
    component: Terms,
    text: 'Terms of Service',
  },
  {
    constant: 'PRIVACY',
    path: `${prefix}/privacy`,
    component: Privacy,
    text: 'Privacy',
  },
  {
    constant: 'ENTITY',
    path: `${prefix}/:slug`,
    component: Entity,
    text: 'Entity',
  },
  {
    constant: 'HOME',
    path: `${prefix}/`,
    component: Home,
    text: 'Home',
  },
]

// const Components = [
//   Home,
//   Company,
//   Partners,
//   Support,
//   Careers,
//   Signup,
//   Terms,
//   Privacy,
//   Entity,
// ];

// export const Routes = RoutesConstants.map((route) => {
//   return {
//     ...route,
//     component: Components.find(
//       (component) =>
//         component.name.toLowerCase() === route.constant.toLowerCase()
//     ),
//   };
// });
