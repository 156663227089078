import React from 'react';

import generalStyles from '../styles/image.module.scss';

interface Props {
  src: string;
  alt: string;
  background?: boolean;
  width: string;
  height: string;
  style?: Object;
  className?: any;
  innerRef?: any;
}

const Image = (props: Props) => {
  const {
    src,
    alt,
    background,
    width,
    height,
    style,
    className,
    innerRef,
  } = props;

  if (background) {
    return (
      <span
        ref={innerRef ? innerRef : null}
        className={`${generalStyles.background_img}${
          className ? ` ${className}` : ''
        }`}
        style={{
          backgroundImage: `url(${src})`,
          width: width,
          height: height,
          ...style,
        }}
        role={'img'}
        aria-label={alt}
      />
    );
  } else {
    return (
      <img
        ref={innerRef ? innerRef : null}
        className={`${generalStyles.img}${className ? ` ${className}` : ''}`}
        style={{
          width: width,
          height: height,
        }}
        src={src}
        alt={alt}
      />
    );
  }
};

export default Image;
