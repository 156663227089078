// careers page is not being used - now an external link to 'https://apply.workable.com/docmatter-inc-2/'
import React from 'react'
import { Helmet } from 'react-helmet'

import { CareersCopies } from '../../../constants/Copies'
import styles from '../styles/careers.module.scss'
import '../styles/workable.scss'

interface Props {
  routerProps: any
  windowDimensions: { width: number; height: number }
  isTop: boolean
  isMobileTablet: boolean
  handleUpdateEntityData: (entityData: any) => void
  handleResetEntityData: () => void
  entityData: any
}

export default class Careers extends React.Component<Props> {
  private careers = React.createRef<HTMLDivElement>()

  componentDidMount() {
    this.props.handleResetEntityData()

    if (this.careers) {
      const script = document.createElement('script')
      script.innerHTML = `whr(document).ready(function() {
        whr_embed(349340, {
          detail: 'titles',
          base: 'jobs',
          zoom: 'country',
          grouping: 'none',
        });
      })`
      document.body.appendChild(script)
    }
  }

  render() {
    return (
      <div className={styles.careers} ref={this.careers}>
        <Helmet>
          <title>{CareersCopies.headTitle}</title>
        </Helmet>

        <div className={styles.careers__inner}>
          <h1 className={styles.careers__title}>{CareersCopies.title}</h1>

          {/* data coming in */}
          <div className={styles.careers__body} id='whr_embed_hook'></div>
        </div>
      </div>
    )
  }
}
