import React from 'react';
import { Helmet } from 'react-helmet';

import Link from '../../../utils/Link';
import { TermsCopies, handleFindPath } from '../../../constants/Copies';
import styles from '../styles/terms.module.scss';

interface Props {
  routerProps: any;
  windowDimensions: { width: number; height: number };
  isTop: boolean;
  isMobileTablet: boolean;
  handleUpdateEntityData: (entityData: any) => void;
  handleResetEntityData: () => void;
  entityData: any;
}

export default class Terms extends React.Component<Props> {
  componentDidMount() {
    this.props.handleResetEntityData();
  }

  render() {
    const { title1, title2 } = TermsCopies;

    return (
      <div className={styles.terms}>
        <Helmet>
          <title>{TermsCopies.headTitle}</title>
        </Helmet>

        <div className={styles.terms__inner}>
          <h1 className={styles.terms__title}>
            {title1}
            <br />
            {title2}
          </h1>
          <div className={styles.terms__body}>
            <h2>Welcome to DocMatter!</h2>
            <p>
              Our Mission is to support the clinicians who have dedicated their
              lives to providing care for others. We endeavor to make teaching,
              learning from, and collaborating with your peers as simple,
              efficient and impactful as possible.
            </p>
            <h2>Terms of Service</h2>
            <p>Last Revision: June 12, 2019</p>
            <p>
              <b>
                IMPORTANT NOTICE REGARDING ARBITRATION: WHEN YOU AGREE TO THESE
                TERMS OF USE YOU ARE AGREEING (WITH LIMITED EXCEPTION) TO
                RESOLVE ANY DISPUTE BETWEEN YOU AND DOCMATTER INC., THROUGH
                BINDING, INDIVIDUAL ARBITRATION, RATHER THAN IN COURT. PLEASE
                CAREFULLY REVIEW SECTION 9, "DISPUTE RESOLUTION," BELOW, FOR
                DETAILS REGARDING ARBITRATION (INCLUDING THE PROCEDURE TO OPT
                OUT OF ARBITRATION).
              </b>
            </p>
            <p>
              You agree that by clicking "Sign Up", or by similarly accessing,
              registering, or otherwise using our Services (defined below), you
              are agreeing to personally enter into a contract with DocMatter
              Inc. (also referred to as "we," and "us"), even if you are using
              our Services through a DocMatter Partner, you are indicating that
              you are at least eighteen (18) years of age or older, and you
              understand and agree to be bound by the terms contained in this
              contract (referred to as the "<u>Terms of Service</u>," or "
              <u>Terms</u>"). If you do not agree to the Terms, do not click
              "Sign Up" or otherwise create or request an account, and do not
              access or otherwise use any of our Services. If you wish to
              terminate this contract at any time after your account has been
              created, you can do so by requesting your account be terminated
              (see below for directions for termination), and no longer
              accessing or using our Services.
            </p>
            <p>
              These Terms constitute the entire agreement between you and
              DocMatter, and govern your use of the Services, superseding any
              prior agreements between you and DocMatter with respect to use of
              the Services.
            </p>
            <p>
              Registered users of our Services are "<u>Members</u>," and these
              Terms apply to all Members. Upon registration, an account will be
              created for you, including a Member profile. For more information
              about your profile, please review our{' '}
              <Link to={handleFindPath('PRIVACY')}>Privacy Policy</Link>.
            </p>
            <h2>1. Our Services</h2>
            <p>
              These Terms apply to your use of DocMatter.com, DocMatter-branded
              apps, and other DocMatter-related sites, apps (together with
              DocMatter-branded apps, "<u>Apps</u>"), communications, and other
              services that state that they are offered under these Terms ("
              <u>Services</u>").
            </p>
            <p>
              <b>
                THE SERVICES DO NOT PROVIDE MEDICAL OR PROFESSIONAL SERVICES
                ADVICE.
              </b>{' '}
              The Services are designed to facilitate communication among
              physicians and other health care professionals. Nothing contained
              within the Services is intended to constitute medical advice,
              instruction for medical diagnosis, or instruction for treatment.
              Any information provided through the Services should be considered
              to be hypothetical, and not be considered complete, nor should it
              be relied upon to suggest a course of treatment for any particular
              individual. Information received from the Services should not be
              relied upon for personal, medical, legal, technical, or financial
              decisions. DocMatter does not, through the Services or otherwise,
              directly or indirectly practice medicine, render medical advice,
              or provide medical services. After reviewing the Services and
              before taking any action related to any content on the Services,
              every Member of the Services is expected to make a reasonable
              investigation into, and consider the potential consequences of,
              any such action. If you obtain any information through the
              Services, you use such information at your own risk.
            </p>
            <p>
              The Services serve as a venue for Members to collaborate.
              DocMatter does not control, and makes no representations as to,
              any views, opinions, or other information provided by any Members.
              Any view, opinion, or other information provided by any Member is
              the view, opinion, and information of that Member, and not of
              DocMatter. DocMatter is not and will not be responsible for any
              damage or harm resulting from any use of, or reliance upon, such
              Member views, opinions, or information. By using the Services, you
              agree not to attempt to impose liability on, or seek any legal
              remedy from, DocMatter with respect to any such Member views,
              opinions, or information.
            </p>
            <p>
              The Services may include access to various communities, generally
              related to therapeutic foci, specialty, and/or research topics ("
              <u>Communities</u>"), which Members may join. To the extent you
              elect to be included in any such Communities, you consent to
              having the information you share through the Services shared with
              such Communities, and allowing such Communities to communicate
              directly with you through the Services. You may opt out of
              receiving such communications at any time, but opting out may
              affect your ability to fully utilize the Services.
            </p>
            <h2>2. Changes to These Terms</h2>
            <p>
              We reserve the right, at our sole discretion, to change or modify
              portions of these Terms at any time. If the Terms are materially
              changed, we will post the changes on this page, and will indicate
              at the top of this page the date these Terms were last revised. We
              will also notify you, through the Services user interface, in an
              email notification, or through other reasonable means, of any such
              changes. Any such changes to the Terms will become effective no
              earlier than fourteen (14) days after they are posted, except that
              some changes (such as addressing new functions of the Services or
              changes made for legal reasons) will be effective immediately.
              Your continued use of the Services after the date any such changes
              become effective constitutes your unequivocal acceptance of, and
              agreement to be bound by, the new Terms of Service (including any
              and all changes to the Terms).
            </p>
            <p>
              In addition, when using certain Services, you will be subject to
              any additional terms applicable to such Services that may be
              posted on the Services, including, without limitation, the Privacy
              Policy, accessible{' '}
              <Link to={handleFindPath('PRIVACY')}>here</Link>. All such terms
              are hereby incorporated by reference into these Terms.
            </p>
            <h2>3. Your Account</h2>
            <h3>
              <u>Registration Obligations</u>
            </h3>
            <p>
              You may be required to register with DocMatter in order to access
              and use certain features of the Services. If you choose to
              register for the Services, you agree to provide and maintain true,
              accurate, current, and complete information about yourself as
              prompted by the Services' registration form. Registration data and
              certain other information about you are governed by our Privacy
              Policy.
            </p>
            <h3>
              <u>Account Information</u>
            </h3>
            <p>
              You are responsible for maintaining the confidentiality of your
              password and account, and are fully responsible for any and all
              activities that occur under your password and/or account. You
              agree to: (a) immediately notify us of any unauthorized use of
              your password or account or any other breach of security; and (b)
              ensure that you log out from your account at the end of each
              session when accessing the Services. DocMatter will not be liable
              for any loss or damage arising from your failure to comply with
              this Section.
            </p>
            <p>
              You own the rights to information contained within your personal
              account, and any information or content you upload to the
              Services.
            </p>
            <h3>
              <u>Your Privacy</u>
            </h3>
            <p>
              At DocMatter, we respect the privacy of our Members. For details,
              please see our Privacy Policy. By using the Services, you consent
              to our collection and use of personal data as outlined therein.
            </p>
            <h3>
              <u>Notices</u>
            </h3>
            <p>
              Notices to you may be made through the Services, email, or regular
              mail. You agree to keep your contact information up to date. The
              Services may also provide notices to you of changes to these
              Terms, or other matters, by displaying notices or links to notices
              generally on the Services.
            </p>
            <h3>
              <u>Sharing</u>
            </h3>
            <p>
              You agree that you understand that the information you share
              through the Services may be seen by others. Notwithstanding the
              limitations in specific areas of the Services, you understand that
              sharing information with others means it is no longer
              confidential.
            </p>
            <p>
              We are not obligated to publish any information or content on our
              Services, and can remove any content in our sole discretion, with
              or without notice.
            </p>
            <h2>4. Rights and Limitations</h2>
            <h3>
              <u>Member Content Transmitted Through the Services</u>
            </h3>
            <p>
              With respect to the content or other information you upload
              through the Services, or share with other Members or recipients,
              you represent and warrant that you own any right to, title to, or
              interest in any such content, including, without limitation, all
              copyrights and rights of publicity contained therein, as necessary
              to grant the licenses in this Section. You may choose to make your
              content available outside the Services, but you may not reproduce
              any other Member's content outside of the Services, without
              express written permission from that Member.
            </p>
            <p>
              If there is a Code of Conduct associated with a Community of which
              you are a member, you hereby agree to abide by such Code of
              Conduct.
            </p>
            <p>
              By uploading any content, you hereby grant and will grant
              DocMatter a nonexclusive, worldwide, fully paid-up, royalty-free,
              transferable, sub-licensable (through multiple tiers), perpetual,
              and irrevocable license to use, reproduce, distribute, publish,
              and create derivative works from your content in connection with
              the operation, improvement, or enhancement of the Services and any
              other activities related to the Services, or the marketing and
              promotion thereof, in any form, medium, or technology now known or
              later developed. Notwithstanding the foregoing, you may
              immediately terminate such license granted to DocMatter with
              respect to any particular content by deleting such content from
              the Services, or you may terminate the license with respect to all
              of your content by terminating your account. Upon your termination
              of your account, DocMatter will use commercially reasonable
              efforts to promptly delete or remove your content from the
              Services, and the license granted above with respect to such
              content shall automatically terminate upon the deletion or removal
              of such content.
            </p>
            <p>
              You acknowledge and agree that any questions, comments,
              suggestions, ideas, feedback, or other communications about the
              Services themselves, provided by you to DocMatter, are
              non-confidential and DocMatter will be entitled to the
              unrestricted use, reproduction, distribution, or publication of,
              and creation of derivative works based upon, these communications
              for any purpose, without acknowledging or compensating you.
            </p>
            <p>
              You acknowledge and agree that DocMatter may preserve content and
              may also disclose content, if required to do so by law, or in the
              good faith belief that such preservation or disclosure is
              reasonably necessary to:
            </p>
            <ol type='a'>
              <li>
                comply with legal process, applicable laws, or government
                requests;
              </li>
              <li>enforce these Terms;</li>
              <li>
                respond to claims that any content violates the rights of third
                parties; or
              </li>
              <li>
                protect or enforce the rights, property, or personal safety of
                DocMatter, our Members, and/or the public.
              </li>
            </ol>
            <p>
              You understand that the technical processing and transmission of
              the Services, including your content, may involve transmissions
              over various networks, and changes in content to conform and adapt
              to technical requirements of connecting networks or devices.
            </p>
            <h3>
              <u>Modifications to the Services</u>
            </h3>
            <p>
              DocMatter reserves the right to modify or discontinue, temporarily
              or permanently, the Services (or any part thereof), with or
              without notice. You agree that DocMatter will not be liable to
              you, or to any third party, for any modification, suspension, or
              discontinuance of the Services.
            </p>
            <h3>
              <u>Third Party Content, Sites, and Apps</u>
            </h3>
            <p>
              Under no circumstances will DocMatter be liable in any way for any
              content or materials of any third parties (including Members),
              including, but not limited to, errors or omissions in any third
              party content, or loss or damage of any kind incurred as a result
              of the use of any such third party content.
            </p>
            <p>
              The Services may provide, or third parties may provide, links or
              other access to other sites and resources on the Internet.
              DocMatter has no control over such third party sites and
              resources, and DocMatter is not responsible for, and does not
              endorse or comment about, such sites and/or resources. You further
              acknowledge and agree that DocMatter will not be responsible or
              liable, directly or indirectly, for any damage or loss caused, or
              alleged to be caused, by or in connection with use of, or reliance
              upon, any content, events, goods, or services available on or
              through any such third party site or resource. Any dealings you
              have with third parties found while using the Services are between
              you and such third party or parties, and you agree that DocMatter
              is not liable for any loss suffered through, or claim that you may
              have against, any such third party.
            </p>
            <h3>
              <u>General Practices Regarding Use and Storage</u>
            </h3>
            <p>
              You acknowledge that DocMatter may establish general practices and
              limits concerning use of the Services, including without
              limitation, the maximum period of time that data or other content
              will be retained by the Services and the maximum individual Member
              allotment of storage space on the Services. You agree that
              DocMatter has no responsibility or liability for the deletion or
              failure to store any data or other content maintained or uploaded
              by or to the Services. You further acknowledge that DocMatter
              reserves the right to terminate accounts that are inactive for an
              extended period of time.
            </p>
            <h3>
              <u>Intellectual Property Rights</u>
            </h3>
            <p>
              DocMatter reserves all of our intellectual property rights in the
              Services. Using the Services does not give you any ownership in
              our Services or the content or information made available through
              the Services. Any non-DocMatter intellectual property used in
              connection with the Services is the intellectual property of the
              respective owner.
            </p>
            <h2>5. Rights and Terms for App Use</h2>
            <h3>
              <u>Rights in App Granted by DocMatter</u>
            </h3>
            <p>
              Subject to your compliance with these Terms, DocMatter grants to
              you a limited, revocable, non-exclusive, non-transferable license,
              with no right to sub-license, to download and install a copy of
              our App on a mobile device, computer, or other compatible hardware
              that you own or control, and to run such copy of the App solely
              for your personal use of the Services in accordance with these
              Terms. Except as expressly permitted in these Terms, you may not:
              (i) copy, modify, or create derivative works based on the App;
              (ii) distribute, transfer, sub-license, lease, lend, or rent the
              App to any third party or otherwise use the App on a time sharing
              or service bureau basis; (iii) reverse engineer, decompile, or
              disassemble the App; or (iv) make the functionality of the App
              available to any other person through any means. We hereby reserve
              all rights in and to the App not expressly granted to you under
              these Terms.
            </p>
            <h3>
              <u>Accessing App from App Store</u>
            </h3>
            <p>
              The following terms apply to any App accessed through, or
              downloaded from, any app store or distribution platform (like the
              Apple App Store or Google Play) where the App may now, or in the
              future, be made available (each an "<u>App Provider</u>"). You
              acknowledge and agree that:
            </p>
            <ul>
              <li>
                These Terms are an agreement between you and DocMatter, and not
                with the App Provider, and DocMatter (not the App Provider) is
                solely responsible for the App;
              </li>
              <li>
                The App Provider has no obligation to furnish any maintenance
                and support services with respect to the App;
              </li>
              <li>
                In the event of any failure of the App to conform to any
                applicable warranty, the App Provider will have no warranty
                obligation whatsoever with respect to the App. Any other claims,
                losses, liabilities, damages, costs, or expenses attributable to
                any failure to conform to any warranty will be the sole
                responsibility of DocMatter;
              </li>
              <li>
                The App Provider is not responsible for addressing any claims
                you have, or any claims of any third party, relating to the App
                or your possession and/or use of the App, including, but not
                limited to: (i) product liability claims; (ii) any claim that
                the App fails to conform to any applicable legal or regulatory
                requirement; and (iii) claims arising under consumer protection
                or similar legislation;
              </li>
              <li>
                In the event of any third party claim that the App or your
                possession and/or use of the App infringes that third party's
                intellectual property rights, DocMatter will be solely
                responsible for the investigation, defense, settlement, and
                discharge of any such intellectual property infringement claim
                to the extent required by these Terms;
              </li>
              <li>
                The App Provider, and its subsidiaries, are third party
                beneficiaries of these Terms as related to your license to the
                App, and upon your acceptance of these Terms, the App Provider
                will have the right (and will be deemed to have accepted the
                right) to enforce these Terms as related to your license of the
                App against you, as a third party beneficiary thereof;
              </li>
              <li>
                You represent and warrant that: (i) you are not located in a
                country that is subject to a U.S. Government embargo, or that
                has been designated by the U.S. Government as a
                terrorist-supporting country; and (ii) you are not listed on any
                U.S. Government list of prohibited or restricted parties; and
              </li>
              <li>
                You must also comply with all applicable third party terms of
                service when using the App.
              </li>
            </ul>
            <h2>6. Disclaimer and Limitations of Liability</h2>{' '}
            <h3>
              <u>Disclaimer of Warranties</u>
            </h3>
            <p>
              YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE
              PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. DOCMATTER
              EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS,
              IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              TITLE, AND NON-INFRINGEMENT. DOCMATTER MAKES NO WARRANTY:(i)THAT
              THE SERVICES WILL MEET YOUR REQUIREMENTS;(ii)THAT THE SERVICES
              WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE;(iii)THAT THE
              RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES WILL BE
              ACCURATE OR RELIABLE; OR(iv)WITH RESPECT TO ANY PRODUCTS,
              SERVICES, INFORMATION, OR OTHER MATERIAL PROMOTED, OFFERED, OR
              OTHERWISE DISCUSSED THROUGH THE SERVICES (AND ANY WARRANTIES
              RELATING THERETO SHALL BE LIMITED TO THOSE OFFERED, IF ANY, BY THE
              THIRD PARTY MANUFACTURER OR THIRD PARTY PROVIDER OF SUCH PRODUCTS,
              SERVICES, INFORMATION, OR OTHER MATERIAL).
            </p>
            <h3>
              <u>Limitation of Liability</u>
            </h3>
            <p>YOUR USE OF THE SERVICES IS AT YOUR OWN RISK.</p>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, YOU EXPRESSLY
              UNDERSTAND AND AGREE THAT DOCMATTER WILL NOT BE LIABLE FOR ANY
              DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY
              DAMAGES, OR DAMAGES FOR LOSS OF PROFITS, INCLUDING BUT NOT LIMITED
              TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA, OR OTHER INTANGIBLE
              LOSSES (EVEN IF DOCMATTER HAS BEEN ADVISED OF THE POSSIBILITY OF
              SUCH DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
              LIABILITY, OR OTHERWISE, RESULTING FROM:
            </p>
            <ol type='I'>
              <li>THE USE OR THE INABILITY TO USE THE SERVICES;</li>
              <li>
                THE COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES
                RESULTING FROM ANY GOODS, DATA, INFORMATION, OR SERVICES
                RECEIVED FROM ANY THIRD PARTY THROUGH OR FROM THE SERVICES;
              </li>
              <li>
                UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR
                DATA;
              </li>
              <li>
                STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICES; OR
              </li>
              <li>ANY OTHER MATTER RELATING TO THE SERVICES. </li>
            </ol>
            <p>
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN
              WARRANTIES, OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR
              INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE
              LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU.
            </p>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU AGREE TO LIMIT
              DOCMATTER'S LIABILITY TO YOU ARISING OUT OF OR IN CONNECTION WITH
              THESE TERMS OR FROM THE USE OF OR INABILITY TO USE OR ACCESS THE
              SERVICES TO THE TOTAL AMOUNT YOU'VE PAID TO DOCMATTER TO USE THE
              SERVICES.
            </p>
            <p>
              THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE
              FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN YOU AND
              DOCMATTER.
            </p>
            <p>
              IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE YOUR RIGHTS WITH
              RESPECT TO CALIFORNIA CIVIL CODE SECTION 1542, WHICH SAYS "A
              GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR
              RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER
              FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY
              HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT
              WITH THE DEBTOROR RELEASED PARTY."
            </p>
            <p>
              <b>
                IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICES, OR
                WITH THESE TERMS OF SERVICE, YOUR SOLE AND EXCLUSIVE REMEDY IS
                TO DISCONTINUE USE OF THE SERVICES.
              </b>
            </p>
            <h3>
              <u>Member Disputes</u>
            </h3>
            <p>
              You agree that you are solely responsible for your interactions
              with any other Member or third party in connection with the
              Services, and DocMatter will have no liability or responsibility
              with respect thereto. DocMatter reserves the right, but has no
              obligation, to become involved in any way with disputes between
              you and any other Member of the Services. You will fully cooperate
              with DocMatter to investigate any suspected unlawful, fraudulent,
              or improper activity.
            </p>
            <p>
              If you have a dispute with one or more other Members, you release
              us (and our officers, directors, agents, subsidiaries, joint
              ventures, customers, partners, and employees) from claims,
              demands, and damages (actual and consequential) of every kind and
              nature, known and unknown, arising out of or in any way connected
              with such disputes.
            </p>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, YOU FURTHER
              AGREE THAT NO OTHER MEMBER WILL BE LIABLE TO YOU FOR ANY DIRECT,
              INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY
              DAMAGES, OR DAMAGES FOR LOSS OF PROFITS, INCLUDING BUT NOT LIMITED
              TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA, OR OTHER INTANGIBLE
              LOSSES, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, OR STRICT
              LIABILITY IN CONNECTION WITH YOUR USE OF THE SERVICES, INCLUDING
              YOUR RELIANCE UPON ANY MEMBER CONTENT.
            </p>
            <p>
              The Services are intended to be a safe space for knowledge
              sharing, and transfer of general, hypothetical ideas. If a Member
              incorporates anything from the Services in any action taken by
              that Member, it is at that Member's own risk.
            </p>
            <h3>
              <u>Indemnity</u>
            </h3>
            <p>
              You agree to indemnify and hold DocMatter and its officers,
              employees, directors, customers, partners, and agents harmless
              from any and all losses, damages, expenses (including reasonable
              attorneys' fees), claims, actions of any kind, and/or injury
              (including death), arising out of or relating to your use of the
              Services, your violation of these Terms, or your violation of any
              rights of another.
            </p>
            <h2>7. Termination</h2>
            <p>
              You agree that DocMatter, in its sole discretion, may suspend or
              terminate your account (or any part thereof) or use of the
              Services, and remove and discard any of your content within the
              Services, for any reason, including, without limitation, for lack
              of use or if DocMatter believes that you have violated or acted
              inconsistently with the letter or spirit of these Terms. Any
              suspected fraudulent, abusive, or illegal activity that may be
              grounds for termination of your use of the Services may be
              referred to appropriate law enforcement authorities. DocMatter may
              also, in its sole discretion and at any time, discontinue
              providing the Services, or any part thereof, with or without
              notice. You agree that any termination of your access to the
              Services under any provision of these Terms may be effected
              without prior notice, and acknowledge and agree that DocMatter may
              immediately deactivate or delete your account and all related
              information and/or bar any further access to such information or
              the Services. Further, you agree that DocMatter will not be liable
              to you or any third party for any termination of your access to
              the Services.
            </p>
            <h2>8. Governing Law</h2>
            <p>
              These Terms will be governed by the laws of the State of
              California without regard to its conflict of law provisions. With
              respect to any disputes, claims, or controversies arising out of
              or relating to these Terms or the breach, termination,
              enforcement, interpretation, or validity thereof, or the use of
              the Services (collectively, "<u>Disputes</u>") not subject to
              arbitration, as set forth below, you and DocMatter agree to submit
              to the personal and exclusive jurisdiction of the state and
              federal courts located within San Francisco County, California,
              and you and DocMatter each waive any objection to jurisdiction and
              venue in such courts.
            </p>
            <h2>9. Dispute Resolution</h2>
            <h3>
              <u>Mandatory Arbitration of Disputes</u>
            </h3>
            <p>
              We each agree that all Disputes will be resolved solely by
              binding, individual arbitration, and not in a class,
              representative, or consolidated action or proceeding. You and
              DocMatter agree that the U.S. Federal Arbitration Act governs the
              interpretation and enforcement of these Terms, and that you and
              DocMatter are each waiving the right to a trial by jury and/or to
              participate in a class action. This arbitration provision shall
              survive termination of these Terms.
            </p>
            <h3>
              <u>Exceptions and Opting Out</u>
            </h3>
            <p>
              As limited exceptions to "Mandatory Arbitration of Disputes,"
              above: (i) you may seek to resolve a Dispute in small claims court
              if it qualifies; and (ii) we each retain the right to seek
              injunctive or other equitable relief from a court to prevent (or
              enjoin) the infringement or misappropriation of our respective
              intellectual property rights. In addition, you will retain the
              right to opt out of arbitration entirely and litigate any Dispute
              if you provide us with written notice of your desire to do so by
              email at{' '}
              <a href='mailto:legal@docmatter.com'>legal@docmatter.com</a>,
              within thirty (30) days following the date you first agree to
              these Terms.
            </p>
            <h3>
              <u>Conducting Arbitration and Arbitration Rules</u>
            </h3>
            <p>
              The arbitration will be conducted by the American Arbitration
              Association ("<u>AAA</u>") under its Consumer Arbitration Rules ("
              <u>AAA Rules</u>") then in effect, except as modified by these
              Terms. The AAA Rules are available at www.adr.org, or by calling
              1-800-778-7879. A party who wishes to start arbitration must
              submit a written Demand for Arbitration to AAA, and give notice to
              the other party as specified in the AAA Rules. The AAA provides a
              form Demand for Arbitration at www.adr.org.
            </p>
            <p>
              If your claim is for U.S. $10,000.00, or less, you may choose
              whether the arbitration will be conducted solely on the basis of
              documents submitted to the arbitrator, through a telephonic or
              video-conference hearing, or by an in-person hearing as
              established by the AAA Rules. If your claim exceeds U.S.
              $10,000.00, the right to a hearing will be determined by the AAA
              Rules. Any arbitration hearings will take place in the county (or
              parish) where you live, unless we both agree to a different
              location. You and DocMatter both agree that the arbitrator shall
              have exclusive authority to decide all issues relating to the
              interpretation, applicability, enforceability, and scope of this
              arbitration agreement.
            </p>
            <h3>
              <u>Arbitration Costs</u>
            </h3>
            <p>
              Payment of all filing, administration, and arbitrator fees will be
              governed by the AAA Rules.
            </p>
            <h3>
              <u>Class Action Waiver</u>
            </h3>
            <p>
              YOU AND DOCMATTER AGREE THAT EACH MAY BRING CLAIMS AGAINST THE
              OTHER ONLY IN YOUR OR OUR INDIVIDUAL CAPACITY, AND NOT AS A
              PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
              PROCEEDING. Further, if the parties' dispute is resolved through
              arbitration, the arbitrator may not consolidate another person's
              claims with your claims, and may not otherwise preside over any
              form of a representative or class proceeding. If this specific
              provision is found to be unenforceable, then the entirety of this
              Section, "Dispute Resolution," shall be null and void.
            </p>
            <h3>
              <u>Effect of Changes on Arbitration</u>
            </h3>
            <p>
              Notwithstanding the provisions of Section 2, "Changes to These
              Terms," above, if we change any of the terms of this Section,
              "Dispute Resolution," after the date you first accepted these
              Terms (or accepted any subsequent changes to these Terms), you may
              reject any such change by sending us written notice (including by
              email to{' '}
              <a href='mailto:legal@docmatter.com'>legal@docmatter.com</a>,
              within thirty (30) days of the date such change became effective,
              as indicated in the "Last Revision" date above, or in the date of
              our email or other communication to you notifying you of such
              change. By rejecting any change, you are agreeing that you will
              arbitrate any Dispute between you and DocMatter in accordance with
              the terms of this Section, "Dispute Resolution," as of the date
              you first accepted these Terms (or accepted any subsequent changes
              to these Terms).
            </p>
            <h2>10. General Terms</h2>
            <p>
              The failure of DocMatter to exercise or enforce any right or
              provision of these Terms will not constitute a waiver of such
              right or provision. If any provision of these Terms is found by a
              court of competent jurisdiction to be invalid, the parties
              nevertheless agree that the court should endeavor to give effect
              to the parties' intentions as reflected in the provision, and the
              other provisions of these Terms shall remain in full force and
              effect. You agree that regardless of any statute or law to the
              contrary, any claim or cause of action arising out of or related
              to use of the Services or these Terms must be filed within one (1)
              year after such claim or cause of action arises, or be forever
              barred. A printed version of this agreement and of any notice
              given in electronic form will be admissible in judicial or
              administrative proceedings based upon or relating to this
              agreement to the same extent and subject to the same conditions as
              any other business documents and records originally generated and
              maintained in printed form. You may not assign these Terms without
              the prior written consent of DocMatter, but DocMatter may assign
              or transfer these Terms, in whole or in part, without restriction.
              The section titles in these Terms are for convenience only and
              have no legal or contractual effect.
            </p>
            <p>
              Software available in connection with the Services and the
              transmission of applicable data, if any, is subject to United
              States export controls. The Services may not be downloaded or
              otherwise exported or re-exported in violation of U.S. export
              laws. Downloading or using the Services is at your sole risk.
              Recognizing the global nature of the Internet, you agree to comply
              with all local rules and laws regarding your use of the Services,
              including as it concerns online conduct and acceptable content.
            </p>
            <p>
              With the exception of any of the provisions in Section 9, "Class
              Action Waiver," of these Terms, if an arbitrator or court of
              competent jurisdiction decides that any part of these Terms is
              invalid or unenforceable, the other parts of these Terms will
              still apply.
            </p>
            <h2>11. Content, Software, and Trademarks</h2>
            <p>
              You acknowledge that DocMatter may not always pre-screen content,
              but that we will have the right (but not the obligation) in our
              sole discretion to refuse or remove any content that is available
              via the Services. Without limiting the foregoing, DocMatter will
              have the right to remove any content that violates these Terms, or
              which is deemed by DocMatter, in its sole discretion, to be
              otherwise objectionable. As a courtesy to our Members, we may edit
              grammar, spelling, and/or formatting of content, as we deem
              necessary to clarify or improve the readability of such content
              for other Members. If we edit content, we may notify the author of
              said content, and we will make every reasonable effort to keep the
              subject matter or inferred intent of the content as it was in the
              original format (but, by doing so, we do not assume any
              obligation, responsibility, or liability resulting from the
              content, nor do we adopt any Member’s words as our own). You agree
              that you must evaluate, and bear all risks associated with, the
              use of any content, including any reliance on the accuracy,
              completeness, or usefulness of such content.
            </p>
            <p>
              You acknowledge and agree that the Services may contain content or
              features that are protected by copyright, patent, trademark, trade
              secret, and/or other proprietary rights, or laws. Except as
              expressly authorized by DocMatter, you agree not to modify, copy,
              frame, scrape, rent, lease, loan, sell, distribute, or create
              derivative works based upon the Services, in whole or in part,
              except that the foregoing does not apply to your own content that
              you legally upload to the Services. In connection with your use of
              the Services, you will not engage in or use any data mining,
              robots, scraping, or similar data gathering or extraction methods.
              Any use of the Services other than as specifically authorized
              herein is strictly prohibited. The technology and software
              underlying the Services, or distributed in connection therewith,
              are the property of DocMatter. You agree not to copy, modify,
              create a derivative work of, reverse engineer, reverse assemble or
              otherwise attempt to discover any source code, or sell, assign,
              sub-license, or otherwise transfer any right in the Services or
              DocMatter's technology or software. Any rights not expressly
              granted herein are reserved by DocMatter.
            </p>
            <p>
              The DocMatter name and logos are trademarks and service marks of
              DocMatter. Other than DocMatter, product and service names and
              logos used and displayed via the Services may be trademarks or
              service marks of their respective owners, who may or may not
              endorse or be affiliated with or connected to DocMatter. Nothing
              in these Terms or the Services should be construed as granting, by
              implication or otherwise, any license or right to use any of
              DocMatter's trademarks or service marks, without our prior written
              permission in each instance. All goodwill generated from the use
              of DocMatter's trademarks or service marks will inure to our
              exclusive benefit.
            </p>
            <h2>12. Member Conduct</h2>
            <p>
              You are solely responsible for all code, video, images,
              information, data, text, software, music, sound, photographs,
              graphics, messages, or other content that you upload or email or
              otherwise submit or use via the Services. DocMatter reserves the
              right to investigate and take appropriate legal action against
              anyone who, in DocMatter's sole discretion, violates this
              provision, including without limitation, removing the offending
              content from the Services, suspending or terminating the account
              of such violators, and/or reporting the violator to the law
              enforcement authorities. The following are examples of the kind of
              content and/or use that is illegal or prohibited by DocMatter, and
              you agree to not use the Services to:
            </p>
            <ol type='a'>
              <li>
                email or otherwise upload any content that:
                <ol type='i'>
                  <li>
                    infringes any intellectual property or other proprietary
                    rights of any party;
                  </li>
                  <li>
                    you do not have a right to upload under any law or under
                    contractual or fiduciary relationships;
                  </li>
                  <li>
                    contains software viruses or any other computer code, files,
                    or programs designed to interrupt, destroy, or limit the
                    functionality of any computer software or hardware or
                    telecommunications equipment;
                  </li>
                  <li>
                    poses or creates a privacy or security risk to any person;
                  </li>
                  <li>
                    constitutes unsolicited or unauthorized advertising,
                    promotional materials, commercial activities and/or sales,
                    or any other form of solicitation;
                  </li>
                  <li>
                    is unlawful, harmful, threatening, abusive, harassing,
                    tortious, excessively violent, defamatory, vulgar, obscene,
                    pornographic, libelous, invasive of another's privacy,
                    hateful, and/or otherwise objectionable; or
                  </li>
                  <li>
                    in the sole judgment of DocMatter, is objectionable, or
                    which restricts or inhibits any other person from using or
                    enjoying the Services, or which may expose DocMatter or its
                    Members to any type of harm or liability;
                  </li>
                </ol>
              </li>
              <li>
                interfere with or disrupt the Services, or servers or networks
                connected to the Services, or disobey any requirements,
                procedures, policies, or regulations of networks connected to
                the Services;
              </li>{' '}
              <li>
                violate any applicable local, state, national, or international
                law, or any regulations having the force of law;
              </li>
              <li>
                impersonate any person or entity, or falsely state or otherwise
                misrepresent your affiliation with a person or entity;
              </li>
              <li>
                solicit personal information from anyone under the age of 18;
              </li>
              <li>
                harvest or collect email addresses or other contact information
                of other Members from the Services by electronic or other means
                for the purposes of sending unsolicited emails or other
                unsolicited communications;
              </li>
              <li>
                advertise or offer to sell or buy any goods or services for any
                business purpose;
              </li>
              <li>
                further or promote any criminal activity or enterprise or
                provide instructional information about illegal activities;
              </li>
              <li>
                obtain or attempt to access, or otherwise obtain, any materials
                or information through any means not intentionally made
                available or provided for through the Services; or
              </li>
              <li>
                discuss or solicit pricing information on any medical product.
              </li>
            </ol>
            <p>
              Unless otherwise expressly authorized herein or in the Services,
              you agree not to display, distribute, license, perform, publish,
              reproduce, duplicate, copy, create derivative works from, modify,
              sell, resell, exploit, transfer, or upload, for any purpose, any
              portion of the Services, use of the Services, or access to the
              Services.
            </p>
            <p>
              You agree to comply with all applicable privacy laws and
              regulations (including, but not limited to the EU General Data
              Protection Regulation ("<u>GDPR</u>") and the Health Insurance
              Portability and Accountability Act of 1996, and the regulations
              promulgated thereunder (including 45 Code of Federal Regulations
              Section 160) ("
              <u>HIPAA</u>")), and to obtain any necessary consents or
              authorizations before uploading any personal data about any other
              person. You also agree to comply with all applicable laws and
              regulations that relate to the reporting and investigation of any
              adverse event, injury, toxicity or sensitivity reaction, or any
              unexpected incidence associated with any products, treatments, or
              therapies.
            </p>
            <p>
              Unless you have obtained valid and verifiable permission from your
              patient and any other relevant individual, you agree not to
              upload, post, publish, or display any protected health information
              (as such term is defined by HIPAA, "<u>PHI</u>"), personally
              identifiable information, or other patient information to or via
              the Services (including any images or other content containing any
              such information). DocMatter does not assume any, and hereby
              disclaims all, liability arising from the uploading to, or use of
              any such information on or via, the Services. DocMatter is not a
              "covered entity," or "business associate," under HIPAA. As such,
              it is your responsibility, if you are a covered entity, to ensure
              that you comply with the HIPAA requirements with respect to PHI.
              If you upload content containing PHI, DocMatter may (but has no
              obligation to) notify you and delete the PHI-containing content.
              DocMatter in no way accepts the responsibility to keep PHI
              confidential, and neither becomes a "covered entity," nor a
              "business associate," by performing this courtesy.
            </p>
            <p>
              You agree to accurately disclose all financial ties and conflicts
              of interest.
            </p>
            <h2>13. Complaints Regarding Content</h2>
            <p>
              DocMatter respects the intellectual property of others, and we ask
              our Members to do the same. If you believe that your work has been
              copied in a way that constitutes copyright infringement, or that
              your intellectual property rights have been otherwise violated,
              you should notify DocMatter of your infringement claim in
              accordance with the procedure set forth below.
            </p>
            <p>
              DocMatter will process and investigate notices of alleged
              infringement, and will take appropriate action under the Digital
              Millennium Copyright Act ("DMCA"), and/or any other applicable
              intellectual property laws, with respect to any alleged or actual
              infringement. A notification of claimed copyright infringement
              should be emailed to DocMatter's Copyright Agent at{' '}
              <a href='mailto:legal@docmatter.com'>legal@docmatter.com</a>{' '}
              (Subject line: "DMCA Takedown Request"). You may also contact us
              by mail:
            </p>
            <p style={{ marginLeft: '20px' }}>
              DocMatter Copyright Agent
              <br />
              460 Brannan Street #77452
              <br />
              San Francisco, CA 94107 <br />
              USA
              <br />
            </p>
            <p>
              To be effective, the notification must be in writing and contain
              the following information:
            </p>
            <ul>
              <li>
                an electronic or physical signature of the person authorized to
                act on behalf of the owner of the copyright or other
                intellectual property interest;
              </li>
              <li>
                a description of the copyrighted work or other intellectual
                property that you claim has been infringed;
              </li>
              <li>
                a description of where the material that you claim is infringing
                is located on the Services, with enough detail that we may find
                it on the Services;
              </li>
              <li>your address, telephone number, and email address;</li>
              <li>
                a statement that you have a good faith belief that the disputed
                use is not authorized by the copyright or intellectual property
                owner, the owner's agent, or the law;
              </li>
              <li>
                a statement, made under penalty of perjury, that the above
                information, included within your Notice, is accurate, and that
                you are the copyright or intellectual property owner, or are
                authorized to act on the copyright or intellectual property
                owner's behalf.
              </li>
            </ul>
            <p>
              If you believe that your content, which has been removed (or to
              which access has been disabled), is not infringing, or that you
              have the authorization from the copyright owner, the copyright
              owner's agent, or pursuant to the law, to use the content, you may
              send a written counter-notice containing the following information
              to the Copyright Agent:
            </p>
            <ul>
              <li>your physical or electronic signature;</li>
              <li>
                identification of the content, which has been removed or to
                which access has been disabled, and the location at which the
                content appeared before it was removed or disabled;
              </li>
              <li>
                a statement that you have a good faith belief that the content
                was removed or disabled as a result of mistake or a
                misidentification of the content; and
              </li>
              <li>
                your name, address, telephone number, and email address, a
                statement that you consent to the jurisdiction of the federal
                court located within Northern District of California and a
                statement that you will accept service of process from the
                person who provided notification of the alleged infringement.
              </li>
            </ul>
            <p>
              If a counter-notice is received by the Copyright Agent, DocMatter
              will send a copy of the counter-notice to the original complaining
              party, informing that party that it may replace the removed
              content or cease disabling it within ten (10) business days.
              Unless the copyright owner files an action seeking a court order
              against the content provider, the removed content may be replaced,
              or access to it restored, in 10 to 14 business days or more after
              receipt of the counter-notice, at our sole discretion.
            </p>
            <p>
              In accordance with the DMCA and other applicable law, DocMatter
              has adopted a policy of terminating, in appropriate circumstances
              and at DocMatter's sole discretion, Members who are deemed to be
              repeat infringers. DocMatter may also at its sole discretion limit
              access to the Services and/or terminate the memberships of any
              Members who infringes any intellectual property rights of others,
              whether or not there is any repeat infringement.
            </p>
            <h2>14.Questions? Concerns? Suggestions?</h2>
            <p>
              Please contact us at{' '}
              <a href='mailto:legal@docmatter.com'>legal@docmatter.com</a> to
              report any violations of these Terms of Service or to pose any
              questions regarding these Terms or the Services.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
