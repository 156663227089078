import React from 'react';
import gsap, { Circ } from 'gsap';
import ReactPlayer from 'react-player';

import Image from '../../../partials/image/components/Image';
import PlayButton from '../../../assets/icons/play-overlay.svg';
import { HomeCopies } from '../../../constants/Copies';
import { MediaQueries } from '../../../constants/MediaQueries';
import styles from '../styles/home-video-section.module.scss';

interface Props {
  windowDimensions: { width: number; height: number };
  isMobileTablet: boolean;
  isTop: boolean;
  handleOpenVideoModal: () => void;
  isVideoPlaying: boolean;
  handlePlayVideo: () => void;
}

export default class HomeVideoSection extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
    this.rightAnimation = gsap.timeline({});
    this.leftAnimation = gsap.timeline({});
  }

  private rightAnimation;
  private rightVeil = React.createRef<HTMLDivElement>();
  private leftAnimation;
  private playVideoButton;

  componentDidMount() {
    // keyboard accessibility for 'click to play'
    this.playVideoButton = document.getElementById('playVideoButton');
    this.playVideoButton.addEventListener('keyup', this.handleKeyUp);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isTop && !this.props.isTop) {
      this.rightAnimation.to(this.rightVeil.current, 1.2, {
        left: '-100%',
        ease: Circ.easeInOut,
        delay: 0.8,
      });

      this.leftAnimation.to('.left', {
        duration: 1,
        x: 0,
        ease: 'power1.inOut',
        delay: 0.1,
        stagger: {
          amount: 0.5,
        },
      });
    }
  }

  componentWillUnmount() {
    this.playVideoButton.removeEventListener('keyup', this.handleKeyUp);
  }

  handleKeyUp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.key === 'Enter' && this.props.handleOpenVideoModal();
  };

  renderLeft = () => {
    const content = HomeCopies.videoSection;
    return (
      <div className={styles.home_video_section__left__inner}>
        <div
          className={`${styles.home_video_section__left__inner__content} left`}
        >
          <h2 className={styles.home_video_section__left__inner__title}>
            {content.title}
          </h2>
          <div
            className={styles.home_video_section__left__inner__body}
            dangerouslySetInnerHTML={{ __html: content.body }}
          />
        </div>
      </div>
    );
  };

  renderRight = () => {
    const {
      windowDimensions,
      isMobileTablet,
      isVideoPlaying,
      handlePlayVideo,
      handleOpenVideoModal,
    } = this.props;
    return (
      <div className={styles.home_video_section__right}>
        <div
          className={styles.home_video_section__right__inner}
          style={{
            height:
              windowDimensions.width < MediaQueries.M_TABLET ||
              (windowDimensions.width < MediaQueries.S_DESKTOP &&
                windowDimensions.width < windowDimensions.height)
                ? windowDimensions.width * 0.75
                : '100%',
          }}
        >
          <Image
            src={HomeCopies.videoSection.placeholderImg}
            alt={HomeCopies.videoSection.placeholderImgAlt}
            background
            width={'100%'}
            height={'100%'}
            className={styles.home_video_section__right__inner__placeholder}
          />

          {/* for mobile/tablet, do not render self playing video */}
          {!isMobileTablet && (
            <ReactPlayer
              url={HomeCopies.videoSection.shortVideoLoopUrl}
              playing
              muted
              loop
              width={'100%'}
              height={'100%'}
              onContextMenu={(e) => e.preventDefault()} // Disable right click
              config={{ file: { attributes: { controlsList: 'nodownload' } } }} // Disable download button
              style={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            />
          )}
        </div>

        {/* play button */}
        {!isVideoPlaying && (
          <div
            id={'playVideoButton'}
            className={styles.home_video_section__right__play_button}
            onClick={
              windowDimensions.width < MediaQueries.S_TABLET && isMobileTablet
                ? handlePlayVideo
                : handleOpenVideoModal
            }
            tabIndex={0}
          >
            <Image
              src={PlayButton}
              alt={'Open modal to play video'}
              width={'100%'}
              height={'100%'}
            />
            <p className={styles.home_video_section__right__play_button__text}>
              Click to Play Video
            </p>
          </div>
        )}

        {/* veil */}
        <div
          ref={this.rightVeil}
          className={styles.home_video_section__right__veil}
        />
      </div>
    );
  };

  render() {
    return (
      <div className={styles.home_video_section}>
        <div className={styles.home_video_section__left}>
          {this.renderLeft()}
        </div>
        {this.renderRight()}
      </div>
    );
  }
}
