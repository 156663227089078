import React from 'react';
import { TimelineMax, Circ } from 'gsap/all';

import styles from '../styles/modal.module.scss';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  handleClose: () => void;
  video?: boolean;
}

export default class Modal extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
    this.openAnimation = new TimelineMax({ paused: true });
  }

  private modal = React.createRef<HTMLDivElement>();
  private modalContent = React.createRef<HTMLDivElement>();
  private openAnimation;
  private closeButton;

  componentDidMount() {
    this.openAnimation
      .to(this.modal.current, 0.6, { autoAlpha: 1, ease: Circ.easeInOut })
      .to(this.modalContent.current, 0.6, {
        // y: -50,
        autoAlpha: 1,
        ease: Circ.easeInOut,
      })
      .reversed(true)
      .paused(false);

    // keyboard accessibility for close button
    this.closeButton = document.getElementById('closeButton');
    this.closeButton.addEventListener('keyup', this.handleKeyUp);
  }

  componentDidUpdate() {
    this.openAnimation.reversed(!this.props.isOpen);
  }

  handleKeyUp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.key === 'Enter' && this.props.handleClose();
  };

  render() {
    const { children, handleClose, video } = this.props;
    return (
      <div className={styles.modal} ref={this.modal} onClick={handleClose}>
        <div
          className={`${styles.modal__content}${
            video ? ` ${styles.modal__content__video}` : ''
          }`}
          ref={this.modalContent}
          onClick={(event) => event.stopPropagation()}
        >
          <div
            id={'closeButton'}
            className={styles.modal__exit}
            onClick={handleClose}
            tabIndex={0}
          />
          {children}
        </div>
      </div>
    );
  }
}
