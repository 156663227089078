import React from 'react';

import styles from '../styles/entity-loader.module.scss';

const EntityLoader = (props: { isLoading: boolean }) => {
  const { isLoading } = props;

  return (
    <div
      className={`${styles.entity__loader__container}${
        !isLoading ? ` ${styles.animation_out}` : ''
      }`}
    >
      <div className={styles.entity__loader}>
        <div className={styles.entity__loader__svg__bg} />
        <div className={styles.entity__loader__svg__container}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 100 100'
            fill='transparent'
            className={styles.entity__loader__svg}
          >
            <circle
              stroke='#ffffff'
              strokeWidth='8'
              strokeLinecap='round'
              r={46}
              cx={50}
              cy={50}
              className={styles.entity__loader__circle}
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default EntityLoader;
