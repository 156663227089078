import React from 'react';
import gsap, { Circ } from 'gsap';

import Image from '../../../partials/image/components/Image';
import ForegroundSvgSolid from '../../../assets/images/home-hero--foreground-solid.png';
import ForegroundSvgGradient from '../../../assets/images/home-hero--foreground-gradient.png';
import { HomeCopies } from '../../../constants/Copies';
import styles from '../styles/home-hero.module.scss';
import Button from '../../../partials/button/components/Button';

interface Quote {
  quote: {
    white: string;
    gold: string;
  };
  location: string;
  bgImg: string;
}

interface Props {
  windowDimensions: { width: number; height: number };
  isMobileTablet: boolean;
}

interface State {
  shuffledQuotes: Quote[];
}

export default class HomeHero extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      shuffledQuotes: this.shuffleArray(HomeCopies.heroQuotes),
    };
    this.bgAnimation = gsap.timeline({ repeat: -1 });
    this.textAnimation = gsap.timeline({ repeat: -1 });
  }

  private bgAnimation;
  private textAnimation;

  componentDidMount() {
    const fadeInAnimation = {
      autoAlpha: 1,
      ease: 'power1.inOut',
    };

    const fadeOutAnimation = {
      autoAlpha: 0,
      ease: 'power1.inOut',
    };

    const veilInAnimation = {
      left: '0%',
      ease: Circ.easeInOut,
    };

    const veilOutAnimation = {
      left: '100%',
      ease: Circ.easeInOut,
    };

    this.state.shuffledQuotes.map((quote, i) => {
      this.textAnimation
        .to(`.textInner${i}`, 0.5, fadeInAnimation)
        .to(`.textVeil${i}`, 0.5, {
          ...veilInAnimation,
          delay: 2.5,
        })
        .to(`.textInner${i}`, 0, fadeOutAnimation)
        .to(`.textVeil${i}`, 0.5, veilOutAnimation);
      this.bgAnimation
        .to(`.bgImg${i}`, 1, fadeInAnimation)
        .to(`.bgImg${i}`, 1, { ...fadeOutAnimation, delay: 2 });
      return null;
    });
  }

  shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  renderBgImages = () =>
    this.state.shuffledQuotes.map((quote, i) => (
      <Image
        key={i}
        className={`${styles.home_hero__bg} bgImg${i}`}
        background
        src={quote.bgImg}
        alt={`background image ${i + 1}`}
        width={'100%'}
        height={'120%'}
      />
    ));

  renderQuotes = () =>
    this.state.shuffledQuotes.map((quote, i) => (
      <div key={i} className={styles.home_hero__quote}>
        <div className={`${styles.home_hero__quote__veil} textVeil${i}`} />
        <div className={`${styles.home_hero__quote__inner} textInner${i}`}>
          <p className={styles.home_hero__quote__text}>
            {quote.quote.white}
            <span className={styles.home_hero__quote__text__gold}>
              {quote.quote.gold}
            </span>
          </p>
          <p className={styles.home_hero__quote__location}>{quote.location}</p>
        </div>
      </div>
    ));

  render() {
    const { isMobileTablet } = this.props;
    return (
      <div
        className={`${styles.home_hero}${
          isMobileTablet ? '' : ` ${styles.desktop}`
        }`}
      >
        <div className={styles.home_hero__bg__container}>
          {/* background */}
          {this.renderBgImages()}
        </div>

        {/* foreground */}
        <Image
          className={`${styles.home_hero__foreground}${
            isMobileTablet ? '' : ` ${styles.desktop}`
          }`}
          background
          src={ForegroundSvgSolid}
          alt={'foreground'}
          width={'460px'}
          height={'100%'}
        />
        <Image
          className={`${styles.home_hero__foreground} ${styles.top}${
            isMobileTablet ? '' : ` ${styles.desktop}`
          }`}
          background
          src={ForegroundSvgGradient}
          alt={'foreground'}
          width={'390px'}
          height={'100%'}
        />

        {/* quotes */}
        <div className={styles.home_hero__quotes}>{this.renderQuotes()}</div>

        {/* static */}
        <div className={styles.home_hero__static}>
          <h2 className={styles.home_hero__static__title}>
            {HomeCopies.heroStatic.title}
          </h2>
          <div
            className={styles.home_hero__static__body}
            dangerouslySetInnerHTML={{ __html: HomeCopies.heroStatic.body }}
          />
          <div className={styles.home_hero__static__button__container}>
            <Button
              text={HomeCopies.heroStatic.buttonLink.text}
              path={HomeCopies.heroStatic.buttonLink.link}
              buttonClassName={styles.home_hero__static__button}
              textClassName={styles.home_hero__static__button_text}
            />
          </div>
        </div>

        {/* scroll to continue */}
        {/* <div className={styles.home_hero__scroll}>
          <p className={styles.home_hero__scroll__text}>
            {'scroll to continue'}
          </p>
          <div className={styles.home_hero__scroll__line} />
        </div> */}
      </div>
    );
  }
}
