import React from 'react';

import styles from '../styles/error-message.module.scss';
import Link from '../../../utils/Link';

interface Props {
  data: {
    isShow: boolean;
    messagesArray: {
      message1: string;
      errorLink: { link: string; text: string };
      message2: string;
    }[];
  };
}

const ErrorMessage = (props: Props) => {
  const { isShow, messagesArray } = props.data;
  return (
    <div className={`${styles.error_msg}${isShow ? ` ${styles.show}` : ''}`}>
      <ul className={styles.error_msg__ul}>
        {messagesArray.map((message, i) => (
          <li key={i} className={styles.error_msg__li}>
            <p className={styles.error_msg__p}>
              {message.message1}
              {message.errorLink.link && (
                <Link
                  to={message.errorLink.link}
                  className={styles.error_msg__link}
                >
                  {message.errorLink.text}
                </Link>
              )}
              {!!message.message2 && message.message2}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ErrorMessage;
