import React from 'react';
import { Helmet } from 'react-helmet';

import Image from '../../../partials/image/components/Image';
// import Button from '../../../partials/button/components/Button';
import { CompanyCopies } from '../../../constants/Copies';
import styles from '../styles/company.module.scss';

interface Props {
  routerProps: any;
  windowDimensions: { width: number; height: number };
  isTop: boolean;
  isMobileTablet: boolean;
  handleUpdateEntityData: (entityData: any) => void;
  handleResetEntityData: () => void;
  entityData: any;
}

const Company = (props: Props) => {
  return (
    <div className={styles.company}>
      <Helmet>
        <title>{CompanyCopies.headTitle}</title>
      </Helmet>

      <h1 className={'hidden_page_h1'}>{CompanyCopies.headTitle}</h1>

      {Object.keys(CompanyCopies.sections).map((section, i) => (
        <React.Fragment key={i}>
          <div className={styles.company__bg_img__container}>
            {/* background image */}
            <Image
              src={CompanyCopies.sections[section].bgImg}
              alt={'background image'}
              background
              width={'100%'}
              height={'100%'}
              className={`${styles.company__bg_img} ${styles[section]}`}
            />

            {/* background color */}
            <div className={`${styles.company__bg_color} ${styles[section]}`} />
          </div>
          <div className={styles.company__text}>
            {/* text title */}
            {!!CompanyCopies.sections[section].title && (
              <h2 className={styles.company__text__title}>
                {CompanyCopies.sections[section].title}
              </h2>
            )}

            {/* text body */}
            {!!CompanyCopies.sections[section].body && (
              <div
                className={styles.company__text__body}
                dangerouslySetInnerHTML={{
                  __html: CompanyCopies.sections[section].body,
                }}
              />
            )}

            {/* buttonlink (section 3) */}
            {/* {CompanyCopies.sections[section].buttonLink && (
              <Button
                text={CompanyCopies.sections[section].buttonLink.text}
                path={CompanyCopies.sections[section].buttonLink.link}
                buttonClassName={styles.company__text__button}
                textClassName={styles.company__text__button_text}
              />
            )} */}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default Company;
