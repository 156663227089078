import React from 'react';

import { SupportCopies } from '../../../constants/Copies';
import styles from '../styles/support-forgot-pw-card.module.scss';

const SupportForgotPwCard = () => (
  <div className={styles.support_forgot_pw}>
    <p className={styles.support_forgot_pw__title}>
      {SupportCopies.forgotPassword.title}
    </p>
    <p className={styles.support_forgot_pw__body}>
      {SupportCopies.forgotPassword.body}
    </p>
    <a
      href={SupportCopies.forgotPassword.buttonLink.link}
      target={'_blank'}
      title={SupportCopies.forgotPassword.buttonLink.text}
      rel='noopener noreferrer'
      className={styles.support_forgot_pw__link}
    >
      {SupportCopies.forgotPassword.buttonLink.text}
    </a>
  </div>
);

export default SupportForgotPwCard;
