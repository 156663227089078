//import { SignupCopies } from '../constants/Copies';

export default class FormValidations {
  static strContainsSpaceOnly = (str: string) =>
    /^ *$/.test(str) ? true : false;

  static strContainsNumbersOnly = (str: string) =>
    /^[0-9]*$/.test(str) ? true : false;

  static isEmpty = (str: string) =>
    !str || FormValidations.strContainsSpaceOnly(str) ? true : false;

  static isEmailFormat = (str: string) =>
    str &&
    !FormValidations.strContainsSpaceOnly(str) &&
    str.includes('@') &&
    str.includes('.')
      ? true
      : false;

  static isInstitutionalEmailFormat = (str: string) =>
    str &&
    !FormValidations.strContainsSpaceOnly(str) &&
    FormValidations.isEmailFormat(str);
    
    // no more institutional email checking
    // &&
    // !SignupCopies.institutionalEmailAddresses.find(address =>
    //   str.includes(address)
    // );
}
